// import { getApp } from "firebase/app";
import {
	signInWithPopup,
	getAuth,
	GoogleAuthProvider,
	signOut,
	onAuthStateChanged,
	TwitterAuthProvider,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	updatePassword,
	deleteUser,
	signInWithEmailLink,
	sendSignInLinkToEmail,
	isSignInWithEmailLink,
	fetchSignInMethodsForEmail,
} from "firebase/auth";
// import tagmanager, { TAG_EVENTS } from "../analytics/tagmanager";
import { initFirebase } from "../config/firebase.config";
import * as gtag from "../../lib/google/gtag";
import { decrypt, encrypt } from "../utils/encryptionUtils";

const supportedPopupSignInMethods = [
	GoogleAuthProvider.PROVIDER_ID,
	TwitterAuthProvider.PROVIDER_ID,
];

const getProvider = (providerId) => {
	switch (providerId) {
		case TwitterAuthProvider.PROVIDER_ID:
			return new TwitterAuthProvider();
		case GoogleAuthProvider.PROVIDER_ID:
			return new GoogleAuthProvider();
		default:
			throw new Error(`No provider implemented for ${providerId}`);
	}
}

const expiryDuration = 60 * 60 * 1000;
const expiry = Date.now() + expiryDuration;
// const expiry = Date.now() + (7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds
const storeEncryptedCredentials = (userCred, expiryDate) => {
	const { photoURL, reloadUserInfo, metadata, providerData, accessToken } = userCred || {}
	const encryptedUserCred = encrypt(userCred);
	const encryptedPhotoURL = encrypt(photoURL);
	const encryptedMetadata = encrypt(metadata);
	const encryptedProviderData = encrypt(providerData);
	const encryptedReloadUserInfo = encrypt(reloadUserInfo);

	window.localStorage.setItem("userCred", encryptedUserCred);
	window.localStorage.setItem("userphotoURL", encryptedPhotoURL);
	window.localStorage.setItem("usermetadata", encryptedMetadata);
	window.localStorage.setItem("userproviderData", encryptedProviderData);
	window.localStorage.setItem("userreloadUserInfo", encryptedReloadUserInfo);
	window.localStorage.setItem("useraccessToken", accessToken);
	window.localStorage.setItem("userCredExpiry", expiryDate);
	window.localStorage.setItem('userCredTime', Date.now());
};

const removeEncryptedCredentials = () => {
	// window.localStorage.removeItem('emailForSignIn');

	window.localStorage.removeItem('useraccessToken');
	window.localStorage.removeItem("userCred");
	window.localStorage.removeItem("userCredExpiry");
	window.localStorage.removeItem("userphotoURL");
	window.localStorage.removeItem("usermetadata");
	window.localStorage.removeItem("userproviderData");
	window.localStorage.removeItem("userreloadUserInfo");
	window.localStorage.removeItem('userCredTime');
};

class AuthService {
	constructor(firebaseApp) {
		this.auth = getAuth(firebaseApp);
	}

	waitForUser(callback) {
		return onAuthStateChanged(this.auth, (userCred) => {
			callback(userCred);
		});
	}

	loginWithTwitter() {
		let twitterUserCred;
		return signInWithPopup(this.auth, new TwitterAuthProvider())
			.then((userCred) => {
				// tagmanager.login(userCred.user?.uid);
				gtag.event({
					action: "login",
					method: "Twitter"
				});
				window.localStorage.setItem("emailForSignIn", userCred.user?.email);
				storeEncryptedCredentials(userCred?.user, expiry)
				twitterUserCred = userCred;
				return {
					user: userCred.user,
				};
			})
			.catch((error) => {
				if (error.code === "auth/account-exists-with-different-credential") {
					return fetchSignInMethodsForEmail(this.auth, error.customData.email)
						.then((providers) => {
							const firstPopupProviderMethod = providers.find((p) =>
								supportedPopupSignInMethods.includes(p)
							);
							if (!firstPopupProviderMethod) {
								throw new Error(
									`Your account is linked to a provider that isn't supported.`
								);
							}
							const linkedProvider = getProvider(firstPopupProviderMethod);
							linkedProvider.setCustomParameters({ login_hint: error.email });

							return signInWithPopup(this.auth, linkedProvider)
								.then((userCred) => {
									gtag.event({
										action: "login",
										method: "Twitter"
									});
									window.localStorage.setItem("emailForSignIn", userCred.user?.email);
									storeEncryptedCredentials(userCred?.user, expiry)
									return {
										user: userCred.user,
									};
								});
						})
						.catch((error) => {
							console.error("Error linking accounts:", error.message ?? error);
							return {
								error: error.message ?? error,
							};
						});
				} else {
					// Other errors
					console.error("Login with Twitter error:", error.message ?? error);
					return {
						error: error.message ?? error,
					};
				}
			});
	}

	loginWithGoogle() {
		return signInWithPopup(this.auth, new GoogleAuthProvider())
			.then((userCred) => {
				// tagmanager.login(userCred.user?.uid);
				gtag.event({
					action: "login",
					method: "Google"
				});
				window.localStorage.setItem("emailForSignIn", userCred.user?.email);
				storeEncryptedCredentials(userCred?.user, expiry)
				return {
					user: userCred.user,
				};
			})
			.catch((error) => {
				return {
					error: error.message ?? error,
				};
			});
	}

	async logout() {
		gtag.event({ action: "logout", });
		removeEncryptedCredentials();
		await signOut(this.auth);
	}

	createUserWithEmailAndPassword(email, password) {
		return createUserWithEmailAndPassword(this.auth, email, password)
			.then((userCred) => {
				// tagmanager.pushEvent(TAG_EVENTS.REGISTRATION);
				gtag.event({
					action: "sign_up",
					method: "EmailAndPassword"
				});
				window.localStorage.setItem("emailForSignIn", email);
				storeEncryptedCredentials(userCred?.user, expiry)
				return {
					user: userCred.user,
				};
			}).catch((error) => {
				return {
					error: error.message,
				};
			});
	}

	signInUserWithEmailAndPassword(email, password) {
		return signInWithEmailAndPassword(this.auth, email, password)
			.then((userCred) => {
				// tagmanager.login(userCred.user?.uid);
				gtag.event({
					action: "login",
					method: "EmailAndPassword"
				});
				window.localStorage.setItem("emailForSignIn", email);
				storeEncryptedCredentials(userCred?.user, expiry)
				return {
					user: userCred.user,
				};
			}).catch((error) => {
				return {
					error: error.message,
				};
			});
	}

	resetPassword(email) {
		return sendPasswordResetEmail(this.auth, email, { url: `${process.env.NEXT_PUBLIC_URL}/auth` })
			.catch((error) => {
				return {
					error: error.message,
				};
			});
	}

	deleteAccount() {
		return deleteUser(this.auth?.user)
			.catch((error) => {
				return {
					error: error.message,
				};
			});
	}

	updateUserPassword(user, password) {
		return updatePassword(user, password)
			.then(() => {
				return "Update successfully"
			}).catch((error) => {
				return `${error.message}`
			});
	}

	signInWithEmail(email) {
		if (isSignInWithEmailLink(this.auth, window.location.href)) {
			return signInWithEmailLink(this.auth, email, window.location.href)
				.then((result) => {
					// Clear email from storage.
					window.localStorage.removeItem('emailForSignIn');
					// You can access the new user via result.user
					// Additional user info profile not available via:
					// result.additionalUserInfo.profile == null
					// You can check if the user is new or existing:
					// result.additionalUserInfo.isNewUser
				})
		} else {
			return sendSignInLinkToEmail(this.auth, email, {
				url: `${window.location.origin}/auth`,
				handleCodeInApp: true,
				dynamicLinkDomain: `${window.location.origin}`
			})
				.then((result) => {
					// Save the users email to verify it after they access their email
					window.localStorage.setItem("emailForSignIn", email);
				}).catch((error) => {
					return {
						error: error.message || `An unknown error has occured`,
					};
				});
		}
	}

	async storeUserCredentials(userCred) {
		const encryptedUserCred = await encrypt(JSON.stringify(userCred));
		if (typeof localStorage !== 'undefined') {
			localStorage.setItem('userCred', encryptedUserCred);
		}
	}

	async retrieveUserCredentials() {
		if (typeof window !== 'undefined') {
			const encryptedUserCred = localStorage.getItem('userCred');
			if (encryptedUserCred) {
				// Decrypt the encrypted user credentials
				return await decrypt(encryptedUserCred)
					.then(decryptedUserCred => {
						// Parse the decrypted user credentials as JSON
						return JSON.parse(decryptedUserCred);
					})
					.catch(error => {
						console.error('Error decrypting user credentials:', error);
						return null;
					});
			}
			return null;
		}
	}

	clearUserCredentials() {
		if (typeof localStorage !== 'undefined') {
			localStorage.removeItem('userCred');
		}
		this.logout();
	}

}

export default new AuthService(initFirebase());
