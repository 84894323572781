

import { useEffect } from "react";
import "./dist";

export default function CookieConsent({ setConsent }) {
  useEffect(() => {
    const currentDate = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
    // obtain cookieconsent plugin
    var cc = initCookieConsent();

    // example logo
    var logo = '<img src="/assets/svg/logo_dark.svg" alt="Logo" loading="lazy" style="margin-bottom: -5px; height: 20px">';
    var cookie = '🍪';

    if (currentDate?.includes('Europe')) {
      // run plugin with config object
      cc.run({
        current_lang: 'en',
        autoclear_cookies: true,                    // default: false
        cookie_name: 'gruf_cookie',                 // default: 'cc_cookie'
        cookie_expiration: 365,                     // default: 182
        page_scripts: true,                         // default: false

        // auto_language: null,                     // default: null; could also be 'browser' or 'document'
        // autorun: true,                           // default: true
        // delay: 0,                                // default: 0
        // force_consent: false,
        // hide_from_bots: false,                   // default: false
        // remove_cookie_tables: false              // default: false
        // cookie_domain: location.hostname,        // default: current domain
        // cookie_path: "/",                        // default: root
        // cookie_same_site: "Lax",
        // use_rfc_cookie: false,                   // default: false
        // revision: 0,                             // default: 0

        gui_options: {
          consent_modal: {
            layout: 'cloud',                      // box,cloud,bar
            position: 'bottom center',           // bottom,middle,top + left,right,center
            transition: 'slide'                 // zoom,slide
          },
          settings_modal: {
            layout: 'box',                      // box,bar
            position: 'left',                   // right,left (available only if bar layout selected)
            transition: 'slide'                 // zoom,slide
          }
        },

        onFirstAction: function () {
          console.log('onFirstAction fired');
        },

        onAccept: function (cookie) {
          setConsent(cookie);
          console.log('onAccept fired ...');
          console.log('obtained consent for:', cookie?.categories);
        },

        onChange: function (cookie, changed_preferences) {
          console.log('onChange fired ...');

          // If analytics category is disabled => disable google analytics
          if (!cc.allowedCategory('analytics')) {
            typeof window.gtag === 'function' && window.gtag('consent', 'update', {
              'analytics_storage': 'denied'
            });
          }
        },

        languages: {
          'en': {
            consent_modal: {
              // title: cookie + ' We use cookies! ',
              title: 'This website uses cookies!',
              description: `
            <div id="g-inr">
              <div id="g-inr-icon">
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CookieRoundedIcon"><path d="M21.27 10.9c-1.21-.33-2.31-1.46-2.29-2.89.01-.56-.4-1.02-.96-1.01C15.83 7.03 14 5.22 14 3.02c0-.49-.35-.9-.84-.96C6.53 1.22 2 6.81 2 12c0 5.52 4.48 10 10 10 5.61 0 10.11-4.62 10-10.18-.01-.44-.31-.81-.73-.92zM8.5 15c-.83 0-1.5-.67-1.5-1.5S7.67 12 8.5 12s1.5.67 1.5 1.5S9.33 15 8.5 15zm2-5C9.67 10 9 9.33 9 8.5S9.67 7 10.5 7s1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5 6c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>
              </div>
              <div id="g-inr">
                This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it.
                The latter will be set only after consent.
              </div>
              <div id="g-con-pri">
                <br/> <br/>
                Read more about how we use cookies and how you can control them by clicking <button type="button" data-cc="c-settings" class="cc-link">Cookie Settings</button>
                <br/> <br/>
                For more details relative to cookies and other sensitive data, please read the full <button onclick="location.href='/privacy'" type="button" class="cc-link">Privacy Policy</button>
              </div>
            </div>
            `,
              primary_btn: {
                text: 'Accept all',
                role: 'accept_all'              // 'accept_selected' or 'accept_all'
              },
              // secondary_btn: {
              //   text: 'Reject all',
              //   role: 'accept_necessary'        // 'settings' or 'accept_necessary'
              // }
              secondary_btn: {
                text: 'Let me choose',
                role: 'settings'        // 'settings' or 'accept_necessary'
              }
            },
            settings_modal: {
              title: logo,
              save_settings_btn: 'Save settings',
              accept_all_btn: 'Accept all',
              // reject_all_btn: 'Reject all',
              close_btn_label: 'Close',
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' }
              ],
              blocks: [
                {
                  title: 'Cookie usage',
                  description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy" class="cc-link">privacy policy</a>.'
                }, {
                  title: 'Strictly necessary cookies',
                  description: 'These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                  }
                }, {
                  title: 'Performance and Analytics cookies',
                  description: 'These cookies allow the website to remember the choices you have made in the past',
                  toggle: {
                    value: 'analytics',     // there are no default categories => you specify them
                    enabled: false,
                    readonly: false
                  },
                  cookie_table: [
                    {
                      col1: '^_ga',
                      col2: 'grufity.com',
                      col3: '2 years',
                      col4: 'Used to distinguish users',
                      is_regex: true,
                      domain: "grufity.com"
                    },
                    {
                      col1: '_gid',
                      col2: 'grufity.com',
                      col3: '1 day',
                      col4: 'Used to distinguish users',
                      domain: "grufity.com"
                    }
                  ]
                },
                // {
                //   title: 'Advertisement and Targeting cookies',
                //   description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                //   toggle: {
                //     value: 'targeting',
                //     enabled: false,
                //     readonly: false
                //   }
                // },
                {
                  title: 'More information',
                  description: 'For any queries in relation to our policy on cookies and your choices, please <a target="_blank" rel="noopener" class="cc-link" href="mailto:contact@grufity.com?subject=Regarding Cookie Consent">contact us</a>.',
                }
              ]
            }
          }
        }
      });
    }
  }, []);
  return null;
}
