import Router from 'next/router';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import React from "react";

export default function GrufityProgress({ color = '#29D', startPosition = 0.3, stopDelayMs = 200, height = 3, showOnShallow = true, options, nonce, }) {
  let timer = null;
  React.useEffect(() => {
    if (options) {
      NProgress.configure(options);
    }
    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeEnd);
    Router.events.on('routeChangeError', routeChangeEnd);
    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
      Router.events.off('routeChangeComplete', routeChangeEnd);
      Router.events.off('routeChangeError', routeChangeEnd);
    };
  }, []);
  const routeChangeStart = (_, { shallow, }) => {
    if (!shallow || showOnShallow) {
      NProgress.set(startPosition);
      NProgress.start();
    }
  };
  const routeChangeEnd = (_, { shallow, }) => {
    if (!shallow || showOnShallow) {
      if (timer)
        clearTimeout(timer);
      timer = setTimeout(() => {
        NProgress.done(true);
      }, stopDelayMs);
    }
  };
  return (React.createElement("style", { nonce: nonce }, `
      #nprogress {
        pointer-events: none;
      }
      #nprogress .bar {
        background: ${color};
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: ${height}px;
      }
      #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
        opacity: 1;
        -ms-transform: rotate(3deg) translate(0px, -4px);
        transform: rotate(3deg) translate(0px, -4px);
      }
      #nprogress .spinner {
        display: block;
        position: fixed;
        z-index: 1031;
        top: 1.5rem;
        left: 7.5rem;
      }
      #nprogress .spinner-icon {
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        border: solid 2px transparent;
        border-top-color: ${color};
        border-left-color: ${color};
        border-radius: 50%;
        animation: nprogress-spinner 400ms linear infinite;
      }
      .nprogress-custom-parent {
        overflow: hidden;
        position: relative;
      }
      .nprogress-custom-parent #nprogress .spinner,
      .nprogress-custom-parent #nprogress .bar {
        position: absolute;
      }
      @-webkit-keyframes nprogress-spinner {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes nprogress-spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `));
}
GrufityProgress.propTypes = {
  color: PropTypes.string,
  startPosition: PropTypes.number,
  stopDelayMs: PropTypes.number,
  height: PropTypes.number,
  showOnShallow: PropTypes.bool,
  options: PropTypes.object,
  nonce: PropTypes.string,
};


// import Router from 'next/router';
// import NProgress from 'nprogress';
// import PropTypes from 'prop-types';
// import React from "react";

// const GrufityProgress = ({ color = '#29D', startPosition = 0.3, stopDelayMs = 200, height = 3, showOnShallow = true, options, nonce }) => {
//   let timer = null;
//   React.useEffect(() => {
//     if (options) {
//       NProgress.configure(options);
//     }
//     Router.events.on('routeChangeStart', routeChangeStart);
//     Router.events.on('routeChangeComplete', routeChangeEnd);
//     Router.events.on('routeChangeError', routeChangeEnd);
//     return () => {
//       Router.events.off('routeChangeStart', routeChangeStart);
//       Router.events.off('routeChangeComplete', routeChangeEnd);
//       Router.events.off('routeChangeError', routeChangeEnd);
//     };
//   }, []);

//   const routeChangeStart = (_, { shallow }) => {
//     if (!shallow || showOnShallow) {
//       NProgress.set(startPosition);
//       NProgress.start();
//     }
//   };

//   const routeChangeEnd = (_, { shallow }) => {
//     if (!shallow || showOnShallow) {
//       if (timer) clearTimeout(timer);

//       timer = setTimeout(() => {
//         NProgress.done(true);
//       }, stopDelayMs);
//     }
//   };

//   const progressStyles = `
//     #gprogress {
//       pointer-events: none;
//     }
//     #gprogress .bar {
//       background: ${color};
//       position: fixed;
//       z-index: 9999;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: ${height}px;
//     }
//     #gprogress .peg {
//       display: block;
//       position: absolute;
//       right: 0px;
//       width: 100px;
//       height: 100%;
//       box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
//       opacity: 1;
//       -ms-transform: rotate(3deg) translate(0px, -4px);
//       transform: rotate(3deg) translate(0px, -4px);
//     }
//     #gprogress .spinner {
//       display: block;
//       position: fixed;
//       z-index: 1031;
//       top: 1.5rem;
//       left: 7.5rem;
//     }
//     #gprogress .spinner-icon {
//       width: 18px;
//       height: 18px;
//       box-sizing: border-box;
//       border: solid 2px transparent;
//       border-top-color: ${color};
//       border-left-color: ${color};
//       border-radius: 50%;
//       animation: gprogress-spinner 400ms linear infinite;
//     }
//     .gprogress-custom-parent {
//       overflow: hidden;
//       position: relative;
//     }
//     .gprogress-custom-parent #gprogress .spinner,
//     .gprogress-custom-parent #gprogress .bar {
//       position: absolute;
//     }
//     @-webkit-keyframes gprogress-spinner {
//       0% {
//         -webkit-transform: rotate(0deg);
//       }
//       100% {
//         -webkit-transform: rotate(360deg);
//       }
//     }
//     @keyframes gprogress-spinner {
//       0% {
//         transform: rotate(0deg);
//       }
//       100% {
//         transform: rotate(360deg);
//       }
//     }
//   `;

//   return <style nonce={nonce}>{progressStyles}</style>;
// };

// GrufityProgress.propTypes = {
//   color: PropTypes.string,
//   startPosition: PropTypes.number,
//   stopDelayMs: PropTypes.number,
//   height: PropTypes.number,
//   showOnShallow: PropTypes.bool,
//   options: PropTypes.object,
//   nonce: PropTypes.string,
// };

// export default GrufityProgress;
