const baseValue = {};

export default (baseline = baseValue, payload = {}) => {
  const { photo_url: photoUrl, display_name, screen_name } = payload;
  return {
    ...baseline,
    ...payload,
    photoUrl,
    displayName: display_name,
    screenName: screen_name,
  };
};
