export function getBrowserInfo() {
  try {
    if (typeof window === 'undefined' || !window.navigator) {
      throw new Error('Navigator object not available');
    }

    const userAgent = window.navigator.userAgent;
    const isMobile = /Mobi/.test(userAgent);

    let _browser = 'Unknown';
    if (/Firefox/.test(userAgent)) {
      _browser = 'Firefox';
    } else if (/Chrome/.test(userAgent)) {
      _browser = 'Chrome';
    } else if (/Safari/.test(userAgent)) {
      _browser = 'Safari';
    } else if (/Edge/.test(userAgent)) {
      _browser = 'Edge';
    } else if (/Opera/.test(userAgent)) {
      _browser = 'Opera';
    } else if (/MSIE|Trident/.test(userAgent)) {
      _browser = 'IE';
    }

    return {
      _browser,
      isMobile,
    };
  } catch (error) {
    console.error('Error getting _browser information:', error.message);
    return {
      _browser: 'Unknown',
      isMobile: false,
    };
  }
}
