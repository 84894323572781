import React, { Component } from "react";
import { withRouter } from "next/router";
import Error from "../../../public/assets/svg/error.svg";
import { Box, Typography, Container, Button } from '@mui/material';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.error({ error, errorInfo });
  }

  componentDidUpdate(prevProps) {
    const pathsHistory = this.props?.router?.asPath;

    if (pathsHistory !== prevProps?.router?.asPath) {
      const samePath = pathsHistory === prevProps?.router?.asPath;
      this.setState({ hasError: samePath });
    }
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container maxWidth="md" sx={{ textAlign: 'center', my: 12 }}>
          <Box sx={{ height: 'auto', maxWidth: '500px', mx: 'auto', }}>
            <Error />
          </Box>
          <Typography variant="body1" sx={{ color: 'text.description', lineHeight: 2, letterSpacing: '0.06em' }}>
            Oops, there is an error!
          </Typography>
          <Box sx={{ mt: 5 }}>
            <Button variant="outlined" sx={{ fontWeight: 500 }} onClick={() => this.setState({ hasError: false })}>Try again?</Button>
          </Box>
        </Container>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
