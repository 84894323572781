// fingerprint.js

import Fingerprint2 from 'fingerprintjs2';

const generateFingerprint = () => {
  return new Promise((resolve) => {
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
      resolve(fingerprint);
    });
  });
};

const getFingerprintFromCookie = () => {
  if (typeof document !== 'undefined') {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === '__fp') {
        return value;
      }
    }
  }
  return null;
};
// 0617050035648406d43ff4f57606adc1
const setFingerprintCookie = async () => {
  const fingerprint = await generateFingerprint();
  if (typeof document !== 'undefined') {
    document.cookie = `__fp=${fingerprint}; path=/`;
  }
};

const ensureFingerprintCookie = async () => {
  const existingFingerprint = getFingerprintFromCookie();
  if (!existingFingerprint) {
    await setFingerprintCookie();
  }
};

export { ensureFingerprintCookie, getFingerprintFromCookie };
