import axios from 'axios';
import NodeCache from 'node-cache';
import withDecrypt from '../../src/withDecrypt';

const cache = new NodeCache({ stdTTL: 777 });

async function fetchAPI(query, { variables, preview } = {}) {
  try {
    const response = await axios({
      method: 'post',
      url: process.env.NEXT_PUBLIC_API_URL,
      data: {
        query,
      },
    });
    return withDecrypt(response.data);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export default async function getStockTabs(ticker, tabCode, subTab, first, offset, preview) {
  const cacheKey = `stockTabs_${ticker}_${tabCode}_${subTab}_${first}_${offset}_${ticker ? 'preview' : 'live'}`;

  const cachedData = cache.get(cacheKey);
  if (cachedData) {
    return cachedData;
  }

  try {
    const data = await fetchAPI(
      `
    query {
        getStockTabs(ticker: "${ticker}", tab_code: "${tabCode}"${subTab ? `, sub_tab: "${subTab || null}"` : ''}${first ? `, first:${first || null}` : ''}${offset ? `, offset:${offset || null}` : ''}){
          ticker
          tab_code
          section_name
          tab_name
          sub_tab
          isPremium
          show_upgrade
          section_code
          settings
          type
          tabData: tab_data {
            # ... on Insider_Transaction {
            #   ticker
            #   rptOwnerName
            #   txType
            #   txShares
            #   transactionCode
            #   transactionDate
            # }
            ... on Insider_Details {
              # ticker
              transactionDate
              rptOwnerName
              salePurchase
              txSharesPS
              txValPS
              avgSharePrice
              # sharesOwnedFollowingTransaction
              # dateFiled
              officerTitle
              # isDirector
              # isOfficer
              # isTenPercentOwner
              # isOther
              # isFund
              # issuerName
              # periodOfReport
              # url
              # rptfcik
            }
            ... on Fund_Holding{
              # ticker
              value
              companyName
              putCall
              sshPrnAmt
              sshPrnAmtType
              # titleOfClass
              PctPortfolio
              titleOfClass
              # period
              fcik
              incSh
              incPct
              changeType
              incVal
              incValPct
              dateFiled
            }
            ... on Fin_Table{
              # ticker
              # shortName
              # longName
              tableText
              period
              qtrOrAnn
            }
            ... on Premium_Feature{
                message
                isPremiumUser
            }
            ... on Series_Data{
                c: seriesCode
                v: value
                r: recordDate
                # description: seriesDescription
                # desc: shortName
            }
            ... on Seq_Series_Data{
                c: seriesCode
                seq
                v: value
                d: desc
            }
            ... on Chart{
                code
                chart_settings
                chart_type
                series: series_settings
                from_date
                to_date
                x_series
                y_series
            }
            ... on Calc_Metric {
                code: seriesCode
                desc
                formula
                data {
                  c: seriesCode
                  r: recordDate
                  v: value
                }
            }
            ...on Table_Data {
                val
                rowN
            }
            ...on Latest_Metrics_Data {
                r
                value: v
                code: seriesCode
                # isLatest
                # seriesDescription
                # shortName
                # industryName
                # mktCapCat
            }
          }
        }
    }
    `,
      { preview }
    );

    cache.set(cacheKey, data);

    return data;
  } catch (error) {
    console.error('Error fetching stock tabs:', error);
    return null;
  }
}
