/* eslint no-mixed-operators: ["error", {"groups": [["&", "|", "^", "~", "<<", ">>", ">>>"], ["&&", "||"]]}] */
import { dataConstants } from '../../../constants';

const { CHART_SELECTOR, CHART_LAYERS } = dataConstants;

export default ({ selector, target, chart, data = {} }) => {
  let { x } = target || {};
  const { y } = target || {};
  const { scaleX } = chart.chart || {};
  let dx = +0.15;
  let dy = +0.15;
  const periodWidth = 80;
  let begDiff = periodWidth / 2;
  let endDiff = periodWidth / 2;
  let begX = 0;
  // const { scale } = scaleGroups[0] || {};
  // const chartBegX = chart.chartMargin.top;
  const chartEndY = chart.axisLength.y + chart.chartMargin.top;

  const scaleYFirst = chart.getFirstScale();

  // const calRatio = (diviend, divisor) => Math.round((diviend / divisor) * 1000) / 1000;

  switch (selector) {
    case CHART_SELECTOR.Event.selector:
      x = x || scaleX(chart.getXVal(new Date(data.date)));
      return {
        label: {
          label: data.heading || 'Event Name',
          x: +scaleX.invert(x - 20),
          y: chart.getChartYRatio(chartEndY - 5),
          rotate: -90,
        },
        line: {
          x: +new Date(data.date) || +scaleX.invert(x),
        },
      };
    case CHART_SELECTOR.Period.selector:
      if (x && x < chart.chartMargin.left + periodWidth / 2) {
        begDiff = x - chart.chartMargin.left;
        endDiff = periodWidth - begDiff;
      } else if (x && x > chart.chartMargin.left + chart.axisLength.x - periodWidth / 2) {
        endDiff = chart.chartMargin.left + chart.axisLength.x - x;
        begDiff = periodWidth - endDiff;
      }

      begX = x ? x - begDiff : scaleX(chart.getXVal(new Date(data.fromDate)));

      return {
        rect: {
          x1: +new Date(data.fromDate) || +scaleX.invert(x - begDiff),
          x2: +new Date(data.toDate) || +scaleX.invert(x + endDiff),
        },
        label: {
          label: data.heading || 'Period Description',
          x: +scaleX.invert(begX - 25),
          y: chart.getChartYRatio(chartEndY - 5),
          rotate: -90,
        },
      };
    case CHART_SELECTOR.Callout.selector:
      if (x < chart.chartMargin.left + dx * chart.axisLength.x + 125 / 2 + 2) {
        dx = -dx;
      }
      if (y < chart.chartMargin.top + dy * chart.axisLength.y + 70 / 2 + 2) {
        dy = -dy;
      }

      // if (x > chart.axisLength.x + dx * chart.axisLength.x) {
      //   dx = -dx;
      // }
      // if (y > chartEndY + dy * chartEndY) {
      //   dy = -dy;
      // }

      return {
        line: { x1: +scaleX.invert(x), y1: scaleYFirst.invert(y) },
        center: { dx, dy },
        rect: { width: 125, height: 70 },
        head: { label: 'Headline' },
        sub: { label: 'Details go here.' },
      };
    case CHART_SELECTOR.Trendline.selector:
      return {
        pos: data.line,
        item: {
          data: {
            x1: +scaleX.invert(data.line.x1),
            y1: scaleYFirst.invert(data.line.y1),
            x2: +scaleX.invert(data.line.x2),
            y2: scaleYFirst.invert(data.line.y2),
          },
        },
      };
    case CHART_SELECTOR.Change.selector:
      // const scaleY = scaleGroups[data.series.groupId].scale;
      // const dataPoint = {
      //   beg: Math.min(data.pos.endLine.x2, data.pos.begLine.x1),
      //   end: Math.max(data.pos.endLine.x2, data.pos.begLine.x1),
      // };

      // const { beg, end } = dataPoint;

      return {
        pos: data.pos,
        item: {
          data: {
            begLine: {
              x: +scaleX.invert(data.pos.begLine.x1),
              //y: chart.getChartYRatio(data.pos.begLine.y1),
            },
            endLine: {
              x: +scaleX.invert(data.pos.endLine.x2),
              //y: chart.getChartYRatio(data.pos.endLine.y2),
            },
            dataLine: {
              // dx: (data.pos.begLine.x2 - data.pos.begLine.x1) / 2,
              dx: 0.5,
            },
          },
        },
        series: data.series,
        layer: chart.layers[CHART_LAYERS.EDIT].select(`#pctChange-${data.series.id}`),
      };
    case CHART_SELECTOR.Text.selector:
      return {
        x: chart.getXRatio(x),
        y: chart.getChartYRatio(y),
        label: 'Text',
      };
    default:
      return null;
  }
};
