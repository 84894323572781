export default hex => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('');

    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = `0x${c.join('')}`;

    const rgb = { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255 };

    const rgbTot = (+rgb.r + +rgb.b + +rgb.g) / (255 * 3);

    return rgbTot < 0.3 ? '#FFFFFF' : '#000000';
  }
  throw new Error('Bad Hex');
};
