// import { differenceInYears, differenceInMonths, differenceInWeeks, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, addDays, subYears, subMonths } from 'date-fns';
import { parseISO } from 'date-fns';
import format from "date-fns-tz/format";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInYears from 'date-fns/differenceInYears';
import dateFormat from "date-fns/format";
import { enUS } from 'date-fns/locale';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';

export const timeDiffs = [
  { str: 'years', fn: differenceInYears },
  { str: 'months', fn: differenceInMonths },
  { str: 'weeks', fn: differenceInWeeks },
  { str: 'days', fn: differenceInDays },
  { str: 'hours', fn: differenceInHours },
  { str: 'minutes', fn: differenceInMinutes },
  { str: 'seconds', fn: differenceInSeconds },
];

export const getTimeDiffItem = unit => timeDiffs.find(d => d.str === unit);

export const getTimeDiff = (first, second, unit) => {
  const diffObj = getTimeDiffItem(unit);
  return diffObj.fn(first, second);
}

export const convertDate = (date, strFormat = "yyyy-MM-dd") => {
  return date != 'Invalid Date' ? dateFormat(new Date(date), strFormat) : null
};

// export const convertDate_MDY = (date, strFormat = "MMM dd, yyyy") => dateFormat(new Date(date), strFormat);

export const convertDate_MDY = (date, strFormat = "MMM dd, yyyy") => {
  const dateWithoutTime = date.split("T")[0];
  const parsedDate = parseISO(dateWithoutTime);
  const newYorkTimeZone = "America/New_York";
  const formattedDate = format(parsedDate, strFormat, { timeZone: newYorkTimeZone });

  return formattedDate;
};

export const timeSince = (date) => {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export const getTradingItem = (d, timeZone = 'America/New_York', prop = 'recordDate') => {
  const date = utcToZonedTime(new Date(d[prop]), timeZone);
  return {
    ...d,
    //[prop]: Timezone(d[prop]).tz(timeZone).format('DD MMMM YYYY, HH:mm:ss'),
    [prop]: `${format(date, 'yyyy-MM-dd', { timeZone })}T${format(date, 'HH:mm:ss', { timeZone })}`,
  };
}

export const getTimeDiffStr = (date, now = new Date()) => {
  const first = new Date(date);
  const second = new Date(now);

  let diff = 0;
  let i = 0;

  while (diff === 0 && i < timeDiffs.length) {
    diff = timeDiffs[i].fn(second, first);
    i += 1;
  }

  return i <= timeDiffs.length ? `${diff} ${timeDiffs[i - 1].str} ago` : `less than a seconds ago`;
}

export const addDaysFn = (date, amt) => addDays(new Date(date), amt);

export const subYearsFn = (date, amt) => subYears(new Date(date), amt);

export const subMonthsFn = (date, amt) => subMonths(new Date(date), amt);

export function convertToNYTime(dateStr) {
  try {
    const nyTimeZone = 'America/New_York';
    let parsedDate = parseISO(dateStr);
    if (isNaN(parsedDate)) {
      throw new Error('Invalid date format');
    }
    if (dateStr.length <= 10) { // YYYY-MM-DD format
      parsedDate = new Date(dateStr + 'T12:00:00Z');
    }
    const nyDate = utcToZonedTime(parsedDate, nyTimeZone);
    const formattedDate = format(nyDate, 'dd MMM, yyyy', { locale: enUS });
    return formattedDate;
  } catch (error) {
    console.error('Error converting date:', error);
  }
}
