import User from '../user';

const baseValue = {};

export default (baseline = baseValue, payload = {}) => {
  const { users } = payload;

  return {
    ...baseline,
    ...payload,
    users: users ? User(undefined, users) : users,
  };
};
