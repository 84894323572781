/* eslint no-mixed-operators: ["error", {"groups": [["&", "|", "^", "~", "<<", ">>", ">>>"], ["&&", "||"]]}] */
export default (inNewCoords, angle = 0, initialPos) => {
  // var oldCoords = {"x":"","y":""}
  // var originOldCoords = {"x":"","y":""}
  const rotar = initialPos;
  const originOldCoords = {
    x: rotar.x - rotar.x * Math.cos((+angle / 180) * Math.PI) + rotar.y * Math.sin((+angle / 180) * Math.PI),
    y: rotar.y - rotar.x * Math.sin((+angle / 180) * Math.PI) - rotar.y * Math.cos((+angle / 180) * Math.PI),
  };

  return {
    x: originOldCoords.x + inNewCoords.x * Math.cos((+angle / 180) * Math.PI) - inNewCoords.y * Math.sin((+angle / 180) * Math.PI),
    y: originOldCoords.y + inNewCoords.x * Math.sin((+angle / 180) * Math.PI) + inNewCoords.y * Math.cos((+angle / 180) * Math.PI),
  };

  // originOldCoords.x = initialPos.x - initialPos.x * (Math.cos( +angle /  180 * Math.PI)) + rotar.y * (Math.sin( +angle/180 * Math.PI));
  // originOldCoords.y =

  // oldCoords.x =
  // oldCoords.y =
  // return ({
  //     y: initialPos.y + Math.round((initialPos.x - dragPos.x) * Math.sin(angle/180 * Math.PI)) * -1,
  //     x: initialPos.x + Math.round((dragPos.y - initialPos.y) * Math.sin(angle/180 * Math.PI)) * -1,
  // });
};
