import axios from 'axios';
import withDecrypt from '../../src/withDecrypt';

async function fetchAPI(query, accessToken, signal) {
  try {
    const response = await axios({
      method: 'post',
      url: process.env.NEXT_PUBLIC_API_URL,
      data: { query },
      headers: { accesstoken: accessToken ?? "" },
      signal,
    });
    return withDecrypt(response.data);
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log('Request aborted:', err.message);
    } else {
      console.log('Error:', err);
    }
    throw err;
  }
}

export default async function _sendAnalytics(cookie, url, gaid, uid, browser, device, last_signin, bot, remarks, accessToken, signal) {
  const query = `
    mutation {
      sendAnalytics(
        cookie: "${cookie ?? ''}",
        url: "${url}"
        ${gaid ? `, gaid: "${gaid}"` : ''}
        ${uid ? `, uid: "${uid}"` : ''}
        ${browser ? `, browser: "${browser}"` : ''}
        ${device ? `, device: "${device}"` : ''}
        ${last_signin ? `, last_signin: "${last_signin}"` : ''}
        ${bot ? `, bot: "${bot}"` : ''}
        ${remarks ? `, remarks: "${remarks}"` : ''}
      ) {
        status
      }
    }
  `;

  try {
    const data = await fetchAPI(query, accessToken, signal);
    return data;
  } catch (err) {
    const errorQuery = `
      mutation {
        sendAnalytics(
          cookie: "${cookie ?? ''}",
          url: "${url}",
          remarks: "error"
        ) {
          status
        }
      }
    `;
    const data = await fetchAPI(errorQuery, accessToken, signal);
    return data;
  }
}


// import axios from 'axios';
// import withDecrypt from '../../src/withDecrypt';

// async function fetchAPI(query, accessToken, signal) {
//   // async function fetchAPI(query, accessToken) {
//   return axios({
//     method: 'post',
//     url: process.env.NEXT_PUBLIC_API_URL,
//     data: {
//       query,
//     },
//     headers: { accesstoken: accessToken ?? "" },
//     // signal,
//   })
//     .then(resp => withDecrypt(resp.data))
//     .catch(err => {
//       if (axios.isCancel(err)) { // Change isAbort to isCancel
//         console.log('Request aborted:', err.message);
//       } else {
//         console.log('Error:', err);
//       }
//     });
// }

// export default async function _sendAnalytics(cookie, url, gaid, uid, browser, device, last_signin, bot, remarks, accessToken, signal) {
//   // export default async function _sendAnalytics(cookie, url, gaid, uid, browser, device, last_signin, bot, remarks, accessToken) {
//   try {
//     const data = await fetchAPI(
//       `mutation {
//       sendAnalytics(
//         cookie: "${cookie ? cookie : ''}",
//         url: "${url}"
//         ${gaid ? `, gaid: "${gaid}"` : ''}
//         ${uid ? `, uid: "${uid}"` : ''}
//         ${browser ? `, browser: "${browser}"` : ''}
//         ${device ? `, device: "${device}"` : ''}
//         ${last_signin ? `, last_signin: "${last_signin}"` : ''}
//         ${bot ? `, bot: "${bot}"` : ''}
//         ${remarks ? `, remarks: "${remarks}"` : ''}
//         ) {
//         status
//       }
//     }`,
//       accessToken, signal
//       // accessToken
//     );
//     return data;
//   }
//   catch (err) {
//     const data = await fetchAPI(`mutation { sendAnalytics(cookie: "${cookie ? cookie : ''}", url: "${url}", remarks: "error"){ status } } `, accessToken);
//     return data;
//   }
// }
