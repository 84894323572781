import { Box } from '@mui/material';
import Link from "next/link";
import styled, { ThemeProvider } from 'styled-components';
import NoSsr from '@mui/material/NoSsr';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { deepPurple } from '@mui/material/colors';
import { useRouter } from 'next/router';

const customTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[500],
    },
  },
});
const StyledGrufity = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['background-color', 'transform'], {
  duration: theme.transitions.duration.shortest,
})};
  &:hover {
    transform: scale(1.09);
  }
  `}
`;
export default function Logo({ color, isPopup, height, width }) {
  const router = useRouter();
  return (
    // <NoSsr>
    <MuiThemeProvider theme={customTheme}>
      <ThemeProvider theme={customTheme}>
        <Link prefetch={false} href="/">
          {router.pathname?.includes("/docs/api") ?
            <Box sx={{ display: { xs: "none", md: "flex" }, marginRight: isPopup ? 1 : 4, transition: 'all .1s ease-in-out', cursor: 'pointer', alignItems: 'flex-end' }}>
              <StyledGrufity
                component="img"
                sx={{
                  height: height ?? 20,
                  width: width ?? 70,
                  mr: .8,
                  display: { xs: "none", md: "flex" },
                }}
                alt="Grufity logo"
                src={color === 'dark' ? "/assets/svg/logo_dark.svg" : "/assets/svg/logo.svg"}
              />
              <svg height="10" viewBox="0 0 260 113" style={{ marginBottom: '1px' }} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.056 0.991992L0.248 113H23.024L31.916 88.976H78.092L86.984 113H110.228L68.264 0.991992H42.056ZM54.848 26.42L70.76 69.476H39.092L54.848 26.42ZM150.43 71.816H173.362C199.102 71.816 215.17 59.492 215.17 36.404C215.17 13.16 199.102 0.991992 173.362 0.991992H127.498V113H150.43V71.816ZM150.43 52.316V20.492H172.114C185.53 20.492 192.394 26.264 192.394 36.404C192.394 46.388 185.53 52.316 172.114 52.316H150.43ZM259.423 0.991992H236.491V113H259.423V0.991992Z" fill="#66b2ff"></path></svg>
            </Box> :
            <StyledGrufity
              component="img"
              sx={{
                height: height ?? 20,
                width: width ?? 70,
                marginRight: isPopup ? 1 : 8,
                display: { xs: "none", md: "flex" },
                transition: 'all .1s ease-in-out',
                cursor: 'pointer'
              }}
              alt="Grufity logo"
              src={color === 'dark' ? "/assets/svg/logo_dark.svg" : "/assets/svg/logo.svg"}
            />}
        </Link>
        <Link prefetch={false} href="/">
          <StyledGrufity
            component="img"
            sx={{
              height: 20,
              width: 70,
              marginRight: 0,
              display: { xs: "flex", md: "none" },
              transition: 'all .1s ease-in-out',
              cursor: 'pointer'
            }}
            alt="Grufity logo"
            src={color === 'dark' ? "/assets/svg/logo_dark.svg" : "/assets/svg/logo.svg"}
          />
        </Link>
      </ThemeProvider>
    </MuiThemeProvider>
    // </NoSsr>
  );
}
