import rgbToHex from './rgbToHex';

export default (hex, chg) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('');

    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = `0x${c.join('')}`;

    let rgb = {
      r: c > 16 && 255,
      g: c > 8 && 255,
      b: c && 255,
    };

    // rgb={"r":"","g":"","b":""};
    // rgb.r =
    // rgb.g =
    // rgb.b =
    //        var rgb = [(c>>16)&255, (c>>8)&255, c&255]
    // console.log(rgb)
    rgb = {
      r: Math.max(Math.min(255, +rgb.r + Math.round(25.5 * chg)), 0),
      g: Math.max(Math.min(255, +rgb.g + Math.round(25.5 * chg)), 0),
      b: Math.max(Math.min(255, +rgb.b + Math.round(25.5 * chg)), 0),
    };

    // rgb.r = Math.max(Math.min(255, +rgb.r * (1+(chg/100))),0)
    // rgb.g = Math.max(Math.min(255, +rgb.g * (1+(chg/100))),0)
    // rgb.b = Math.max(Math.min(255, +rgb.b * (1+(chg/100))),0)
    // console.log(rgb)
    return `#${rgbToHex(rgb.r)}${rgbToHex(rgb.g)}${rgbToHex(rgb.b)}`;
  }
  throw new Error('Bad Hex');
};
