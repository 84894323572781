import React from "react";
import {
  Zoom, useMediaQuery, IconButton, AppBar, Toolbar, Container, CssBaseline, useScrollTrigger, Box, Fab,
  // Alert, Skeleton, Slide, Menu, MenuItem, Typography
} from "@mui/material";
// import ThemeSwitch from "./ThemeSwitch";
// import globalStore from "../store/globalStore";
import PropTypes from 'prop-types';
// import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
// import ToolsMenu from "./_common/ToolsMenu";
// import LoginMenu from "./_common/LoginMenu";
// import SearchWidget from "./_common/SearchWidget";
// import BottomMenu from "./_common/BottomMenu";
import useOnlineStatus from "./_common/useOnlineStatus";
import UserMenu from "./_common/UserMenu";
import Logo from "./_common/Logo";
import MainMenu from "./_common/MainMenu";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import SearchFund from "./_common/SearchFund";
// import SearchTicker from "./_common/SearchTicker";
// import MenuIcon from '@mui/icons-material/Menu';
// import { dataConstants } from "../constants";
import SwipeableMobileDrawer from "./_common/SwipeableMobileDrawer";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import getPricePlans from "../../lib/pricing/_getPricePlans";
import StepBanner from "./_common/StepBanner";
import { ClickAwayListener } from "@mui/base";

const SearchTicker = dynamic(() => import('./_common/SearchTicker'))
// const BottomMenu = dynamic(() => import('./_common/BottomMenu'))

const groupAdsByPlan = (ads, plan) => {
  try {
    if (!Array.isArray(ads)) {
      throw new Error("Input is not an array");
    }
    const filteredAds = ads.filter(ad => ad.active && ad.targetAudience.includes(plan));
    return filteredAds;
  } catch (error) {
    console.error("Error:", error.message);
    return [];
  }
}

function ScrollTop(props) {
  const { children, window, isMobile } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const ResponsiveAppBar = (props) => {
  const theme = useTheme()
  const router = useRouter()
  // const home = router && router?.asPath ? router?.asPath === "/" : null
  // const stock = router && router?.asPath ? router?.asPath.includes("/stock/") : null
  const home = false
  const fund = router && router?.asPath ? router?.asPath.includes("/funds/") : null
  const { toggleColorMode, themeMode, email, signOut, pageLoading, plan, } = props || {}
  const [showSearch, setShowSearch] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showAd, setShowAd] = React.useState(false);
  const [top_ads, setTopAds] = React.useState({});
  const [bottom_ads, setBottomAds] = React.useState({});
  const [ad_props, setAdProps] = React.useState({});
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const isOnline = useOnlineStatus();
  React.useEffect(() => setOpen(true), [isOnline]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const _get_price_plans_ = async () => {
    const { plans } = await getPricePlans() || {}
    const price_banners = plans && plans.length > 0 ? plans?.find(d => d.type === "PREMIUM") : null
    // const ad1 = price_banners ? JSON.parse(price_banners?.pricing_banner) : null
    const ad = price_banners && price_banners?.features ? JSON.parse(price_banners?.features)?.banner : null
    // const pricingBanner = features && features !== "" ? JSON.parse(features) : {}
    setTopAds(ad && ad.length > 0 && ad[0]?.top_ads ? ad[0]?.top_ads : {})
    setBottomAds(ad && ad.length > 0 && ad[0]?.bottom_ads ? ad[0]?.bottom_ads : [])
    setAdProps(ad && ad.length > 0 && ad[0]?.autoplay_props ? ad[0]?.autoplay_props : [])
    setShowAd((ad && ad.length > 0 && ad[0]?.top_ads) || (ad && ad.length > 0 && ad[0]?.bottom_ads) || {})
  }

  React.useEffect(() => {
    _get_price_plans_();
    // const { plans } = await getPricePlans() || {}
    // const price_banners = plans && plans.length > 0 ? plans?.find(d => d.type === "PREMIUM") : null
    // const ad1 = price_banners ? JSON.parse(price_banners?.pricing_banner) : null
    // const ad2 = price_banners && price_banners?.features ? JSON.parse(price_banners?.features)?.banner : null
    // // const pricingBanner = features && features !== "" ? JSON.parse(features) : {}
    // setAd(ad1 || {})
    // setAds(ad2 || [])
    // setShowAd(ad1 || ad2 || {})
  }, [])

  // const _showBanner = top_ads && top_ads?.targetAudience ? top_ads?.targetAudience.includes(plan) : false;
  const groupedTopAds = top_ads && top_ads?.length > 0 ? groupAdsByPlan(top_ads, plan) : [];
  const groupedBottomAds = bottom_ads && bottom_ads?.length > 0 ? groupAdsByPlan(bottom_ads, plan) : [];

  return (
    <>
      <CssBaseline />
      {/* <ElevationScroll {...props}> */}
      <StepBanner
        ads={groupedTopAds}
        stepper={ad_props && ad_props?.length > 0 ? ad_props[0] : {}}
        pageLoading={pageLoading}
        plan={plan}
      />
      {/* {!pageLoading && _showBanner && top_ads && top_ads?.active && top_ads?.position === "top" && top_ads?.text && top_ads?.text !== "" ?
        <Toolbar id="back-to-top-anchor"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer,
            minHeight: { md: '.5rem', sm: '.5rem', xs: '.5rem' }, p: '0 !important', m: '0 !important',
            backgroundColor: top_ads?.backgroundColor ?? "", justifyContent: 'center', alignItems: 'start',
            display: showAd ? 'flex' : 'none', boxShadow: top_ads?.boxShadow ?? 0,
          }}
        >
          <Box component="div" sx={{
            textAlign: top_ads?.textAlign ?? 'center', backdropFilter: top_ads?.backdropFilter ?? 'blur(2px)',
            width: '100%', pt: .2, top_ads: .2, px: .4, color: top_ads?.textColor ?? "",
            '& h1, h2, h3, h4, h5, h6': { fontSize: '1rem', m: 0, p: 0, color: 'inherit', textTransform: 'uppercase' },
            '& p, a': { fontSize: '.875rem', m: 0, p: 0, color: 'inherit', textTransform: 'capitalize' },
          }}
            dangerouslySetInnerHTML={{
              __html: top_ads && top_ads?.href ? `<a href="${top_ads?.href}">${top_ads?.text}</a>` : top_ads && top_ads?.text ? top_ads?.text : ""
            }} />
          <IconButton size="small"
            sx={{ color: '#0a196287', '&:hover': { color: 'primary.main' } }}
            onClick={() => setShowAd(!showAd)}
          >
            <CancelRoundedIcon sx={{ fontSize: "1rem", color: 'inherit', }} />
          </IconButton>
        </Toolbar> : null} */}
      <AppBar sx={{
        bgcolor: 'primary.main', zIndex: (theme) => theme.zIndex.drawer,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        borderBottom: 0
      }}
        position="sticky"
      >
        <Container maxWidth="xl" sx={{ px: { xs: 0, md: 3 } }}>
          {/* {!pageLoading && */}
          <Toolbar disableGutters variant="dense">
            {/* <Box sx={{ display: { md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{ pl: 0 }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {dataConstants.PAGES.map((page) => (
                    <MenuItem key={page.name} onClick={() => { router.push(page.url); handleCloseNavMenu() }}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box> */}
            <SwipeableMobileDrawer />
            {!showSearch &&
              <Box sx={{ mr: 'auto', ml: 1 }}>
                {/* {!pageLoading ? */}
                <Logo />
                {/* :
                  <Skeleton variant="rounded" width={70} height={38} sx={{ bgcolor: '#2a2d64', marginRight: 10, }} animation="wave" />} */}
              </Box>}
            <ClickAwayListener onClickAway={() => setShowSearch(false)}>
              <Box sx={{ py: showSearch ? 1 : 0 }}>
                {!home && (showSearch ?
                  (fund ? <SearchFund setShowSearch={setShowSearch} /> : <SearchTicker setShowSearch={setShowSearch} />)
                  :
                  (isMobile ?
                    <IconButton size="large" aria-label="search" color="inherit" sx={{ ml: 'auto' }} onClick={() => setShowSearch(true)}>
                      <SearchIcon />
                    </IconButton>
                    :
                    (fund ? <SearchFund setShowSearch={setShowSearch} /> : <SearchTicker setShowSearch={setShowSearch} />)))}
              </Box>
            </ClickAwayListener>
            <MainMenu email={email} signOut={signOut} pageLoading={pageLoading} />
            {/* {!showSearch && email && <UserMenu email={email} signOut={signOut} pageLoading={pageLoading} />} */}
            {!showSearch && <UserMenu email={email} signOut={signOut} pageLoading={pageLoading} />}
          </Toolbar>
          {/* } */}
        </Container>
      </AppBar>
      {/* {
        (!open || isOnline || !isOnline) && !pageLoading &&
        <AppBar
          position="fixed"
          color="transparent"
          sx={{
            zIndex: 9,
            top: "auto",
            bottom: 0,
            '& .MuiContainer-root': {
              p: 0,
            }
          }}
        >
          {isOnline ?
            <Slide direction="up" in={!open} mountOnEnter unmountOnExit timeout={{ exit: 2000 }} style={{ transitionDelay: '2000ms' }} easing={{ exit: theme.transitions.easing.easeInOut, }}>
              <Alert sx={{ alignItems: 'center', justifyContent: 'center', p: 0, borderRadius: 0, '& .MuiAlert-icon, .MuiAlert-message': { p: 0 }, '& .MuiSvgIcon-root': { fontSize: '1rem' } }} severity="success" variant="filled">Back online — refresh the page to stay up-to-date!</Alert>
            </Slide>
            :
            <Slide direction="up" in={!isOnline} mountOnEnter unmountOnExit>
              <Alert sx={{ alignItems: 'center', justifyContent: 'center', p: 0, borderRadius: 0, '& .MuiAlert-icon, .MuiAlert-message': { p: 0 }, '& .MuiSvgIcon-root': { fontSize: '1rem' } }} variant="filled" severity="warning">{isMobile ? "You're offline — your page might not be up-to-date!" : "You're offline — some data on this page might not be up-to-date!"}</Alert>
            </Slide>}
        </AppBar>
      } */}
      {/* <AppBar
        position="fixed"
        color="primary"
        style={{ paddingRight: 0 }}
        sx={{
          zIndex: 9,
          top: "auto",
          bottom: 0,
          display: { xs: "flex", md: "none" },
          '& .MuiContainer-root': {
            p: 0,
          },
          bgcolor: 'transparent',
        }}
      >
        {isOnline && !pageLoading ?
          <Slide direction="up" in={!open} mountOnEnter unmountOnExit timeout={{ exit: 2000 }} style={{ transitionDelay: '2000ms' }} easing={{ exit: theme.transitions.easing.easeInOut, }}>
            <Alert sx={{ alignItems: 'center', justifyContent: 'center', p: 0, borderRadius: 0, '& .MuiAlert-icon, .MuiAlert-message': { p: 0 }, '& .MuiSvgIcon-root': { fontSize: '1rem' } }} severity="success" variant="filled">Back online — refresh the page to stay up-to-date!</Alert>
          </Slide>
          :
          <Slide direction="up" in={!isOnline} mountOnEnter unmountOnExit >
            <Alert sx={{ alignItems: 'center', justifyContent: 'center', p: 0, borderRadius: 0, '& .MuiAlert-icon, .MuiAlert-message': { p: 0 }, '& .MuiSvgIcon-root': { fontSize: '1rem' } }} variant="filled" severity="warning">{isMobile ? "You're offline — your page might not be up-to-date!" : "You're offline — some data on this page might not be up-to-date!"}</Alert>
          </Slide>}
        <Container maxWidth="xl" sx={{ p: 0 }}>
          <Toolbar disableGutters variant="dense">
            <BottomMenu themeMode={themeMode} />
          </Toolbar>
        </Container>
      </AppBar> */}
      {/* {!pageLoading && _showBanner && top_ads && top_ads?.active && top_ads?.position === "bottom" && top_ads?.text && top_ads?.text !== "" ?
        <Toolbar id="back-to-top-anchor"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer,
            minHeight: { md: '1rem', sm: '1rem', xs: '1rem' }, p: '0 !important', m: '0 !important',
            backgroundImage: top_ads && top_ads?.backgroundImage ? top_ads?.backgroundImage : "",
            backgroundColor: top_ads && top_ads?.backgroundColor ? top_ads?.backgroundColor : "", backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto', justifyContent: 'center', alignItems: 'start',
            display: showAd ? 'flex' : 'none', boxShadow: top_ads && top_ads?.boxShadow ? top_ads?.boxShadow : 0,
          }}
        >
          <Box component="div" sx={{
            textAlign: top_ads?.textAlign ?? 'center', backdropFilter: top_ads?.backdropFilter ?? 'blur(2px)',
            width: '100%', py: .5, px: .4, color: top_ads?.textColor ?? "primary.main",
            '& h1, h2, h3, h4, h5, h6': { fontSize: '1rem', m: 0, p: 0, color: 'inherit', textTransform: 'uppercase' },
            '& p, a': { fontSize: '.875rem', m: 0, p: 0, color: 'inherit', textTransform: 'capitalize' },
            // '& a': { textDecoration: 'none' }, '& a:hover': { textDecoration: 'underline' },
          }}
            dangerouslySetInnerHTML={{
              __html: top_ads && top_ads?.href ? `<a href="${top_ads?.href}">${top_ads?.text}</a>` : top_ads && top_ads?.text ? top_ads?.text : ""
            }} />
          <IconButton size="small"
            sx={{ color: '#0a196287', '&:hover': { color: 'primary.main' } }}
            onClick={() => setShowAd(!showAd)}
          >
            <CancelRoundedIcon sx={{ fontSize: "1rem", color: 'inherit', }} />
          </IconButton>
        </Toolbar> : null} */}
      <StepBanner
        ads={groupedBottomAds}
        stepper={ad_props && ad_props?.length > 0 ? ad_props[0] : {}}
        pageLoading={pageLoading}
        plan={plan}
      />
      <ScrollTop {...props} isMobile={isMobile}>
        <Fab size="small" aria-label="scroll back to top" sx={{ bgcolor: '#e7edf3', color: '#174c86' }}>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};
export default React.memo(ResponsiveAppBar);
