import React from "react";
import { Box, IconButton, Divider, ListItemIcon, ListItemText, Skeleton, Menu, MenuItem, Avatar } from '@mui/material';
import { dataConstants } from "../../constants";
// import { useAuthUser } from "next-firebase-auth";
import { useRouter } from "next/router";
import useAuth from "../../hook/auth";
import { getRandomStr } from "../../helpers";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // color: theme.palette.primary.main,
    color: "#2a2d64",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#2a2d64",
    fontWeight: 600,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: '7px 15px 5px 15px',
  },
}));

// function notificationsLabel(count) {
//   if (count === 0) {
//     return 'No notifications';
//   }
//   if (count > 9) {
//     // return 'More than 9 notifications';
//     return 'You have 12 notifications';
//   }
//   return `${count} notifications`;
// }

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   // [`& .${tooltipClasses.arrow}`]: {
//   //   color: theme.palette.common.black,
//   // },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.primary.main,
//   },
// }));

export default function UserMenu({ email, signOut, pageLoading }) {
  const router = useRouter();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  // const [login, setLogin] = React.useState(false);
  const auth = useAuth() || {};
  const { user } = auth || {}
  const { reloadUserInfo } = user || {}
  const isPremium = reloadUserInfo?.customAttributes && (JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PREMIUM" || JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PROFESSIONAL") ? true : false;

  const handleOpenUserMenu = (event) => {
    event.preventDefault()
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (path) => {
    // signOut();
    // !user && router.push('/auth')
    user ? router.push(path)
      : router.push({
        pathname: '/auth',
        query: { from: router?.asPath },
      })
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    auth.logout()
    setAnchorElUser(null);
  }

  return (
    <>
      {/* <CustomTooltip title={notificationsLabel(12)} >
        <IconButton aria-label={notificationsLabel(12)}>
          <Badge badgeContent={12} color='error' max={9}>
            <NotificationsRoundedIcon sx={{ color: '#fff', }} />
          </Badge>
        </IconButton>
      </CustomTooltip> */}
      {/* {!login ?
        <Button
          key={dataConstants.SIGNIN}
          sx={{ color: "white", display: "block", ml: 3 }}
          onClick={() => { setLogin(true); handleCloseUserMenu() }}
        >
          {dataConstants.LOGIN}
        </Button>
        : */}
      <Box sx={{ flexGrow: 0, display: "flex", }}>
        <LightTooltip title={dataConstants.MYACCOUNT} describeChild arrow placement="bottom-start" disableInteractive>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={email ? handleOpenUserMenu : handleCloseUserMenu} sx={{ p: { xs: 1.22, md: 0 }, ml: 2 }}
          >
            {pageLoading ? <Skeleton variant="circular" width={35} height={35} sx={{ bgcolor: '#2a2d64' }} animation="wave" />
              : <Avatar
                alt={user && user.displayName ? user.displayName : ''}
                src={user && user.photoURL ? user.photoURL : ""}
                sx={{ width: 35, height: 35, bgcolor: 'rgba(255, 255, 255, 0.15)', '&:hover': { bgcolor: 'rgb(255 255 255 / 28%)' } }}
              />
            }
          </IconButton>
        </LightTooltip>
        <Menu
          sx={{
            mt: "45px"
          }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          open={Boolean(anchorElUser)}
          onClose={() => setAnchorElUser(null)}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 0,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
        >
          {email &&
            <div key={email}>
              <MenuItem>
                <ListItemText sx={{ '& .MuiListItemText-primary': { fontSize: '.87rem', color: 'hsl(235deg 59.62% 21.27%)' /*#020B4A*/ }, py: 0 }}>{email}</ListItemText>
              </MenuItem>
              <Divider />
            </div>}
          {dataConstants.USERMENU
            .slice(isPremium ? 1 : 0, dataConstants.USERMENU.length)
            .map((setting) => {
              return (
                <Box key={getRandomStr(3)}>
                  {setting.path ?
                    <MenuItem key={setting.name} onClick={() => handleCloseUserMenu(setting.path)}>
                      <ListItemIcon >
                        {setting.icon}
                      </ListItemIcon>
                      <ListItemText sx={{ '& .MuiListItemText-primary': { fontSize: '.87rem', color: setting.color ? setting.color : '' }, py: 0 }}>{setting.name}</ListItemText>
                      {/* <Typography textAlign="center">{setting.name}</Typography> */}
                    </MenuItem>
                    :
                    !setting.divider &&
                    <MenuItem key={setting.name} onClick={() => handleLogout()}>
                      <ListItemIcon >
                        {setting.icon}
                      </ListItemIcon>
                      <ListItemText sx={{ '& .MuiListItemText-primary': { fontSize: '.87rem', color: setting.color ? setting.color : '' }, py: 0 }}>{setting.name}</ListItemText>
                      {/* <Typography textAlign="center">{setting.name}</Typography> */}
                    </MenuItem>}
                  {setting.divider && <Divider sx={{ my: 1 }} />}
                </Box>)
            })}
        </Menu>
      </Box >
      {/* <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
        <Tooltip title={dataConstants.USERMENUTOOLTIP}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
            <Avatar alt="Remy Sharp" src="" />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {dataConstants.USERMENU.map((setting) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box> */}
    </>
  );
}
