import React from "react";
import { Box, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Menu, MenuItem, Skeleton, ListItemButton, Stack, Link } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
// import Link from "next/link";
import { dataConstants } from "../../constants";
import LoginMenu from "./LoginMenu";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useRouter } from "next/router";
// import ImageIcon from '@mui/icons-material/Image';
// import WorkIcon from '@mui/icons-material/Work';
// import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import { getRandomStr } from "../../helpers";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // color: theme.palette.primary.main,
    color: "#2a2d64",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#2a2d64 !important",
    fontWeight: 600,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: '12px 7px 5px 12px !important',
  },
}));

export default function MainMenu({ email, pageLoading }) {
  const router = useRouter()
  const { asPath } = router || {}
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoute = (link) => {
    setAnchorEl(null);
    router.push(link)
  };
  const subMenu = dataConstants?.PAGES?.filter(item => item.subMenu);
  const regularMenu = dataConstants?.PAGES?.filter(item => !item.subMenu);

  const isPageActive = (pageUrl) => {
    if (asPath === pageUrl) {
      return true;
    }
    if (asPath.startsWith(pageUrl + "/")) {
      return true;
    }
    return false;
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "flex !important" }, justifyContent: 'end !important', justifyContent: 'flex-end !important' }}>
      {regularMenu?.map((page) => (
        <React.Fragment key={page.name}>
          {/* {!pageLoading ? */}
          <LightTooltip describeChild key={getRandomStr(3)} title={<p dangerouslySetInnerHTML={{ __html: page?.desc }} />} arrow placement="bottom-start" disableInteractive>
            <Button
              className="top-menu-link"
              key={page.name}
              sx={{
                my: 1.2, mx: .7, color: "white", display: "flex", fontSize: '.875rem',
                fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none', '&:hover': { bgcolor: '#2a2d64' },
                textAlign: 'center', borderRadius: '7px',
              }}
              // onClick={() => router.push(page.url)}
              // startIcon={page.icon}
              href={page.url}
            >
              <Box component="div" sx={{ position: 'relative' }}>
                {page.name}
                {isPageActive(page.url) ?
                  <Box component="div" className="indicator"
                    sx={{
                      position: 'absolute', display: "flex", flexDirection: "row", justifyContent: 'center', justifyContent: 'center', width: '100%', bottom: '-7px'
                    }}
                  >
                    <Box className="indicatorSpan" sx={{ width: "40px", height: '2px', borderRadius: '25px', background: '#ffffffd7', }}></Box>
                  </Box> : null}
              </Box>
              {page.badge ?
                <Box className="badge"
                  sx={{
                    px: '5px', fontSize: '.65rem', bgcolor: '#3a3a6f', color: 'rgb(212, 212, 212)',
                    ml: .7, border: '1px solid #c0c0c021', fontWeight: 600, borderRadius: '5px',
                  }}
                >
                  {page.badge}
                </Box> : null}
            </Button>
          </LightTooltip>
          {/* :
            <Skeleton variant="rounded" width={70} height={35} sx={{ bgcolor: '#2a2d64', my: 1.2, mx: 1, borderRadius: '5px' }} animation="wave" />} */}
        </React.Fragment>
      ))}
      {
        // !pageLoading ?
        subMenu && subMenu.length > 0 &&
        <>
          <Button
            id="menu-button"
            aria-controls={open ? 'menu-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{ my: 1.2, mx: 1, color: "white", fontSize: '.875', fontWeight: 400, textTransform: 'none', '&:hover': { bgcolor: '#2a2d64' } }}
            endIcon={<KeyboardArrowDownRoundedIcon />}
          >
            More
          </Button>
          <Menu
            // elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id="menu-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'menu-button',
            }}
            sx={{
              "& .MuiList-root.MuiList-padding": { p: 0 },
              "& .MuiMenu-paper": { borderRadius: '8px' },
            }}
          >
            <Stack direction="row" spacing={0} sx={{ m: 0 }}>
              <List sx={{
                width: '100%', maxWidth: 277, bgcolor: 'background.paper',
                '& .MuiListItemText-primary': { fontSize: '.875rem', fontWeight: 600 },
                '& .MuiListItemText-secondary': { fontSize: '.74rem' }, display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start'
              }}>
                {subMenu?.map((page) => (
                  <ListItemButton key={getRandomStr(5)} role={undefined}
                    selected={isPageActive(page.url)}
                    // onClick={() => handleRoute(page.url)}
                    sx={{
                      maxWidth: 277, px: 0, py: .3,
                      '& .MuiAvatar-circular': { border: '1.5px solid #fff', },
                      '&:hover .MuiAvatar-circular': { color: '#fff', border: '2px solid #2a2d64', transition: 'all 0.3s ease' },
                      '&:hover .MuiListItemText-primary': { color: 'primary.main' },
                      '&.Mui-selected': {
                        backgroundColor: '#f5f6f7',
                        position: 'relative',
                        '&::after': {
                          content: '""', position: 'absolute', bottom: 0, right: 0, width: 0, height: 0,
                          borderStyle: 'solid', borderWidth: '11px 11px 0 0', borderColor: (theme) => `transparent ${theme.palette.primary.main} transparent transparent`,
                        },
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#f5f6f7',
                        '&::after': {
                          borderWidth: '0',
                          transition: 'all 0.3s ease'
                        }
                      }
                    }}
                    component="a" href={page.url}
                  >
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: '#2a2d64' }}>
                          {page.icon}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={page?.longName ?? page?.name} secondary={page?.desc} />
                      {/* <MenuItem onClick={() => handleRoute(page.url)}> {page.name}</MenuItem> */}
                    </ListItem>
                  </ListItemButton>
                ))}
              </List>
              <Box component="div" sx={{ px: 3, py: 2, bgcolor: '#f5f6f7', '& a': { fontSize: '.8rem', display: 'block', mb: 1.4 } }}>
                <Link href="/pricing" underline="hover">View Pricing</Link>
                <Link href="/pricing" underline="hover">Know Premium Benefits</Link>
                <Link href="/pricing" underline="hover">FAQs</Link>
                <Link href="/report-issue" underline="hover">Report an Issue</Link>
              </Box>
            </Stack>
          </Menu>
        </>
        // : <Skeleton variant="rounded" width={70} height={35} sx={{ bgcolor: '#2a2d64', my: 1.2, mx: 1, borderRadius: '5px' }} animation="wave" />
      }
      {/* {!pageLoading ?
        <Button
          id="tools-button"
          aria-controls={open ? 'basic-tools-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ my: 1.2, mx: 1, color: "white", fontSize: '.8rem', fontWeight: 400 }}
          endIcon={<KeyboardArrowDownRoundedIcon />}
        >
          Tools
        </Button>
        :
        <Skeleton variant="rounded" width={70} height={35} sx={{ bgcolor: '#2a2d64', my: 1.2, mx: 1, }} animation="wave" />} */}
      {!email && !pageLoading && <LoginMenu email={email} />}
      {/* <Menu
        id="basic-tools-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.7,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              bgcolor: '#020e4a',
            },
            // '&:before': {
            //   content: '""',
            //   display: 'block',
            //   position: 'absolute',
            //   top: 0,
            //   right: 14,
            //   width: 10,
            //   height: 10,
            //   bgcolor: 'background.paper',
            //   transform: 'translateY(-50%) rotate(45deg)',
            //   zIndex: 0,
            // },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <List sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper', cursor: 'pointer' }} onClick={() => { router.push('/charts'); handleClose() }} >
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <QueryStatsIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Grufity Charts" secondary="Sample text about grufity charts." sx={{ '& .MuiListItemText-primary': { fontSize: '.87rem', fontWeight: 500 }, '& .MuiListItemText-secondary': { fontSize: '.8rem' } }} />
          </ListItem>
        </List>
      </Menu> */}
    </Box>
  );
}
