import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Badge, CardHeader, IconButton } from '@mui/material';
import Logo from '../Logo';
import { useRouter } from 'next/router';
import { dataConstants } from '../../../constants';
import { red } from '@mui/material/colors';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import useAuth from '../../../hook/auth';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

export default function SwipeableMobileDrawer() {
  const router = useRouter()
  const auth = useAuth()
  const {
    user,
    logout,
  } = auth;
  const { reloadUserInfo } = user || {}
  const isPremium = reloadUserInfo?.customAttributes && (JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PREMIUM" || JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PROFESSIONAL") ? true : false;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ bgcolor: '#f2f2f2', py: .5 }}>
        <ListItem disablePadding >
          <ListItemIcon>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              sx={{ display: { md: 'none' } }}
              onClick={toggleDrawer('left', false)}
            >
              <MenuIcon />
            </IconButton>
          </ListItemIcon>
          <Logo color={"dark"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {dataConstants.PAGES.map((page, index) => (
          <ListItem key={page?.name} disablePadding>
            <ListItemButton onClick={() => { router.push(page.url) }}>
              <ListItemIcon sx={{ '& svg': { fontSize: '1.1rem' } }}>
                {page?.icon}
              </ListItemIcon>
              <ListItemText primary={page?.name} sx={{ '& .MuiListItemText-primary': { fontSize: '.9rem' } }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List sx={{}}>
        {dataConstants.PREMPROFILE.slice(isPremium ? 0 : 4, isPremium ? 3 : 6).map((page, index) => (
          <ListItem key={page?.name} disablePadding>
            <ListItemButton onClick={() => { router.push(page.url) }}>
              <ListItemIcon sx={{ '& svg': { fontSize: '1.1rem' } }}>
                {page?.icon}
              </ListItemIcon>
              <ListItemText primary={page?.name} sx={{ '& .MuiListItemText-primary': { fontSize: '.9rem' } }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List sx={{}}>
        {dataConstants.HELPUS.map((page, index) => (
          <ListItem key={page?.name} disablePadding>
            <ListItemButton onClick={() => { router.push(page.url) }}>
              <ListItemIcon sx={{ '& svg': { fontSize: '1.1rem' } }}>
                {page?.icon}
              </ListItemIcon>
              <ListItemText primary={page?.name} sx={{ '& .MuiListItemText-primary': { fontSize: '.9rem' } }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List sx={{}}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => user?.email ? logout() : router.push("/auth")}>
            <ListItemIcon sx={{ '& svg': { fontSize: '1.1rem' } }}>
              {user?.email ? <LogoutRoundedIcon /> : <LoginRoundedIcon />}
            </ListItemIcon>
            <ListItemText primary={user?.email ? "Sign out" : "Sign in"} sx={{ '& .MuiListItemText-primary': { fontSize: '.9rem' } }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        sx={{ display: { md: 'none' }, justifyContent: 'space-between' }}
        onClick={toggleDrawer('left', true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
        sx={{
          '& .MuiDrawer-paper': {
            justifyContent: 'space-between'
          }
        }}
      >
        {list('left')}
        {user?.email &&
          <Box>
            <Divider />
            <List sx={{ bgcolor: '#f2f2f2' }}>
              <ListItem disablePadding>
                <ListItemButton>
                  <CardHeader
                    sx={{
                      p: 0,
                      '& .MuiCardHeader-title': {
                        maxWidth: '9rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'

                      }
                    }}
                    avatar={
                      isPremium ?
                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={<CheckCircleRoundedIcon sx={{ bgcolor: ` #fff`, borderRadius: '50%', fontSize: '1.2rem', color: '#555' }} />}
                        >
                          <Avatar
                            alt={user && user.displayName ? user.displayName : ''}
                            src={user && user.photoURL ? user.photoURL : ""}
                            sx={{ bgcolor: 'rgb(42 45 100)' }} />
                        </Badge>
                        :
                        <Avatar
                          alt={user && user.displayName ? user.displayName : ''}
                          src={user && user.photoURL ? user.photoURL : ""}
                          sx={{ bgcolor: 'rgb(42 45 100)' }} />}
                    title={user?.email}
                    subheader={isPremium ? "Premium" : "Free"}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>}
      </SwipeableDrawer>
    </React.Fragment >
  );
}
