// import { Backdrop, Box, LinearProgress } from '@mui/material';
import { useEffect, useState } from "react";
import useAuth from "./hook/auth";
import AuthService from "./service/AuthService";
import { useRouter } from 'next/router';
import _sendAnalytics from "../lib/analytics/_sendAnalytics";
import { getBrowserInfo } from "./utils/browserInfo";
// import { detectIncognito } from "./utils/detectincognitoes6";
import { getUserLocation } from "./utils/detectlocation";
import { ensureFingerprintCookie, getFingerprintFromCookie } from "./components/_common/fingerprint";
import { dataConstants } from "./constants";
import { decrypt, encrypt } from "./utils/encryptionUtils";
import { getAuth } from "firebase/auth";

// const extractGeoAndFpValues = (inputString) => {
// 	try {
// 		const result = {};
// 		const regex = /(__geo=[^;]+)|(__fp=[^;]+)/g;

// 		let match;
// 		while ((match = regex.exec(inputString)) !== null) {
// 			if (match[1]) {
// 				const [key, value] = match[1].split('=');
// 				result[key] = value;
// 			} else if (match[2]) {
// 				const [key, value] = match[2].split('=');
// 				result[key] = value;
// 			}
// 		}

// 		return result;
// 	} catch (error) {
// 		console.error("Error extracting values:", error);
// 		return null;
// 	}
// }

const excludedKeys = ['_ga', '_ga_N5ZXKR5D3T', '_gid'];
const regexPattern = new RegExp(`\\b(${excludedKeys.join('|')})=[^;]*;?\\s*`, 'g');
// const expiry = Date.now() + (7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds
const isValid = (expiryDate) => {
	if (!expiryDate) {
		return false;
	}

	const expiryTimestamp = new Date(expiryDate).getTime();
	const currentTimestamp = Date.now();

	if (isNaN(expiryTimestamp)) {
		return true;
	}

	return expiryTimestamp > currentTimestamp;
};
const storeEncryptedCredentials = (userCred, expiry) => {
	const { photoURL, reloadUserInfo, metadata, providerData, accessToken } = userCred || {}
	const encryptedUserCred = encrypt(userCred);
	const encryptedPhotoURL = encrypt(photoURL);
	const encryptedMetadata = encrypt(metadata);
	const encryptedProviderData = encrypt(providerData);
	const encryptedReloadUserInfo = encrypt(reloadUserInfo);

	window.localStorage.setItem("userCred", encryptedUserCred);
	window.localStorage.setItem("userphotoURL", encryptedPhotoURL);
	window.localStorage.setItem("usermetadata", encryptedMetadata);
	window.localStorage.setItem("userproviderData", encryptedProviderData);
	window.localStorage.setItem("userreloadUserInfo", encryptedReloadUserInfo);
	window.localStorage.setItem("useraccessToken", accessToken);
	window.localStorage.setItem("userCredExpiry", expiry);
	window.localStorage.setItem('userCredTime', Date.now());
};

const removeEncryptedCredentials = () => {
	// window.localStorage.removeItem('emailForSignIn');

	window.localStorage.removeItem('useraccessToken');
	window.localStorage.removeItem("userCred");
	window.localStorage.removeItem("userCredExpiry");
	window.localStorage.removeItem("userphotoURL");
	window.localStorage.removeItem("usermetadata");
	window.localStorage.removeItem("userproviderData");
	window.localStorage.removeItem("userreloadUserInfo");
	window.localStorage.removeItem('userCredTime');
};

// const getOperatingSystem = () => {
// 	const userAgent = navigator.userAgent;
// 	if (userAgent.includes('Windows')) {
// 		return 'Windows';
// 	} else if (userAgent.includes('Macintosh') || userAgent.includes('Mac OS X')) {
// 		return 'Mac';
// 	} else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
// 		return 'iOS';
// 	} else if (userAgent.includes('Android')) {
// 		return 'Android';
// 	} else if (userAgent.includes('Linux')) {
// 		return 'Linux';
// 	} else {
// 		return 'Unknown';
// 	}
// }

const getOperatingSystem = () => {
	const userAgent = navigator.userAgent;
	// const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
	const { brand = '' } = ((arr) => arr?.length > 0 ? arr[arr?.length - 1] : {})(navigator?.userAgentData?.brands);
	const isMobile = navigator?.userAgentData?.mobile ?? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

	if (userAgent.includes('Windows')) {
		return isMobile ? `${brand + ' ' + '(Windows Mobile)'}` : `${brand + ' ' + '(Windows)'}`;
	} else if (userAgent.includes('Macintosh') || userAgent.includes('Mac OS X')) {
		return isMobile ? `${brand + ' ' + '(iOS)'}` : `${brand + ' ' + '(Mac)'}`;
	} else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
		return `${brand + ' ' + '(iOS)'}`;
	} else if (userAgent.includes('Android')) {
		return `${brand + ' ' + '(Android)'}`;
	} else if (userAgent.includes('Linux')) {
		return isMobile ? `${brand + ' ' + '(Linux Mobile)'}` : `${brand + ' ' + '(Linux)'}`;
	} else {
		return 'Unknown';
	}
}

const botExtract = (userAgent) => {
	const match = userAgent.match(/\b([a-z0-9-]*bot[a-z0-9-]*)\b/i);
	return match ? match[1] || match[2] : null;
};

const isBot = async () => {
	let eventCount = 0;
	const eventHandler = () => {
		eventCount++;
	};
	document.addEventListener('mousemove', eventHandler);
	document.addEventListener('keydown', eventHandler);
	return new Promise((resolve) => {
		const startTime = Date.now();
		const intervalId = setInterval(() => {
			const currentTime = Date.now();
			const elapsedTime = currentTime - startTime;
			if (elapsedTime >= 5777) {
				document.removeEventListener('mousemove', eventHandler);
				document.removeEventListener('keydown', eventHandler);
				const isLikelyBot = eventCount < 5;
				clearInterval(intervalId);
				resolve(isLikelyBot);
			}
		}, 3777);
	});
};

const useBotDetection = () => {
	const [isBotDetected, setBotDetected] = useState(false);

	useEffect(() => {
		let eventCount = 0;
		const timeThreshold = 3777; // Time threshold in milliseconds
		const eventThreshold = 5; // Event threshold for considering the user as a bot
		let startTime = null;
		let intervalId = null;

		const eventHandler = () => {
			eventCount++;
		};

		const startDetection = () => {
			document.addEventListener('mousemove', eventHandler);
			document.addEventListener('keydown', eventHandler);

			startTime = Date.now();
			intervalId = setInterval(() => {
				const currentTime = Date.now();
				const elapsedTime = currentTime - startTime;

				if (elapsedTime >= timeThreshold) {
					stopDetection();
					const isLikelyBot = eventCount < eventThreshold;
					setBotDetected(true);
				}
			}, 1000);
		};

		const stopDetection = () => {
			document.removeEventListener('mousemove', eventHandler);
			document.removeEventListener('keydown', eventHandler);
			clearInterval(intervalId);
		};

		startDetection();

		return stopDetection;
	}, []);

	return isBotDetected;
};

const bots = dataConstants?.BOTS ?? null
const getBotName = async () => {
	if (typeof window === "undefined") {
		return false;
	}

	const userAgent = navigator.userAgent.toLowerCase();
	const matchedBot = botExtract(userAgent) || (bots && bots.length > 0 ? bots?.find(bot => userAgent.includes(bot.toLowerCase())) : null);

	return matchedBot || false;
	// if (matchedBot) {
	// 	return matchedBot;
	// }

	// const isUserLikelyBot = await isBot();
	// return isUserLikelyBot ? "Likely Bot" : false;
};

export default function AuthStateChanged({ children, initSkeleton, setpageLoading }) {
	const { setUser } = useAuth() || {};
	const _auth = getAuth();
	const { asPath } = useRouter() || {};
	const [loading, setLoading] = useState(true);
	const isBotDetected = useBotDetection();

	// useEffect(() => {
	// 	ensureFingerprintCookie();
	// 	const expiryDuration = 60 * 60 * 1000; // 60 minutes in milliseconds

	// 	const fetchUserCredentials = () => {
	// 		AuthService.waitForUser((userCred) => {
	// 			const expiry = Date.now() + expiryDuration;
	// 			storeEncryptedCredentials(userCred, expiry);
	// 			setUser(userCred);
	// 			setpageLoading(true);
	// 			setLoading(false);
	// 		});
	// 	};

	// 	const refreshToken = async () => {
	// 		try {
	// 			const user = _auth.currentUser;
	// 			if (user) {
	// 				const tokenResult = await user.getIdTokenResult(true);
	// 				const tokenExpirationTime = tokenResult.expirationTime;
	// 				const currentTime = Date.now();
	// 				const tokenExpiryThreshold = 5 * 60 * 1000; // 5 minutes
	// 				if (!tokenExpirationTime || tokenExpirationTime - currentTime <= tokenExpiryThreshold) {
	// 					// Token is about to expire or has expired, refresh it
	// 					const refreshedTokenResult = await user.getIdTokenResult(true);
	// 					const expiry = new Date(refreshedTokenResult.expirationTime).getTime();
	// 					// Update the stored access token with the new one
	// 					const newAccessToken = refreshedTokenResult.accessToken;
	// 					storeEncryptedCredentials({ ...user, accessToken: newAccessToken }, expiry);
	// 				}
	// 			}
	// 		} catch (error) {
	// 			console.error("Error refreshing token:", error);
	// 			removeEncryptedCredentials();
	// 			fetchUserCredentials();
	// 		}
	// 	};

	// 	const checkAccessTokenExpiration = () => {
	// 		const userCredExpiryTime = parseInt(window.localStorage.getItem("userCredTime"), 10);
	// 		const currentTime = Date.now();
	// 		const timeElapsed = currentTime - userCredExpiryTime;

	// 		// if (timeElapsed >= expiryDuration) {
	// 		// 	removeEncryptedCredentials();
	// 		// 	fetchUserCredentials();
	// 		// }

	// 		if (timeElapsed >= expiryDuration - 5 * 60 * 1000) { // Refresh 5 minutes before expiry
	// 			refreshToken();
	// 		}
	// 	};

	// 	const encryptedUserCred = window.localStorage.getItem("userCred");
	// 	const userCredExpiry = window.localStorage.getItem("userCredExpiry");
	// 	const encryptedReloadUserInfo = window.localStorage.getItem("userreloadUserInfo");
	// 	const encryptedPhotoURL = window.localStorage.getItem("userphotoURL");
	// 	const encryptedMetadata = window.localStorage.getItem("usermetadata");
	// 	const encryptedProviderData = window.localStorage.getItem("userproviderData");

	// 	if (encryptedUserCred && userCredExpiry) {
	// 		const expiryTime = parseInt(userCredExpiry);
	// 		const currentTime = Date.now();
	// 		if (expiryTime && currentTime >= expiryTime) {
	// 			// Access token has expired, remove user credentials and fetch again
	// 			removeEncryptedCredentials();
	// 			fetchUserCredentials();
	// 		} else {
	// 			const accessToken = window.localStorage.getItem("useraccessToken");
	// 			if (!accessToken || accessToken === "undefined") {
	// 				setUser(null);
	// 				setpageLoading(true);
	// 				setLoading(false);
	// 				return;
	// 			}
	// 			const userCred = decrypt(encryptedUserCred);
	// 			const reloadUserInfo = decrypt(encryptedReloadUserInfo);
	// 			const photoURL = decrypt(encryptedPhotoURL);
	// 			const metadata = decrypt(encryptedMetadata);
	// 			const providerData = decrypt(encryptedProviderData);
	// 			// if (userCred && userCred?.user && Object.keys(userCred.user).length !== 0 && isValid(userCredExpiry)) {
	// 			// if (!accessToken || (accessToken && accessToken === "undefined")) { setUser(null); } else {
	// 			setUser({ ...userCred, reloadUserInfo, photoURL, metadata, providerData, accessToken });
	// 			setpageLoading(true);
	// 			setLoading(false);
	// 		}
	// 	} else { fetchUserCredentials(); }

	// 	const intervalId = setInterval(() => {
	// 		checkAccessTokenExpiration();
	// 	}, 5 * 60 * 1000);

	// 	return () => clearInterval(intervalId);
	// 	//eslint-disable-next-line
	// }, []);

	useEffect(() => {
		ensureFingerprintCookie();
		AuthService.waitForUser((userCred) => {
			setUser(userCred);
			setLoading(false);
			setpageLoading(true);
		});
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		// if (!window.location.hostname.includes('localhost') || !process.env.NEXT_PUBLIC_URL.includes("dev.grufity.com")) {
		if (!window.location.hostname.includes('localhost')) {
			const controller = new AbortController();
			const { signal } = controller;
			const { _browser, isMobile } = getBrowserInfo();

			AuthService.waitForUser(async (userCred) => {
				try {
					const { accessToken, uid, auth, reloadUserInfo } = userCred || {};
					const inputString = document?.cookie ?? "";
					const referrer = document?.referrer ?? "";
					const match = inputString && inputString !== "" ? inputString.match(/_ga=([^;]+)/) : null;
					const gid = match && match[1];

					let location;
					try {
						const response = await fetch('/api/ip');
						const data = await response.json();
						location = data.userIP ?? ""
					} catch (error) {
						console.error('Error fetching user IP:', error);
					}

					// try {
					// 	location = await getUserLocation();
					// } catch (locationError) {
					// 	console.error("Error getting user location:", locationError);
					// 	// Handle the error if needed
					// }

					let cookie;
					try {
						cookie = getFingerprintFromCookie() ?? "";
					} catch (cookieError) {
						console.error("Error getting fingerprint from cookie:", cookieError);
					}

					const url = process.env.NEXT_PUBLIC_URL + asPath;
					const gaid = gid;
					// const browser = navigator?.userAgent ?? _browser;
					const device = `${navigator?.userAgentData?.platform + '/' + navigator?.platform}` ?? _browser;
					const browser = getOperatingSystem() ?? "";
					const last_signin = reloadUserInfo?.lastLoginAt ? new Date(parseInt(reloadUserInfo?.lastLoginAt, 10))?.toISOString()?.slice(0, -5) + 'Z' : null;

					let bot;
					try {
						bot = await getBotName().then(d => d) ?? false;
						// bot = uid ? false : isBotDetected ? 'Likely Bot' : bot;
						bot = uid ? false : bot;
					} catch (botError) {
						console.error("Error getting bot name:", botError);
					}

					// const remarks = `__geo=${`${location?.continent !== 'Unknown' ? location.continent + '-' : ''}${location?.country !== 'Unknown' ? location.country + '-' : ''}${location?.city !== 'Unknown' ? location.city : ''}`};${referrer && referrer !== "" ? `_ref=${referrer};` : ``}${document.cookie}`.replace(regexPattern, '') ?? "";
					const remarks = `__geo=${`${location && location !== "" ? location : ''}`};${referrer && referrer !== "" ? `_ref=${referrer};` : ``}${document.cookie}`.replace(regexPattern, '') ?? "";
					await _sendAnalytics(cookie, url, gaid, uid, browser, device, last_signin, bot, remarks, accessToken, signal);
					// await _sendAnalytics(cookie, url, gaid, uid, browser, device, last_signin, bot, remarks, accessToken);
				} catch (error) {
					const url = process.env.NEXT_PUBLIC_URL + asPath;
					console.error("Error in analytics processing:", error);
					await _sendAnalytics(null, url, null, null, null, null, null, null, 'front api err', "");
				}
				// }, signal);
			});

			// return () => controller.abort();
		}
		// eslint-disable-next-line
	}, [asPath]);

	return children;
}


// import { Backdrop, Box, LinearProgress } from '@mui/material';
// import React, { useEffect, useState } from "react";
// import useAuth from "./hook/auth";
// import AuthService from "./service/AuthService";

// export default function AuthStateChanged({ children, initSkeleton, setpageLoading }) {
// 	const { setUser } = useAuth();
// 	const [loading, setLoading] = useState(true);

// 	useEffect(() => {
// 		AuthService.waitForUser((userCred) => {
// 			setUser(userCred);
// 			setLoading(false);
// 			setpageLoading(true);
// 		});
// 		//eslint-disable-next-line
// 	}, []);

// 	// commented the below code because we are not able to get the html code in our view-source (it is an issue for our SEO).
// 	// until we find the better alternative for this issue, we are going with and returning children.

// 	// if (loading) {
// 	// 	return (
// 	// 		<Backdrop
// 	// 			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
// 	// 			open={loading}
// 	// 		// onClick={handleClose}
// 	// 		>
// 	// 			<Box sx={{ width: '20%' }}>
// 	// 				<LinearProgress />
// 	// 			</Box>
// 	// 		</Backdrop>);
// 	// }

// 	return children;
// }
