import React from "react";
// import Box from "@mui/material/Box";
import { Button } from '@mui/material';
import { dataConstants } from "../../constants";
import { useRouter } from "next/router";

export default function MainMenu({ email }) {
  const router = useRouter();
  return (
    // <Box sx={{ flexGrow: 1, display: { xs: "none", md: "none", lg: "flex" }, justifyContent: 'end', maxWidth: '35.4%' }}>
    <>
      <Button
        className="login-button"
        key={dataConstants.SIGNIN}
        sx={{ my: 1.2, ml: 1.5, color: "white", display: "block", fontWeight: 400, fontSize: '.875rem', display: { xs: "none", md: "none", lg: "block" }, textTransform:'none' }}
        // onClick={() => router.push('/auth')}
        onClick={() => router.push({
          pathname: '/auth',
          query: { from: router?.asPath },
        })}
      >
        {dataConstants.SIGNIN}
      </Button>

      {/* <Button
        key={dataConstants.SIGNUP}
        sx={{ my: 1.2, mr: 5, color: "white", display: "block", fontWeight: 400, fontSize: '.8rem', display: { xs: "none", md: "none", lg: "block" } }}
      >
        {dataConstants.SIGNUP}
      </Button> */}
    </>
    // </Box >
  );
}
