module.exports = Object.freeze({
  //CACHE
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_CACHE: "AUTH_CACHE",
  AUTH_FAIL: "AUTH_FAIL",

  // LOGIN
  LOG_IN: "LOG_IN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  //TOGGLE_SCREEN_ALERT: 'TOGGLE_SCREEN_ALERT',
  SET_CHECK_LOGIN: "SET_CHECK_LOGIN",
  LOGGING_USER: "LOGGING_USER",
  SET_USER_INFO: "SET_USER_INFO",
  GET_USER: "GET_USER",
  CREATE_USER: "CREATE_USER",
  SET_USER_API_STATUS: "SET_USER_API_STATUS",
  TOGGLE_ASK_TRIAL: "TOGGLE_ASK_TRIAL",
  CHECK_LOGIN_REDIRECT: "CHECK_LOGIN_REDIRECT",

  // App
  SELECT_NAV: "SELECT_NAV",
  SET_GRID_VIEW: "SET_GRID_VIEW",
  TOGGLE_SIDE_BAR: "TOGGLE_SIDE_BAR",
  TOGGLE_DROP_DOWN: "TOGGLE_DROP_DOWN",
  CREATE_LEAD: "CREATE_LEAD",
  CHECK_RECAPTCHA: "CHECK_RECAPTCHA",
  IS_LOGIN_REDIRECT: "IS_LOGIN_REDIRECT",
  GET_PAY_STATUS: "GET_PAY_STATUS",
  TOGGLE_MESSAGE: "TOGGLE_MESSAGE",
  TOGGLE_CONTACT_US: "TOGGLE_CONTACT_US",
  GET_CONFIG: "GET_CONFIG",
  GET_PAY_KEY: "GET_PAY_KEY",
  CHECKOUT_SESSION_ID: "CHECKOUT_SESSION_ID",
  GET_SESSION_EMAIL: "GET_SESSION_EMAIL",
  GET_POPULAR_SEARCHES: "GET_POPULAR_SEARCHES",
  TOGGLE_EMBED_CARD: "TOGGLE_EMBED_CARD",
  GET_EMBED_URL_DETAILS: "GET_EMBED_URL_DETAILS",
  SET_AD_KEY: "SET_AD_KEY",
  REMOVE_AD_KEY: "REMOVE_AD_KEY",

  // Graffiti
  GRAFFITI_PAGE_REQUEST: "GRAFFITI_PAGE_REQUEST",
  GRAFFITI_PAGE_SUCCESS: "GRAFFITI_PAGE_SUCCESS",
  GRAFFITI_PAGE_ERROR: "GRAFFITI_PAGE_ERROR",
  GET_GRAFFITI_CHARTS: "GET_GRAFFITI_CHARTS",
  GET_PINNED_CHARTS: "GET_PINNED_CHARTS",
  GET_WALL_CHARTS: "GET_WALL_CHARTS",
  CREATE_LIKE: "CREATE_LIKE",
  CREATE_PIN: "CREATE_PIN",
  UN_LIKE: "UN_LIKE",
  UN_PIN: "UN_PIN",
  UPDATE_VIEW_COUNT: "UPDATE_VIEW_COUNT",
  CREATE_COMMENT: "CREATE_COMMENT",

  // chart
  CHART_DATA_REQUEST: "CHART_DATA_REQUEST",
  CHART_DATA_SUCCESS: "CHART_DATA_SUCCESS",
  CHART_DATA_ERROR: "CHART_DATA_ERROR",
  CHART_DATA_UPDATE: "CHART_DATA_UPDATE",
  GET_CHART_THEMES: "GET_CHART_THEMES",
  CHART_STATE: "CHART_STATE",
  GET_CHART: "GET_CHART",
  GET_DEFAULT_CHART: "GET_DEFAULT_CHART",
  GET_CIKS: "GET_CIKS",
  GET_CODES: "GET_CODES",
  GET_PLOT_DATA: "GET_PLOT_DATA",
  GET_SERIES_CODE_DETAIL: "GET_SERIES_CODE_DETAIL",
  GET_CHART_SUGGESTIONS: "GET_CHART_SUGGESTIONS",
  SEARCH_CODES: "SEARCH_CODES",
  GET_EVENTS: "GET_EVENTS",
  GET_PERIODS: "GET_PERIODS",
  CREATE_EVENT: "CREATE_EVENT",
  CREATE_PERIOD: "CREATE_PERIOD",
  GET_SIMILAR_CHARTS: "GET_SIMILAR_CHARTS",
  GET_CHART_TEMPLATES: "GET_CHART_TEMPLATES",
  GET_TEMPLATE_CHART: "GET_TEMPLATE_CHART",
  UPLOAD_CHART_IMAGE: "UPLOAD_CHART_IMAGE",
  GET_CHART_DATA: "GET_CHART_DATA",
  GET_CHARTS: "GET_CHARTS",
  GET_CHART_THEMES_REQUEST: "GET_CHART_THEMES_REQUEST",
  GET_CHART_THEMES_ERROR: "GET_CHART_THEMES_ERROR",
  HOME_PAGE_REQUEST: "HOME_PAGE_REQUEST",
  HOME_PAGE_SUCCESS: "HOME_PAGE_SUCCESS",
  HOME_PAGE_ERROR: "HOME_PAGE_ERROR",
  GET_HOME_DATA: "GET_HOME_DATA",
  GET_TOP_QUESTIONS: "GET_TOP_QUESTIONS",
  GET_DEFAULT_DATA: "GET_DEFAULT_DATA",
  UPDATE_SERIES_CHART: "UPDATE_SERIES_CHART",
  DELETE_CHART: "DELETE_CHART",
  SEARCH_EXPLORER: "SEARCH_EXPLORER",

  // Modal show and hide
  MODAL_STATE: "MODAL_STATE",
  MODAL_SHOW: "MODAL_SHOW",
  MODAL_HIDE: "MODAL_HIDE",
  MODAL_UPDATE: "MODAL_UPDATE",

  // signin
  SIGNIN_OPEN: "SIGNIN_OPEN",
  LOG_OUT: "LOG_OUT",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  UNAUTH: "UNAUTH",

  // dropdowns
  DROPDOWN_REQUEST: "DROPDOWN_REQUEST",
  DROPDOWN_SUCCESS: "DROPDOWN_SUCCESS",
  DROPDOWN_ERROR: "DROPDOWN_ERROR",

  // save charts
  SAVE_CHART: "SAVE_CHART",
  UPDATE_CHART: "UPDATE_CHART",

  // chart events
  CHART_EVENT_TITLE: "CHART_EVENT_TITLE",
  CHART_EVENT_SUBTITLE: "CHART_EVENT_SUBTITLE",
  CHART_EVENT_CODE: "CHART_EVENT_CODE",
  CHART_EVENT_TEXT: "CHART_EVENT_TEXT",
  CHART_EVENT_CANVAS: "CHART_EVENT_CANVAS",
  CHART_EVENT_TRENDLINE: "CHART_EVENT_TRENDLINE",
  CHART_EVENT_ANNOTATION: "CHART_EVENT_ANNOTATION",
  CHART_EVENT_EVENT: "CHART_EVENT_EVENT",
  CHART_EVENT_PERIOD: "CHART_EVENT_PERIOD",
  CHART_EVENT_CHANGE: "CHART_EVENT_CHANGE",
  CHART_EVENT_SELECT: "CHART_EVENT_SELECT",
  CHART_EVENT_SELECTOR: "CHART_EVENT_SELECTOR",
  CHART_EVENT_DELETE: "CHART_EVENT_DELETE",
  CHART_EVENT_LEGEND: "CHART_EVENT_LEGEND",
  RESET_EVENTS: "RESET_EVENTS",
  CHART_EVENT_DESC: "CHART_EVENT_DESC",

  FILE_UPLOAD_STATUS: "FILE_UPLOAD_STATUS",
  FILE_READING_FINISH: "FILE_READING_FINISH",
  CSV_UPLOAD_ERROR: "CSV_UPLOAD_ERROR",
  CSV_UPLOAD_PREVIEW: "CSV_UPLOAD_PREVIEW",
  CSV_UPLOAD_REQUEST: "CSV_UPLOAD_REQUEST",
  CSV_UPLOAD_SUCCESS: "CSV_UPLOAD_SUCCESS",
  CSV_UPLOAD_CLOSE: "CSV_UPLOAD_CLOSE",

  LOADING: "LOADING",

  // stock
  STOCK_PAGE_REQUEST: "STOCK_PAGE_REQUEST",
  STOCK_PAGE_SUCCESS_PRIORITY: "STOCK_PAGE_SUCCESS_PRIORITY",
  STOCK_PAGE_SUCCESS: "STOCK_PAGE_SUCCESS",
  COMPARE_STOCK_SUCCESS: 'COMPARE_STOCK_SUCCESS',
  STOCK_PAGE_ERROR: "STOCK_PAGE_ERROR",
  STOCK_TAB: "STOCK_TAB",
  GET_NOTES: "GET_NOTES",
  GET_QUESTION_ANSWERS: "GET_QUESTION_ANSWERS",
  SEARCH_TICKERS: "SEARCH_TICKERS",
  CREATE_NOTE: "CREATE_NOTE",
  CREATE_ANSWER: "CREATE_ANSWER",
  CREATE_QUESTION: "CREATE_QUESTION",
  GET_STOCK_DATA: "GET_STOCK_DATA",
  GET_STOCK_PRICE: "GET_STOCK_PRICE",
  GET_UNANSWERED_QUESTION: "GET_UNANSWERED_QUESTION",
  GET_STOCK_RATIO: "GET_STOCK_RATIO",
  GET_COMMODITY_DATA: "GET_COMMODITY_DATA",
  GET_STOCK_TAB_DATA_PRIORITY: "GET_STOCK_TAB_DATA_PRIORITY",
  GET_STOCK_TAB_DATA: "GET_STOCK_TAB_DATA",
  GET_STOCK_TABS: "GET_STOCK_TABS",
  GET_TICKER_INFO: "GET_TICKER_INFO",
  STOCK_TAB_CHANGE: "STOCK_TAB_CHANGE",
  STOCK_TICKER_CHANGE: "STOCK_TICKER_CHANGE",
  GET_STOCK_SERIES: "GET_STOCK_SERIES",
  GET_REAL_TIME_DATA: "GET_REAL_TIME_DATA",
  GET_PRICE_DATA: "GET_PRICE_DATA",
  GET_STOCK_COMPARE_DATA: 'GET_STOCK_COMPARE_DATA',
  GET_TICKER_BASIC_INFO: 'GET_TICKER_BASIC_INFO',

  //user
  GET_API_USAGE: "GET_API_USAGE",
  GET_MY_EVENTS: "GET_MY_EVENTS",
  GET_MY_PERIODS: "GET_MY_PERIODS",
  DELETE_EVENT: "DELETE_EVENT",
  DELETE_PERIOD: "DELETE_PERIOD",
  EDIT_EVENT: "EDIT_EVENT",
  EDIT_PERIOD: "EDIT_PERIOD",
  GET_USER_SUMMARY: "GET_USER_SUMMARY",
  UPDATE_USER: "UPDATE_USER",
  GET_USER_SERIES_DATA: "GET_USER_SERIES_DATA",
  USER_SERIES_LOADING: "USER_SERIES_LOADING",
  CHECK_USER_NAME: "CHECK_USER_NAME",
  SET_LIST_LOADER: "SET_LIST_LOADER",
  GET_USER_SERIES: "GET_USER_SERIES",
  USER_UPLOAD_DATA: "USER_UPLOAD_DATA",
  SET_UPLOAD_STATUS: "SET_UPLOAD_STATUS",
  GET_OTHER_SUMMARY: "GET_OTHER_SUMMARY",
  GET_OTHER_SERIES_DATA: "GET_OTHER_SERIES_DATA",
  CHECK_USER: "CHECK_USER",
  UPDATE_USER_SERIES: "UPDATE_USER_SERIES",
  DELETE_USER_SERIES: "DELETE_USER_SERIES",
  GET_SHARED_CHART: "GET_SHARED_CHART",
  GET_MY_COMMENTS: "GET_MY_COMMENTS",
  GET_MY_NOTES: "GET_MY_NOTES",

  //subscription
  START_TRIAL: "START_TRIAL",
  UPLOAD_STD_DOC: "UPLOAD_STD_DOC",
  UPDATE_SUBSCRIPTION: "UPDATE_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  GET_CANCEL_REASONS: "GET_CANCEL_REASONS",

  //economy
  GET_ECONOMY_TABS: "GET_ECONOMY_TABS",
  GET_ECO_TAB_DATA: "GET_ECO_TAB_DATA",
  ECO_TAB_CHANGE: "ECO_TAB_CHANGE",

  //table
  GET_TABLES_DATA: "GET_TABLES_DATA",

  //blogs
  GET_BLOGS: "GET_BLOGS",
  GET_BLOG: "GET_BLOG",
  SEARCH_BLOG: "SEARCH_BLOG",
  SAVE_BLOG: "SAVE_BLOG",
  BLOG_CALL_PROGRESS: "BLOG_CALL_PROGRESS",
  SET_SEARCH_BLOG: "SET_SEARCH_BLOG",
  GET_BLOG_TABLES_DATA: "GET_BLOG_TABLES_DATA",

  //custom dashboards
  GET_CUSTOM_DASHBOARDS: "GET_CUSTOM_DASHBOARDS",
  GET_CUSTOM_DASHBOARD: "GET_CUSTOM_DASHBOARD",
  SEARCH_CUSTOM_DASHBOARD: "SEARCH_CUSTOM_DASHBOARD",
  SAVE_CUSTOM_DASHBOARD: "SAVE_CUSTOM_DASHBOARD",
  CUSTOM_DASH_CALL_PROGRESS: "CUSTOM_DASH_CALL_PROGRESS",
  SET_SEARCH_DASHBOARD: "SET_SEARCH_DASHBOARD",
  GET_CUSTOM_TABLES_DATA: "GET_TABLES_DATA",

  GET_TABLE_DATA: "GET_TABLE_DATA",
  GET_TICKER_TABLE_DATA: "GET_TICKER_TABLE_DATA",

  //geo pages
  GET_COUNTRY_DATA: "GET_COUNTRY_DATA",
  GET_STATE_DATA: "GET_STATE_DATA",
  GET_COUNTY_DATA: "GET_COUNTY_DATA",
  SEARCH_GEO_LOCATION: "SEARCH_GEO_LOCATION",
  GET_GEO_COUNTRY_TABS: "GET_GEO_COUNTRY_TABS",
  GET_GEO_STATE_TABS: "GET_GEO_STATE_TABS",
  GET_GEO_COUNTY_TABS: "GET_GEO_COUNTY_TABS",
  SET_TABS_API_STATUS: "SET_TABS_API_STATUS",

  TOGGLE_MODAL: "TOGGLE_MODAL",

  //custom page
  GET_CUSTOM_PAGES: "GET_CUSTOM_PAGES",
  SEARCH_CUSTOM_PAGE: "SEARCH_CUSTOM_PAGE",
  GET_TICKER_WIDGET_DATA: "GET_TICKER_WIDGET_DATA",
  GET_TICKERS_INFO: "GET_TICKERS_INFO",

  //fund page
  GET_FUND_TABS: "GET_FUND_TABS",
  GET_FUND_INFO: "GET_FUND_INFO",
  SEARCH_FUNDS: "SEARCH_FUNDS",
  GET_FUND_SUM: "GET_FUND_SUM",

  UPDATE_QUESTION: "UPDATE_QUESTION",
  UPDATE_ANSWER: "UPDATE_ANSWER",
  DELETE_ANSWER: "DELETE_ANSWER",
  GET_USER_QUESTIONS: "GET_USER_QUESTIONS",
  GET_USER_ANSWERS: "GET_USER_ANSWERS",
  DELETE_QUESTION: "DELETE_QUESTION",

  GET_HOME_TABS: "GET_HOME_TABS",
  GET_WATCH_LIST: "GET_WATCH_LIST",
  SAVE_WATCHLIST: "SAVE_WATCHLIST",
  GET_SECTOR_TABLES: "GET_SECTOR_TABLES",
  GET_SECTOR_TICKER_TABLES: "GET_SECTOR_TICKER_TABLES",
  GET_HOME_SECTORS: "GET_HOME_SECTORS",
  GET_NEWS_WIDGET: "GET_NEWS_WIDGET",

  GET_LATEST_PRICE: "GET_LATEST_PRICE",
  GET_TICKER_TABLE_DATA: "GET_TICKER_TABLE_DATA",
  TOGGLE_WATCHLIST_LOADING: "TOGGLE_WATCHLIST_LOADING",
  TOGGLE_HOME_SECTORS: "TOGGLE_HOME_SECTORS",

  GET_SUGGESTIONS: "GET_SUGGESTIONS",
  WARM_SUGGESTIONS_CACHE: "WARM_SUGGESTIONS_CACHE",
  CLEAR_SUGGESTIONS: "CLEAR_SUGGESTIONS",

  //sectors
  GET_SECTORS: 'GET_SECTORS',
  GET_SECTOR_HEIRARCHY: 'GET_SECTOR_HEIRARCHY',

  //screener
  GET_SCREENER: 'GET_SCREENER',
});
