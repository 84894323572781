export default hex => {
  let color = hex;
  /* Check for # infront of the value, if it's there, strip it */

  if (color.substring(0, 1) === '#') {
    color = color.substring(1);
  }

  const rgbColor = {};

  /* Grab each pair (channel) of hex values and parse them to ints using hexadecimal decoding */
  rgbColor.rChannel = parseInt(color.substring(0, 2), 16);
  rgbColor.gChannel = parseInt(color.substring(2, 4), 16);
  rgbColor.bChannel = parseInt(color.substring(4), 16);

  return rgbColor;
};
