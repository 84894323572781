/* eslint camelcase: 0 */
// import moment from 'moment';

import { convertDate } from '../../../helpers/time';
// import seriesData from './seriesData';
// import stockData from './stockData';
// import sequenceData from './sequenceData';
import chartSettings from '../theme/chartSettings';
import Comment from './comment';
import User from '../user';
// import projectionData from './projectionData';
// import dailyStockData from './dailystockData';


const baseValue = {};

export default (baseline = baseValue, payload = {}) => {
  const {
    id,
    parentChartCode,
    tags,
    code,
    title,
    author,
    sub_title,
    x_series,
    y_series,
    description,
    // series_data,
    // stock_data,
    // seq_series_data,
    // proj_series_data,
    // daily_stock_data,
    chart_type,
    chart_settings,
    isPrivate,
    chart_axis,
    comments,
    likes,
    likes_count,
    liked_by_me,
    pins,
    pinned_by_me,
    pins_count,
    views,
    views_count,
    from_date,
    to_date,
    isDraft,
    series,
    base_data,
    url,
    series_metadata,
    tickers = [],
    frequency_days,
    createdAt,
    updatedAt,
  } =
    payload || {};

  let chart_setting = typeof chart_settings === 'string' ? JSON.parse(chart_settings) : chart_settings;
  chart_setting = chart_setting && Object.keys(chart_setting).length > 0 ? chartSettings(undefined, chart_setting) : chart_setting;

  // let chart_setting = chart_settings ? JSON.parse(JSON.stringify(chart_settings)) : {};
  // let chart_setting = chart_settings && typeof chart_settings === 'string' ? JSON.parse(chart_settings) : chart_settings;
  // let chart_setting = typeof chart_settings === 'string' ? Object.keys(chart_settings).length > 0 ? JSON.parse(chart_settings) : chart_settings : chart_settings;

  return {
    ...baseline,
    getChart: {
      id: id || -1,
      tags,
      parentChartCode,
      code,
      title,
      author: author ? author.map(user => User(undefined, user)) : author,
      subTitle: sub_title,
      xSeries: x_series,
      ySeries: y_series,
      description,
      series_data: [],
      stock_data: [],
      sequenceData: [],
      projections: [],
      chart_type,
      chartSetting: chart_setting,
      isPrivate,
      chartAxis: chart_axis,
      comments: comments ? comments.map(comment => Comment(undefined, comment)) : comments,
      commentCount: comments ? comments.length : 0,
      likes,
      likeCount: likes_count || 0,
      liked: liked_by_me,
      pins,
      pinned: pinned_by_me,
      pinCount: pins_count,
      views,
      viewCount: views_count,
      fromDate: from_date ? convertDate(from_date) : '',
      toDate: to_date ? convertDate(to_date) : '',
      isDraft,
      // series: series ? JSON.parse(series) : [],
      series: series && typeof series === 'string' ? JSON.parse(series) : series,
      baseData: base_data,
      url,
      dailyStockData: [],
      seriesMeta: series_metadata,
      tickers,
      frequency_days,
      createdAt,
      updatedAt,
    },
  };
};
