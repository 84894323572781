export const routes = [
  // { name: "Home", link: "/home" },
  { name: "Stock Screener", link: "/stock-screener", group: 'Resources', tooltip: 'Stock Screener' },
  { name: "Stock", link: "/stock", group: 'Resources', tooltip: 'Stock' },
  { name: "Funds", link: "/funds", group: 'Resources', tooltip: 'Funds' },
  { name: "Economy", link: "/us-economy", group: 'Resources', tooltip: 'Economy' },
  { name: "Sectors", link: "/sectors", group: 'Resources', tooltip: 'Sectors' },
  // { name: "Screener", link: "/stock-screener", group: 'Resources', tooltip: 'Stock Screener' },
  { name: "Watchlists", link: "/watchlists", group: 'Resources', tooltip: 'Watchlists' },
  { name: "Grufity API", link: "/docs/api?lang=r#api-reference", group: 'Explore', tooltip: 'API Documentation' },
  // { name: "Data Explorer", link: "/data-explorer", group: 'Explore', tooltip: 'Data Explorer' },
  { name: "Stock Ideas", link: "/stock-ideas", group: 'Explore', tooltip: 'Stock Ideas' },
  // { name: "Search Filings", link: "/search", group: 'Explore', tooltip: 'Search' },
  { name: "Blogs", link: "/blogs", group: 'Explore', tooltip: 'Blogs' },
  { name: "Pricing", link: "/pricing", group: 'Explore', tooltip: 'Pricing' },
  { name: "Terms", link: "/terms", group: 'Company', tooltip: 'Terms of Use' },
  { name: "Privacy", link: "/privacy", group: 'Company', tooltip: 'Privacy Policy' },
  { name: "About us", link: "/about", group: 'Company', tooltip: 'About us' },
  { name: "Contact us", link: "/contact", group: 'Company', tooltip: 'Contact us' },
  { name: "Report Issue", link: "/report-issue", group: 'Company', tooltip: 'Report any issues or bugs you faced on this website' },
  { name: "Stocks", link: "/browse/stocks?alphabet=A", group: 'Browse', tooltip: 'Browse All Stocks' },
  { name: "Funds", link: "/browse/funds?alphabet=A", group: 'Browse', tooltip: 'Browse All Funds' },
];

// export const terms = [
//   { name: "Terms", link: "/terms" },
//   { name: "Privacy", link: "/privacy" },
//   // { name: "Security", link: "/security" },
// ];

// export const pricing = [
//   { name: "Pricing", link: "/" },
// ];

export const socials = {
  // facebook: "https://www.facebook.com/",
  // github: "https://github.com/",
  twitter: "https://twitter.com",
};
