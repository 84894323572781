import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getPreferredTheme } from '.';

export const GrufityTheme = ({ children }) => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const preferedMode = 'light';
  // const mode = prefersDarkMode ? 'dark' : 'light'
  const theme = React.useMemo(
    () => createTheme(getPreferredTheme(preferedMode)),
    // createTheme({
    //   palette: {
    //     mode: prefersDarkMode ? 'dark' : 'light',
    //     ...(mode === 'dark' && {
    //       background: {
    //         default: 'rgb(10, 25, 41)',
    //         paper: '#202e3c',
    //         primary: 'rgb(10, 25, 41)',
    //         primary_light: '#323f4b',
    //         selected: '',
    //         sorted: '#403f344d',
    //       },
    //       text: {
    //         default: 'rgba(255, 255, 255, 0.7)',
    //         link: '#2DA1EE',
    //         description: 'rgba(255, 255, 255, 0.7)',
    //         positive: '#0e9e19',
    //         negative: '#EB0000',
    //         regular: 'rgba(0, 0, 0, 0.87)',
    //         color: {
    //           white: 'rgb(255, 255, 255)',
    //           black: 'rgba(0, 0, 0, 0.87)',
    //         }
    //       },
    //       primary: {
    //         main: "rgb(10, 25, 41)",
    //       },
    //     }),
    //     ...(mode === 'light' && {
    //       background: {
    //         primary: '#174c86',
    //         primary_light: 'rgb(23 76 134/.1)',
    //         selected: '',
    //         sorted: 'rgba(255, 255, 0, .1)',
    //       },
    //       text: {
    //         default: 'rgba(0, 0, 0, 0.6)',
    //         link: '#174c86',
    //         description: '#666',
    //         positive: '#0e9e19',
    //         negative: '#EB0000',
    //         regular: 'rgb(255, 255, 255)',
    //         color: {
    //           black: 'rgb(255, 255, 255)',
    //           white: 'rgba(0, 0, 0, 0.87)',
    //         }
    //       },
    //       primary: {
    //         main: "#020B4A",
    //       },
    //       secondary: {
    //         main: "#19857b",
    //       },
    //       error: {
    //         main: red.A400,
    //       },
    //       //   background: {
    //       //     default: '#1d2025',
    //       //     paper: '#323f4b',
    //       //   },
    //     }),
    //   },
    //   components: {
    //     MuiTypography: {
    //       defaultProps: {
    //         variantMapping: {
    //           // h1: 'h2',
    //           // h2: 'h2',
    //           // h3: 'h2',
    //           // h4: 'h2',
    //           // h5: 'h2',
    //           // h6: 'h2',
    //           // subtitle1: 'h2',
    //           // subtitle2: 'h2',
    //           body1: 'span',
    //           body2: 'span',
    //         },
    //       },
    //     },
    //   },
    // }),
    [preferedMode],
  );

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

// // import { createTheme } from "@mui/material/styles";
// import { red } from "@mui/material/colors";
// import useMediaQuery from '@mui/material/useMediaQuery';

export const getTheme = (mode) => (getPreferredTheme(mode));

// export default getTheme;
