// import * as mutation from '../mutations';
import axios from 'axios';
import withDecrypt from '../../src/withDecrypt';
// import decorators from '../../src/api/decorators';
// import requestService from '../../src/api/services/requestService';
// import { home } from '../../src/api/queries';
// import { doHomePageAction } from '../../src/actions';
// import { actionConstants } from '../../src/constants';

async function fetchAPI(query, { variables, preview } = {}) {
  return axios({
    method: 'post',
    url: process.env.NEXT_PUBLIC_API_URL,
    data: {
      query,
    },
    // headers: { 'accessToken': args[0] },
  })
    .then(resp => withDecrypt(resp.data))
    .catch(err => {
      console.log(err);
    });
}

export default async function getPricePlans() {
  const data = await fetchAPI(
    `
    query {
      plans: getPricePlans {
        id
        plan
        type: plan_type
        features
        m: monthly_price
        q: quarterly_price
        y: yearly_price
        im: inr_monthly_price
        iq: inr_quarterly_price
        iy: inr_yearly_price
        em: eur_monthly_price
        eq: eur_quarterly_price
        ey: eur_yearly_price
        gm: gbp_monthly_price
        gq: gbp_quarterly_price
        gy: gbp_yearly_price
        mpid: monthly_price_id
        qpid: quarterly_price_id
        ypid: yearly_price_id
        student_m: student_monthly_price
        student_y: student_yearly_price
        order
        pricing_banner
      }
    }
    `
  )
  return data
}
