import { formatCount } from ".";
import { isInt } from "../..";

export default (arr = []) => {
    const max = arr.max();
    const min = arr.min();

    const dev = Math.log10(max)/Math.log10(1000) - Math.log10(min)/Math.log10(1000);
    const perDev = dev / arr.length;

    let x = arr.map(d => formatCount(Math.sign(d) * d, false, 3, Math.sign(d)));
    x = x.filter(d => !isInt(parseFloat(d)));
    //const i = max / 1000;

    return {dev, perDev, decimals: x.length > 0 ? Math.abs(Math.min(0, Math.round(Math.log10(perDev)))): 0};
}
