import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { getRandomStr } from '../../../helpers';

const AutoPlayAds = autoPlay(SwipeableViews);

function StepBanner({ ads, stepper, pageLoading, plan }) {
  const theme = useTheme();
  const [showAd, setShowAd] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = ads?.length;

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const lastCloseTime = localStorage.getItem('adClosedTime');
      if (lastCloseTime) {
        const elapsedTime = Date.now() - parseInt(lastCloseTime, 10);
        if (elapsedTime < 24 * 60 * 60 * 1000) {
          setShowAd(false);
        } else {
          setShowAd(true);
        }
      } else {
        setShowAd(true);
      }

      if (maxSteps > 0) {
        setActiveStep(Math.floor(Math.random() * maxSteps));
      }
    }
  }, [ads, maxSteps]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleCloseAd = () => {
    setShowAd(false);
    if (typeof window !== 'undefined') {
      localStorage.setItem('adClosedTime', Date.now().toString());
    }
  };

  if (showAd === null) {
    return null;
  }

  return (
    <>
      {!pageLoading && showAd && ads[activeStep]?.active && ads[activeStep]?.text && ads[activeStep]?.text !== "" ? (
        <Toolbar
          sx={{
            zIndex: (theme) => theme.zIndex.drawer,
            minHeight: { md: '1rem', sm: '1rem', xs: '1rem' },
            p: '4px !important',
            m: '0 !important',
            backgroundImage: ads[activeStep]?.backgroundImage ? `url(${ads[activeStep]?.backgroundImage})` : "",
            backgroundColor: ads[activeStep]?.backgroundColor || "",
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            justifyContent: 'center',
            alignItems: 'start',
            display: 'flex',
            boxShadow: ads[activeStep]?.boxShadow || 0,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <AutoPlayAds
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={stepper?.interval ?? 3777}
              autoplay={stepper?.autoplay ?? true}
              direction={stepper?.direction ?? 'incremental'}
            >
              {ads.map((step, index) => (
                <div key={getRandomStr(5)}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box component="div"
                      sx={{
                        textAlign: step?.textAlign ?? 'center',
                        verticalAlign: "middle",
                        width: '100%',
                        pt: .2,
                        px: .4,
                        color: step?.textColor || "",
                        '& h1, h2, h3, h4, h5, h6': {
                          fontSize: '1rem',
                          m: 0,
                          p: 0,
                          color: 'inherit',
                          textTransform: 'uppercase',
                          backdropFilter: step?.backdropFilter ?? 'blur(2px)',
                        },
                        '& p, a': {
                          fontSize: '.875rem',
                          m: 0,
                          p: 0,
                          color: 'inherit',
                          textTransform: 'capitalize',
                          backdropFilter: step?.backdropFilter ?? 'blur(2px)',
                        },
                      }}
                      dangerouslySetInnerHTML={{
                        __html: step?.href ? `<a href="${step?.href}">${step?.text}</a>` : step?.text || "",
                      }}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlayAds>
          </Box>
          <IconButton
            size="small"
            sx={{ color: '#0a196287', '&:hover': { color: 'primary.main' } }}
            onClick={handleCloseAd}
          >
            <CancelRoundedIcon sx={{ fontSize: "1rem", color: 'inherit' }} />
          </IconButton>
        </Toolbar>
      ) : null}
    </>
  );
}

export default StepBanner;
