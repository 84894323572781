export default function (text = '', setting, notWrap = false) {
  if (text === '') {
    return { width: 0, height: 0 };
  }

  const p = document.getElementById('textLength');
  p.style = 'visibility: hidden;';
  p.style.textAlign = 'center';

  if (notWrap) {
    p.style.overflowX = 'auto';
    p.style.whiteSpace = 'nowrap';
  }

  const span = document.getElementById('textLength').children[0];
  span.style.fontSize = setting && setting.fs ? setting.fs : '1rem';
  span.style.fontWeight = setting && setting.fw ? setting.fw : 'normal';
  span.style.fontFamily = setting && setting.ff ? setting.ff : 'Arial,san-serif';
  span.innerText = text;

  const width = span.offsetWidth + 24;
  const height = span.offsetHeight + 4;

  p.style = 'display: none';
  span.innerText = '';

  return { width, height };
}
