// import * as mutation from '../mutations';
import axios from 'axios';
import withDecrypt from '../../src/withDecrypt';
import { dataConstants } from '../../src/constants';
import { constructKeywordSearchStr } from "../../src/helpers";
// import decorators from '../../src/api/decorators';
// import requestService from '../../src/api/services/requestService';
// import { home } from '../../src/api/queries';
// import { doHomePageAction } from '../../src/actions';
// import { actionConstants } from '../../src/constants';


async function fetchAPI(query, { variables, preview } = {}) {
  return axios({
    method: 'post',
    url: process.env.NEXT_PUBLIC_API_URL,
    data: {
      query,
    },
    // headers: { 'accessToken': args[0] },
  })
    .then(resp => withDecrypt(resp.data))
    .catch(err => {
      console.log(err);
    });
}

export default async function get5YPriceData(ticker, start_date, end_date) {
  const data = await fetchAPI(
    `
     query{
        getStockSummary(ticker:  "${ticker}", start_date: "${start_date}", limit: 250) {
          stock_data {
            code: seriesCode
            value: price
            recordDate: record_date
            rn
          }
        }
      }
  `
  )
  return data
}
