const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T', 'Q', 'E', 'Z', 'Y'];

export default (count, withAbbr = false, decimals = 3, sign = 1) => {
  if (count === 0) return '0';

  count *= sign;  // Apply sign to count directly
  const absCount = Math.abs(count);
  const i = Math.floor(Math.log10(absCount) / Math.log10(1000));
  let result = '';

  if (i === 0 || i === -1) {
    result = (Math.round(count * 100) / 100).toString();
  } else if (i < -1) {
    const l = Math.floor(Math.log10(absCount));
    result = `${Math.round(count / Math.pow(10, l))}e${l}`;
  } else {
    result = (count / Math.pow(1000, i)).toFixed(decimals);
  }

  if (withAbbr && COUNT_ABBRS[i]) {
    result += COUNT_ABBRS[i];
  } else if (withAbbr && i >= COUNT_ABBRS.length) {
    result += 'e' + (i * 3);
  }
  return result;
};


// const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T', 'Q', 'E', 'Z', 'Y'];

// export default (count, withAbbr = false, decimals = 3, sign = 1) => {
//   const i = count === 0 ? count : Math.floor(Math.log10(count) / Math.log10(1000));
//   let result = '';
//   if (i === 0 || i === -1) {
//     result = (sign * Math.round(count * 100)) / 100;
//   } else if (i < -1) {
//     const l = Math.floor(Math.log10(count));
//     result = `${Math.round(count / Math.pow(10, l))}e${l}`;
//   } else {
//     result = parseFloat(sign * parseFloat(count / Math.pow(1000, i))).toFixed(decimals);
//   }
  
//   if (withAbbr && COUNT_ABBRS[i]) {
//     result += `${COUNT_ABBRS[i]}`;
//   }
//   return result;
// };
