import { getFingerprintFromCookie } from "../../src/components/_common/fingerprint";
import _sendAnalytics from "./_sendAnalytics";

export const uevent = async (event, props) => {
  const controller = new AbortController();
  const { signal } = controller;
  const { target } = event || {};
  const { eventName, eventCategory, eventLabel } = target?.dataset || {};
  const { href } = target || {};
  const asPath = typeof window !== 'undefined' ? window.location.href : "";
  let location;
  try {
    const response = await fetch('/api/ip');
    const data = await response.json();
    location = data.userIP ?? ""
  } catch (error) {
    console.error('Error fetching user IP:', error);
  }
  let cookie;
  try {
    cookie = getFingerprintFromCookie() ?? "";
  } catch (cookieError) {
    console.error("Error getting fingerprint from cookie:", cookieError);
  }
  _sendAnalytics(
    cookie,
    asPath.replace(/^http:\/\/localhost:3000/, "").replace(/^https:\/\/dev.grufity.com/, "").replace(/^https:\/\/www.grufity.com/, "").replace(/^https:\/\/grufity.com/, ""),
    null,
    null,
    null,
    null,
    null,
    null,
    `_geo=${location}; _uEvents={${eventName ? `${eventName}, ` : ''}${eventCategory ? `${eventCategory}, ` : ''}${eventLabel ? `${eventLabel}, ` : ''}${href ? `${href}, ` : ''}${props ? `${props}` : ''}}`,
    "",
    signal
  ).catch((error) => {
    console.warn(`Unexpected error at analytics at send.js: ${error}`)
  });
}
