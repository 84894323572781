// import LZUUTF8 from 'lzutf8';

// import decryptor from './decryptor';
// import Cipher from './cipher';

// const passKey = decryptor.getKey();

// export default d => {
//   if (d.data) {
//     const cipher = new Cipher();
//     cipher.key = d.data;
//     const tempArray = new Uint8Array(cipher.getKey(passKey).split(','));
//     return JSON.parse(LZUUTF8.decompress(tempArray));
//   }

//   return d.data;
// };
// import * as base64 from "byte-base64";
// import ZIP from 'uzip';

// import decryptor from './decryptor';
// import Cipher from './cipher';

// const passKey = decryptor.getKey();

// function Utf8ArrayToStr(array) {
//   let out, i, len, c;
//   let char2, char3;
//   out = "";
//   len = array.length;
//   i = 0;

//   while (i < len) {
//     c = array[i++];
//     switch (c >> 4) {
//       case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
//         out += String.fromCharCode(c);
//         break;
//       case 12: case 13:
//         // 110x xxxx   10xx xxxx
//         char2 = array[i++];
//         out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
//         break;
//       case 14:
//         // 1110 xxxx  10xx xxxx  10xx xxxx
//         char2 = array[i++];
//         char3 = array[i++];
//         out += String.fromCharCode(((c & 0x0F) << 12) |
//           ((char2 & 0x3F) << 6) |
//           ((char3 & 0x3F) << 0));
//         break;
//     }
//   }
//   return out;
// }

export default d => {
  // if (d.data && process.env.NODE_ENV !== 'production') {
  //   const cipher = new Cipher();
  //   cipher.key = d.data;
  //   return JSON.parse(Utf8ArrayToStr(ZIP.inflateRaw(base64.base64ToBytes(cipher.getKey(passKey)))));
  // } else {
  //   return d.data
  // }
  // if (d.data) {
  //   const cipher = new Cipher();
  //   cipher.key = d.data;
  //   return JSON.parse(Utf8ArrayToStr(ZIP.inflateRaw(base64.base64ToBytes(cipher.getKey(passKey)))));
  // } else {
  //   return d.data
  // }
  // if (d.errors) {
  //   return d.errors[0]?.message;
  // }

  if (d.data) {
    return d.data;
  }
};
