import axios from 'axios';

const IPinfoApiKey = "ef30d20db6e644"

const getUserLocation = async () => {
  try {
    const response = await axios.get(`https://ipinfo.io/json?token=${IPinfoApiKey}`);
    const data = response.data;

    const { city, country, region } = data;
    const continent = getContinent(country);

    return {
        city: `${region}-${city}` || 'Unknown',
        country: country || 'Unknown',
        continent: continent || 'Unknown',
    };
  } catch (error) {
    console.error('Error getting user location:', error);
    throw error;
  }
};

const continentMap = {
  'North America': ['US', 'CA', 'MX', 'CR', 'PA', 'JM'],
  'Europe': ['GB', 'DE', 'FR', 'IT', 'ES', 'RU'],
  'Asia': ['CN', 'JP', 'IN', 'KR', 'ID', 'TH'],
  'South America': ['BR', 'AR', 'CL', 'PE', 'CO', 'VE'],
  'Africa': ['NG', 'ZA', 'KE', 'EG', 'ET', 'GH'],
  'Oceania': ['AU', 'NZ', 'FJ', 'PG', 'SB', 'TO'],
};

const getContinent = (countryCode) => {
  const findContinent = (country) => {
    for (const continent in continentMap) {
      if (continentMap[continent].includes(country)) {
        return continent;
      }
    }
    return 'Unknown';
  };

  const country = countryCode.toUpperCase();

  return findContinent(country);
};

export { getUserLocation };

// const getUserLocation = async () => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       if ('geolocation' in navigator) {
//         const position = await getCurrentPosition();
//         const { latitude, longitude } = position.coords;

//         const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
//         const data = await response.json();

//         const address = data.address || {};
//         const city = address.city || address.town || address.village || address.hamlet || address.suburb;
//         const country = address.country;
//         const continent = getContinent(country);

//         resolve({
//           city: city || 'Unknown',
//           country: country || 'Unknown',
//           continent: continent || 'Unknown',
//         });
//       } else {
//         reject(new Error('Geolocation is not supported by your browser.'));
//       }
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// const getCurrentPosition = () => {
//   return new Promise((resolve, reject) => {
//     navigator.geolocation.getCurrentPosition(resolve, reject);
//   });
// };

// const getContinent = (country) => {
//   const continentMap = {
//     'North America': ['US', 'CA', 'MX', 'CR', 'PA', 'JM'],
//     'Europe': ['GB', 'DE', 'FR', 'IT', 'ES', 'RU'],
//     'Asia': ['CN', 'JP', 'IN', 'KR', 'ID', 'TH'],
//     'South America': ['BR', 'AR', 'CL', 'PE', 'CO', 'VE'],
//     'Africa': ['NG', 'ZA', 'KE', 'EG', 'ET', 'GH'],
//     'Oceania': ['AU', 'NZ', 'FJ', 'PG', 'SB', 'TO'],
//   };

//   for (const continent in continentMap) {
//     if (continentMap[continent].includes(country)) {
//       return continent;
//     }
//   }

//   return 'Unknown';
// };

// export { getUserLocation };

// // Example usage:
// // getUserLocation()
// //   .then(location => console.log('User Location:', location))
// //   .catch(error => console.error('Error getting user location:', error));
