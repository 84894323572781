import { getApp, getApps, initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_F_API_KEY,
	authDomain: `${process.env.NEXT_PUBLIC_F_ID}.firebaseapp.com`,
	databaseURL: process.env.NEXT_PUBLIC_F_DB_URL,
	projectId: process.env.NEXT_PUBLIC_F_ID,
	storageBucket: `${process.env.NEXT_PUBLIC_F_ID}.appspot.com`,
	// messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
	// appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
};

export const initFirebase = () => {
	if (!getApps().length) {
		initializeApp(firebaseConfig);
		// if (typeof window !== "undefined") {
		// 	if ("measurementId" in firebaseConfig) {
		// 		getAnalytics();
		// 	}
		// }
	} else getApp()
}
