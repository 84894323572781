/* eslint no-mixed-operators: ["error", {"groups": [["&", "|", "^", "~", "<<", ">>", ">>>"], ["&&", "||"]]}] */
export default function({ x, y, dx, dy, width, height }) {
  let x2;
  let y2;
  //    const theta = dx === 0 ? 1 :  Math.abs(dy/dx);
  const theta = Math.abs(dy / dx);
  const phi = height === 0 ? 0 : height / width;

  const distRatio = {
    dx: dx === 0 ? 0 : Math.abs(dx) / dx,
    dy: dy === 0 ? 0 : Math.abs(dy) / dy,
  };

  if (Math.abs(dx) < width / 2 && Math.abs(dy) < height / 2) {
    return { x2: x, y2: y };
  }

  if (dx === 0) {
    x2 = x;
    y2 = y + (height / 2) * distRatio.dy;
  } else if (theta > phi) {
    x2 = x + (height / (2 * theta)) * distRatio.dx;
    y2 = y + (height / 2) * distRatio.dy;
  } else {
    x2 = x + (width / 2) * distRatio.dx;
    y2 = y + ((width * theta) / 2) * distRatio.dy;
  }

  return { x2, y2 };
}
