import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ResponsiveAppBar from "./AppBar";
import Footer from "./Footer";
// import { useAuthUser, withAuthUser } from "next-firebase-auth";
// import { useRouter } from "next/dist/client/router";
import useAuth from "../hook/auth";

const useMediaQuery = (width) => {
	const [targetReached, setTargetReached] = useState(false);

	const updateTarget = useCallback((e) => {
		if (e.matches) {
			setTargetReached(true);
		} else {
			setTargetReached(false);
		}
	}, []);

	useEffect(() => {
		const media = window.matchMedia(`(max-width: ${width}px)`);
		media.addListener(updateTarget);

		// Check on mount (callback is not called until a change occurs)
		if (media.matches) {
			setTargetReached(true);
		}

		return () => media.removeListener(updateTarget);
	}, []);

	return targetReached;
};

const Layout = ({ children, toggleColorMode, themeMode, pageLoading, }) => {
	const isMobile = useMediaQuery(600);
	const auth = useAuth();
	const { user } = auth || {}
	const { reloadUserInfo } = user || {}
	const isPremium = reloadUserInfo?.customAttributes && (JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PREMIUM" || JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PROFESSIONAL") ? true : false;

	return (
		<>
			<ResponsiveAppBar
				toggleColorMode={toggleColorMode}
				themeMode={themeMode}
				isMobile={isMobile}
				email={user && user.email}
				signOut={auth.signOut}
				pageLoading={pageLoading}
				plan={isPremium ? "premium" : "free"}
			/>
			<Box component='div' sx={{ minHeight: '100vh' }} suppressHydrationWarning>
				{children}
			</Box>
			<Footer />
		</>
	);
}

// export default withAuthUser()(Layout);
export default React.memo(Layout);
