import { hextoRgb, rgbToHex } from ".";

export const interpolateColor = (color1, color2, factor) => {
    let color = '#';
    let fact = factor; //arguments.length < 3 ? 0.5 :
    
    const result = color1.slice();
    
    for (var i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + fact * (color2[i] - color1[i]));
      color += rgbToHex(result[i]);
    }

    return color;
}

export default (colors = [], steps) => {

  const stepFactor = 1 / (steps - 1);
  const interpolatedColorArray = [];
  
  let colorArr = colors.map(d => {
    const color = hextoRgb(d);
    return `rgb(${color.rChannel}, ${color.gChannel}, ${color.bChannel})`;
  });
  
  colorArr = colorArr.map(d => d.match(/\d+/g).map(Number));
  
  const freqency = Math.ceil(steps / (colorArr.length - 1)); 

  for(var i = 0; i < steps; i++) {
    const index = parseInt(i / freqency);
    interpolatedColorArray.push(interpolateColor(colorArr[index], colorArr[index+1], stepFactor * i));
  }

  return interpolatedColorArray;
}