import CryptoJS from 'crypto-js';

const secretKey = process.env.NEXT_PUBLIC_ENCRYPTION_KEY;

export const encrypt = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decrypt = (data) => {
  try {
    // const bytes = CryptoJS.AES.decrypt(data, secretKey);
    // return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedString || decryptedString.trim() === '') {
      return null; // Return null if decrypted string is null or empty
    }
    return JSON.parse(decryptedString);
  } catch (error) {
    console.error("Decryption Error:", error);
    return null;
  }
};

// export async function encrypt(data) {
//   try {
//     // Generate encryption key
//     const key = await window.crypto.subtle.generateKey(
//       {
//         name: 'AES-GCM',
//         length: 256
//       },
//       true,
//       ['encrypt']
//     );

//     // Convert data to Uint8Array
//     const encoder = new TextEncoder();
//     const encodedData = encoder.encode(data);

//     // Generate random initialization vector (IV)
//     const iv = window.crypto.getRandomValues(new Uint8Array(12));

//     // Encrypt data using AES-GCM algorithm
//     const encryptedData = await window.crypto.subtle.encrypt(
//       {
//         name: 'AES-GCM',
//         iv: iv
//       },
//       key,
//       encodedData
//     );

//     // Concatenate IV and encrypted data into a single Uint8Array
//     const encryptedDataWithIV = new Uint8Array([...iv, ...new Uint8Array(encryptedData)]);

//     // Convert encrypted data to base64 string
//     return btoa(String.fromCharCode.apply(null, encryptedDataWithIV));
//   } catch (error) {
//     console.error('Encryption error:', error);
//     throw error;
//   }
// }

// export async function decrypt(encryptedData) {
//   try {
//     // Decode base64 string to Uint8Array
//     const decodedData = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));

//     // Extract IV and encrypted data
//     const iv = decodedData.slice(0, 12);
//     const encryptedDataWithoutIV = decodedData.slice(12);

//     // Generate encryption key
//     const key = await window.crypto.subtle.generateKey(
//       {
//         name: 'AES-GCM',
//         length: 256
//       },
//       true,
//       ['decrypt']
//     );

//     // Decrypt data using AES-GCM algorithm
//     const decryptedData = await window.crypto.subtle.decrypt(
//       {
//         name: 'AES-GCM',
//         iv: iv
//       },
//       key,
//       encryptedDataWithoutIV
//     );

//     // Convert decrypted data to string
//     const decoder = new TextDecoder();
//     return decoder.decode(decryptedData);
//   } catch (error) {
//     console.error('Decryption error:', error);
//     throw error;
//   }
// }
