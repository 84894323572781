import React, { createContext, useContext, useEffect, useRef } from "react";
import {
  Paper, IconButton,
  // Backdrop, Chip,
  Box, ListSubheader, Popper, Tooltip, Typography, Zoom, useMediaQuery, TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme, styled } from '@mui/material/styles';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
// import CircularProgress from '@mui/material/CircularProgress';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import * as gtag from "../../../../lib/google/gtag";
// import parse from 'autosuggest-highlight/parse';
// import match from 'autosuggest-highlight/match';
import * as send from '../../../../lib/analytics/send';
import { searchFund } from "../../../utils/helpers";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle} sx={{ fontWeight: 600, color: 'text.color.white', py: 0, lineHeight: '40px', height: '40px !important', bgcolor: 'background.primary_light' }}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Tooltip title={dataSet[1]?.n.length > 40 ? dataSet[1]?.n : ''} disableInteractive TransitionComponent={Zoom}>
      <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle} sx={{ fontSize: '.8rem', textTransform: 'uppercase', color: 'text.description', '&:hover': { color: 'text.color.white' } }}>
        {dataSet[1].n}
      </Typography>
    </Tooltip>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);
  let noofGroups = itemData.filter(val => {
    return val.group
  })?.length

  return (
    <div ref={ref}>
      {/* {noofGroups && itemData &&
        <Typography variant="caption" display="block" gutterBottom sx={{ textAlign: 'right', px: 2, pt: 1, color: 'text.description' }}>
          <Typography variant="caption" sx={{ color: '#000' }}>
            &nbsp;{itemData.length - noofGroups}&nbsp;
          </Typography>
          of 8205 results.
        </Typography>} */}
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};


const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
// const fund = ({ tab_data }) => {
const SearchFund = ({ setShowSearch, removebackdrop, placeholder, dataEventFrom }) => {
  const [value, setValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const router = useRouter()
  const loading = open && options.length === 0;
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearch = (search_term) => {
    router.push(`/funds/${search_term}`);
    send.uevent(event, `fund-search-${dataEventFrom} ${search_term}`)
    gtag.event({
      action: "fund_search",
      search_term: search_term,
    });
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      // let fundSearch_jsonData = require('../../../../public/assets/search/funds.json');
      // fundSearch_jsonData = fundSearch_jsonData.sort((a, b) => a.n.toUpperCase().localeCompare(b.n.toUpperCase()))
      let fundSearch_jsonData = await searchFund({
        url: `/api/fund-search`,
        searchString: "",
      }) || [];
      // fundSearch_jsonData = fundSearch_jsonData.sort((a, b) => {
      //   const nameA = a.companyName.toUpperCase(); // ignore upper and lowercase
      //   const nameB = b.companyName.toUpperCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }
      //   // names must be equal
      //   return 0;
      // });

      if (active && fundSearch_jsonData) {
        setOptions([...fundSearch_jsonData]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);



  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        AlignItems: 'center',
        flexDirection: 'column',
        // px: 1,
        bgcolor: 'rgba(255, 255, 255, 0.15)',
        borderRadius: '4px',
      }}
    >
      <Paper
        component="form"
        sx={{
          display: 'flex',
          alignItems: 'center',
          margin: '0 auto',
          bgcolor: 'transparent',
          zIndex: open ? (theme) => theme.zIndex.appBar : 0,
          // width: '28ch',
          height: '1.4375em',
          margin: ' 8px 0px 8px 0px',
          boxShadow: 0,
          '& .Mui-focused .MuiTextField-root': {
            width: { md: '49.4ch !important', xs: '28ch' },
          },
        }}
      >
        {/* {loading ?
          <CircularProgress color="inherit" size={20} sx={{ m: 1.5, color: "#fff" }} />
          :
          <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon
              sx={{ color: '#fff' }}
              onClick={e => e.preventDefault()}
            />
          </IconButton>} */}
        <IconButton type="submit" sx={{ p: '10px', position: 'absolute' }} aria-label="search" onClick={e => e.preventDefault()}>
          <SearchIcon sx={{ color: '#fff', fontSize: '1.2rem' }} />
        </IconButton>
        <Autocomplete type="search" data-event-name="Search" data-event-category={dataEventFrom} data-event-label="Fund Search"
          id="search-funds"
          autoHighlight
          disableListWrap
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          sx={{
            color: '#fff',
            transition: 'all ease-in-out .2s',
            fontSize: '.87rem',
            '& .MuiPaper-root-MuiAutocomplete-paper': {
              boxShadow: 0
            },
            '& .MuiAutocomplete-input': {
              color: '#fff'
            },
            '&.MuiAutocomplete-root .MuiFilledInput-root .MuiAutocomplete-endAdornment svg': {
              color: '#fff',
            },
            '&.MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input': {
              padding: ' 2.5px 0 2.5px 32px',
              fontSize: '.9rem'
            }
          }}
          open={open}
          onOpen={() => { setOpen(true); }}
          onClose={() => { setOpen(false); setShowSearch(false) }}
          onChange={(event, newValue) => { setValue(newValue); router && newValue && newValue.f ? handleSearch(newValue.f) : event.preventDefault() }}
          isOptionEqualToValue={(option, value) => option.n === value.n}
          filterOptions={(options, { inputValue }) => inputValue != "" && options.filter(item => item.n.toLowerCase().includes(inputValue.toLowerCase()))}
          // options={fundSearch_jsonData && fundSearch_jsonData.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          // groupBy={(option) => option.companyName.charAt(0)}
          options={options}
          loading={loading}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            // return option.companyName;
            return '';
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          // renderOption={(props, option) => <li {...props} style={{ fontSize: '.87rem', textTransform: 'uppercase' }}>{option.companyName}</li>}
          renderOption={(props, option) => [props, option]}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder ? placeholder : "Search Funds..."}
              size="small"
              variant="filled"
              hiddenLabel
              clearOnBlur
              clearOnEscape
              sx={{
                width: { md: '35.4ch', xs: '28ch' },
                animationDuration: "10ms",
                transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                '& .MuiFilledInput-root': {
                  py: '8px !important'
                },
                '& .MuiFilledInput-root, .MuiFilledInput-root:hover, .MuiFilledInput-root:focus': {
                  background: 'transparent',
                },
                '&  .MuiFilledInput-root, .MuiFilledInput-root:hover, .MuiFilledInput-root:before, .MuiFilledInput-root:after': {
                  borderBottom: '0 !important',
                },
                '& .MuiInputBase-root-MuiFilledInput-root:before, .MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before': {
                  borderBottom: '0 !important',
                },
                '& .MuiInputBase-input-MuiFilledInput-input': {
                  fontSize: '.87rem'
                },
              }}
            />
          )}
          renderGroup={(params) => params}
        />

      </Paper>
      {/* {!removebackdrop &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.appBar - 2 }}
          open={open}
        />} */}
    </Box>
  );
}
export default (SearchFund);
