export const GA_TRACKING_ID = process.env.GA_MEASUREMENT_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url, consent) => {
  try {
    // if (typeof window !== "undefined" && window.gtag) {
    if (consent) {
      window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
      });
    } else {
      window.gtag("config", GA_TRACKING_ID, {
        send_page_view: false
      });
    }
    // window.gtag("config", GA_TRACKING_ID, {
    //   page_path: url,
    //   send_page_view: consent
    // });
    // }
  } catch (error) {
    console.log("Error from the gtap_page_view:", error);
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value, line_items, coupon, transaction_id, currency, customer_id, customer_email, plan, method, content_type, blog_id, search_term, uid }) => {
  try {
    // if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", action, {
      transaction_id: transaction_id,
      customer_id: customer_id,
      customer_email: customer_email,
      currency: currency,
      value: value,
      line_items: line_items,
      plan: plan,
      coupon: coupon,
      event_category: category,
      event_label: label,
      method: method,
      content_type: content_type,
      blog_id: blog_id,
      search_term: search_term,
      uid: uid,
    });
    // }
  } catch (error) {
    console.log("Error from the gtag_event:", error);
  }
};

