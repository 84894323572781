// import * as d3 from 'd3';
import { timeParse } from 'd3'
import groupBy from 'lodash.groupby';
import { getTradingItem } from '../helpers/time';
// import { amber, blueGrey, deepOrange, grey, lightBlue } from '@mui/material/colors';
// import { darkScrollbar } from '@mui/material';
// import { FIELDS } from './D3/constants';
import { dataConstants } from '../constants';
import { roundNum } from '../helpers';
import { red } from "@mui/material/colors";
const { SETTINGS, NAVS, STOCK_TABS, STOCK_TABS_CODES } = dataConstants;

const track = '#202022';
const thumb = '#585859';
const active = '#838384';

// export const parseDate = d3.timeParse('%Y-%m-%d');

// export const timeFormat = d3.timeFormat('%Y-%m-%d');
export const parseDate = timeParse('%Y-%m-%d');

// export const timeFormat = timeFormat('%Y-%m-%d');
// export const chartSettingsGroup = {
//   [FIELDS.TITLE]: {
//     text: [SETTINGS.COLOR, SETTINGS.FF, SETTINGS.FS],
//   },
//   [FIELDS.SUBTITLE]: {
//     text: [SETTINGS.COLOR, SETTINGS.FF, SETTINGS.FS],
//   },
//   [FIELDS.PERIOD]: {
//     label: [SETTINGS.COLOR, SETTINGS.FF, SETTINGS.FS],
//     boundary: [SETTINGS.STROKE, SETTINGS.STROKEWIDTH],
//     rect: [SETTINGS.OPACITY, SETTINGS.BGCOLOR],
//   },
//   [FIELDS.ANNOTATION]: {
//     header: [SETTINGS.COLOR, SETTINGS.FF, SETTINGS.FS],
//     sub: [SETTINGS.COLOR, SETTINGS.FF, SETTINGS.FS],
//     line: [SETTINGS.STROKE, SETTINGS.STROKEWIDTH],
//     arrow: [SETTINGS.COLOR],
//     dataLine: [SETTINGS.STROKEWIDTH],
//   },
//   [FIELDS.PCTCHANGE]: {
//     line: [SETTINGS.STROKE, SETTINGS.STROKEWIDTH],
//     tagLine: [SETTINGS.STROKE, SETTINGS.STROKEWIDTH],
//   },
//   [FIELDS.EVENT]: {
//     line: [SETTINGS.STROKE, SETTINGS.STROKEWIDTH],
//     label: [SETTINGS.COLOR, SETTINGS.FF, SETTINGS.FS],
//   },
//   [FIELDS.THRESHOLD]: {
//     line: [SETTINGS.STROKE, SETTINGS.STROKEWIDTH],
//     label: [SETTINGS.COLOR, SETTINGS.FF, SETTINGS.FS],
//   },
//   [FIELDS.XAXIS]: {
//     line: [SETTINGS.STROKE, SETTINGS.STROKEWIDTH],
//   },
//   [FIELDS.YAXIS]: {
//     line: [SETTINGS.STROKE, SETTINGS.STROKEWIDTH],
//   },
// };

export const getChartSelector = selector =>
  Object.keys(dataConstants.CHART_SELECTOR).find(key => dataConstants.CHART_SELECTOR[key].selector === selector);

export const isElement = obj => {
  try {
    // Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  } catch (e) {
    // Browsers not supporting W3 DOM2 don't have HTMLElement and
    // an exception is thrown and we end up here. Testing some
    // properties that all elements have (works on IE7)
    return typeof obj === 'object' && obj.nodeType === 1 && typeof obj.style === 'object' && typeof obj.ownerDocument === 'object';
  }
};

export const isExcluded = (item, items) => !!items.find(d => d === item);

export const getCookie = key => {
  const re = new RegExp(`${key}=([^;]+)`);
  const value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : null;
};

export const escapeQuotes = str => str.replace(/"/g, '\\"').replace(/'/g, "\\'");

export const espaceNewLine = str => str.replace(/\\n/g, '\\\\n');

export const escapeBackslash = str => str.replace(/\\/g, '\\\\\\');

export const stringify = obj => {
  if (typeof obj !== 'object' || obj === null || obj instanceof Array) {
    return value(obj);
  }

  return `{${Object.keys(obj)
    .map(k => (typeof obj[k] === 'function' ? null : `${k}: ${value(obj[k])}`))
    .filter(i => i)}}`;
};

const value = val => {
  switch (typeof val) {
    case 'string':
      return `"${val.replace(/\\/g, '\\\\').replace('"', '\\"')}"`;
    case 'number':
    case 'boolean':
      return `${val}`;
    case 'function':
      return 'null';
    case 'object':
      if (val instanceof Date) return `"${val.toISOString()}"`;
      if (val instanceof Array) return `[${val.map(value).join(',')}]`;
      if (val === null) return 'null';
      return stringify(val);
    default:
      return val;
  }
};

export const strokeWidths = [
  { option: 'stoketype1', value: 0.5 },
  { option: 'stoketype2', value: 1 },
  { option: 'stoketype3', value: 1.5 },
  { option: 'stoketype4', value: 2 },
  { option: 'stoketype5', value: 2.5 },
  { option: 'stoketype6', value: 3 },
  { option: 'stoketype7', value: 5 },
  { option: 'stoketype8', value: 7 },
];

export const noSvgPages = [NAVS.PRIVACY, NAVS.PINNED, NAVS.TERMS, NAVS.INTRO];

// export const addCommas = str => {
//   let nStr = str + '';
//   const x = nStr.split('.');
//   let x1 = x[0];
//   let x2 = x.length > 1 ? '.' + x[1] : '';
//   const rgx = /(\d+)(\d{3})/;

//   while (rgx.test(x1)) {
//     x1 = x1.replace(rgx, '$1' + ',' + '$2');
//   }
//   return x1 + x2;
// }

export const addCommas = str => {
  let nStr = str + '';

  // Check if the input string is a valid number
  if (!nStr.match(/^-?\d+(?:\.\d+)?$/)) {
    // console.error("Input is not a valid number");
    return str;
  }

  // Split the input string into integer and decimal parts
  const x = nStr.split(/\.|(,)/);
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[2] : '';
  const rgx = /(\d+)(\d{3})/;

  // Add commas to the integer part
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }

  // Check if the number is greater than or equal to 100 or less than or equal to -100
  if (Math.abs(parseFloat(nStr)) >= 100) {
    return x1;
  }

  // Add ".00" for numbers less than 100 and greater than or equal to -100
  return x1 + (x2 === '' ? '.00' : x2);
};

export const convertUrlParamStr = (str = '') =>
  str.replace(/\s\&/g, '').replace(/\&/g, '').replace(/\s/g, '-');

export const convertStrFromUrlParam = (param = '') =>
  param.replace(/\-/g, ' ');

export const defStockTabs = [
  // { code: STOCK_TABS_CODES.SUM, name: 'Summary', types: null, seq: 1, urlName: STOCK_TABS.SUM },
  { code: STOCK_TABS_CODES.QA, name: 'Q & A', types: null, seq: 2, urlName: STOCK_TABS.QA },
  { code: STOCK_TABS_CODES.CHART, name: 'Charts', types: null, seq: 3, urlName: STOCK_TABS.CHART },
  { code: STOCK_TABS_CODES.NOTE, name: 'My Notes', types: null, seq: 4, urlName: STOCK_TABS.NOTE },
  // { code: STOCK_TABS_CODES.RET, name: 'Historical Returns', types: null, seq: 5, urlName: STOCK_TABS.RET },
];

export const getTradingData = (data = [], timeZone) => data.map(d => getTradingItem(d, timeZone));

export const isFloat = n => Number(n) === n && n % 1 !== 0;

export const isInt = n => Number(n) === n && n % 1 === 0;

// export const getFormatEntity = n => {
//   if (!n || n && isNaN(n))
//     return null;

//   if (Math.log10(Math.abs(n)) >= 7) {
//     return 'Millions';
//   } else if (Math.log10(Math.abs(n)) >= 3) {
//     return 'Thousands';
//   } else {
//     return null;
//   }
// }

export const getFormatEntity = n => {
  if (n === null || n === undefined || isNaN(n)) {
    return null;
  } else if (Math.log10(Math.abs(n)) >= 7) {
    return 'Millions';
  } else if (Math.log10(Math.abs(n)) >= 3) {
    return 'Thousands';
  } else {
    return null;
  }
}

export const checkEntityStr = (n, single) => single ? n.substr(0, n.length - 1) : n;

export const getFormatEntityN = (n, single) => {
  if (!n || n && isNaN(n))
    return null;

  if (Math.log10(Math.abs(n)) >= 9) {
    return checkEntityStr('Billions', single);
  } else if (Math.log10(Math.abs(n)) >= 7) {
    return checkEntityStr('Millions', single);
  } else if (Math.log10(Math.abs(n)) >= 3) {
    return checkEntityStr('Thousands', single);
  } else {
    return null;
  }
}

export const formatValue = (n, format, single) => {
  if (!n || n && isNaN(n))
    return n;

  if (format && Math.log10(Math.abs(n)) >= 3) {
    return (format === checkEntityStr('Billions', single) ? n / 1000000000 : (format === checkEntityStr('Millions', single) ? n / 1000000 : n / 1000));
  } else {
    return format ? `${isInt(n) ? n : (n > 1000 ? n : roundNum(n))}*` : n;
  }
}


export const getFormulaData = tab_data => {
  let dataArr = tab_data.map(d => {
    let { formula, data } = d;

    const codeWiseData = groupBy(data, d => d.code);
    const latestArr = [];

    Object.keys(codeWiseData).map(c => {
      latestArr.push(codeWiseData[c].max('recordDate'));
    });

    latestArr.forEach(it => {
      formula = formula.replace(new RegExp(it.code, 'g'), it.value);
    });

    try {
      const value = eval(formula);
      return { ...d, formulaVal: value };
    } catch (err) {
      console.log(err);
      return null;
    }
  });

  dataArr = dataArr ? dataArr.filter(d => d) : dataArr;

  return dataArr && dataArr.length > 0 ? dataArr : null;
}

export const formatTableValue = (val, isFormat) => {
  if (isNaN(val)) {
    if (Date.parse(val) !== NaN) {
      return val;
    } else {
      return val;
    }
  } else {
    return isFormat ? formatValue(val) : addCommas(val);
  }
}

export const tableCellClass = (val, isFormat) => {
  if (isNaN(val)) {
    return '';
  } else {
    return 'td-right';
  }
}

export const getPctBarColor = val => {
  return val <= 20 ? '#EE510E' : (val > 20 && val <= 50 ? '#FBAE21' : '#00C68B');
}

export const convertExcelToJSDate = (excelDate, startCode = 25569, format = 'YYYY-MM-DD') => {
  const diff = excelDate - startCode;
  const millSec = diff * 86400 * 1000;

  return format(new Date(millSec)).format(format);
}


export const handleFirebaseError = (errorMessage) => {
  let errorExplanation = '';

  switch (errorMessage) {
    // Email/Password Auth Errors
    case 'auth/user-not-found':
      errorExplanation = 'The email address is not registered. Please sign up for an account.';
      break;
    case 'auth/wrong-password':
      errorExplanation = 'Please double-check your password. If you forgot your password, you can reset it using the "Forgot Password" option.';
      break;
    case 'auth/invalid-email':
      errorExplanation = 'Please enter a valid email address.';
      break;
    case 'auth/user-disabled':
      errorExplanation = 'This account has been disabled by an administrator. Please contact support for assistance.';
      break;
    case 'auth/user-token-expired':
      errorExplanation = 'Please sign in again to refresh your session.';
      break;
    case 'auth/too-many-requests':
      errorExplanation = 'Your account has been temporarily blocked due to too many failed login attempts. Try again later or reset your password.';
      break;
    case 'auth/network-request-failed':
      errorExplanation = 'There was an issue with the network. Please check your internet connection and try again.';
      break;
    case 'auth/weak-password':
      errorExplanation = 'Please choose a stronger password.';
      break;
    case 'auth/operation-not-allowed':
      errorExplanation = 'The type of account corresponding to the email address is not enabled. Please contact support for assistance.';
      break;
    case 'auth/account-exists-with-different-credential':
      errorExplanation = 'An account with the same email address but different sign-in credentials already exists. Try signing in with a different method or link your accounts.';
      break;

    // Google Auth Errors
    case 'auth/account-exists-with-different-credential':
      errorExplanation = 'An account with the same email address but different sign-in credentials already exists. Try signing in with a different method or link your accounts.';
      break;
    case 'auth/auth-domain-config-required':
      errorExplanation = 'The authentication domain is not configured. Please check your Firebase project settings.';
      break;
    case 'auth/cancelled-popup-request':
      errorExplanation = 'The popup request was cancelled by the user.';
      break;
    case 'auth/operation-not-allowed':
      errorExplanation = 'Google sign-in is not enabled. Please check your Firebase project settings.';
      break;
    case 'auth/operation-not-supported-in-this-environment':
      errorExplanation = 'This operation is not supported in the current environment.';
      break;
    case 'auth/popup-blocked':
      errorExplanation = 'Your browser\'s popup blocker prevented the authentication popup from opening. Please disable the popup blocker or allow popups for this site.';
      break;
    case 'auth/popup-closed-by-user':
      errorExplanation = 'The authentication popup was closed before completing the sign-in process. Please try signing in again.';
      break;
    case 'auth/unauthorized-domain':
      errorExplanation = 'The domain is not authorized for OAuth operations. Please check your Firebase project settings.';
      break;
    case 'auth/user-token-expired':
      errorExplanation = 'The user\'s token has expired. Please sign in again.';
      break;

    // Twitter Auth Errors
    case 'auth/invalid-credential':
      errorExplanation = 'The Twitter credentials provided are invalid. Please check your Twitter app settings.';
      break;
    case 'auth/account-exists-with-different-credential':
      errorExplanation = 'An account with the same email address but different sign-in credentials already exists. Try signing in with a different method or link your accounts.';
      break;
    case 'auth/operation-not-allowed':
      errorExplanation = 'Twitter sign-in is not enabled. Please check your Firebase project settings.';
      break;
    case 'auth/popup-blocked':
      errorExplanation = 'Your browser\'s popup blocker prevented the authentication popup from opening. Please disable the popup blocker or allow popups for this site.';
      break;
    case 'auth/popup-closed-by-user':
      errorExplanation = 'The authentication popup was closed before completing the sign-in process. Please try signing in again.';
      break;
    case 'auth/unauthorized-domain':
      errorExplanation = 'The domain is not authorized for OAuth operations. Please check your Firebase project settings.';
      break;
    case 'auth/user-token-expired':
      errorExplanation = 'The user\'s token has expired. Please sign in again.';
      break;

    // Default case for unknown errors
    default:
      errorExplanation = 'An unexpected error occurred. Please try again. If the issue persists, contact support.';
      break;
  }

  return errorMessage && errorMessage !== "" ?
    <p style={{ fontSize: '.87rem' }}>{errorMessage} <br /><span style={{ color: "#595959", fontSize: '.8rem' }}>{errorExplanation} </span></p> : ``;
}

// export const getTimeDiffStr = (date, now = new Date()) => {
//   const first = moment(new Date(date));
//   const second = moment(now);
//   const times = [{
//     str:'years',
//   }, 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'
//   ];

//   let diff = 0;
//   let i = 0;

//   while(diff === 0) {
//     diff = second.diff(first, times[i]);
//     i += 1;
//   }

//   return i <= times.length ? `${diff} ${times[i - 1]} ago` : `less than a seconds ago`;
// }

export const noHeaderLinks = [
  {
    nav: NAVS.CHART,
    class: 'chart-container',
    footer: 'chart-footer',
    noHeader: true,
    // noFooter: true,
  },
  {
    nav: NAVS.HOME_TEMP,
    class: 'home-container',
    footer: 'home-footer',
  },
  {
    nav: NAVS.STOCK,
    class: 'stock-container',
    footer: 'home-footer',
    // noFooter: true,
  },
  {
    nav: NAVS.DATA_EXPLORER,
    //class: 'data-container',
    footer: 'home-footer'
  },
  {
    nav: NAVS.INTRO,
    class: 'intro-container',
    footer: 'intro-footer',
    showBG: true,
  },
  {
    nav: NAVS.SIGNIN,
    class: 'log-container',
    noHeader: true,
    noFooter: true,
  },
  {
    nav: NAVS.SIGNUP,
    class: 'log-container',
    noHeader: true,
    noFooter: true,
  },
  {
    nav: NAVS.FORPASS,
    class: 'log-container',
    noHeader: true,
    noFooter: true,
  },
  {
    nav: NAVS.EMBED_CHART,
    class: 'embed-chart-container',
    noHeader: true,
    noFooter: true,
  },
  {
    nav: NAVS.CHART_SHARE,
    class: 'chart-share-container',
    noHeader: true,
    noFooter: true,
  },
  {
    nav: NAVS.CHARTS,
    class: 'charts-container',
    noFooter: true,
  }
];

export const checkUserAccess = login => {
  if (login) {
    const { isTrial, isTrialExpired, isPremium } = login || {};

    // if(plans) {
    //const planItem = plans.find(d => d.type === premiumType);

    // if(isPremium || (isTrial && !isTrialExpired)) return true;
    if (isPremium || (isTrial && !isTrialExpired)) return true;
    //}
  }

  return false;
}

export const hasPremiumAccess = (login, isLoginRedirect) => {
  if (isLoginRedirect) {
    if (checkUserAccess(login)) {
      return true;
    } else {
      return false
    }
  } else {
    return true
  }
}


export const checkFirebaseUserAccess = login => {
  if (login) {
    const { isTrial, isTrialExpired, isPremium } = login || {};
    if (isPremium || (isTrial && !isTrialExpired)) return true;
  }
  return false;
}

export const hasPremiumAccessToFirebaseUser = (auth) => {
  const {
    claims,
    firebaseUser,
  } = auth || {}
  const { metadata } = firebaseUser || {}
  const { userType } = claims || {}
  const { creationTime, lastSignInTime, a, b } = metadata || {}

  let creationDate = new Date(creationTime);
  let lastSignInDate = new Date(lastSignInTime);
  let daysDifference = parseInt((lastSignInDate - creationDate) / (1000 * 60 * 60 * 24), 10);
  let isLoginRedirect = creationDate && lastSignInDate && daysDifference && daysDifference >= 8 ? true : false
  // let login = userType ? { isPremium: userType === 'PREMIUM' ? true : false } : undefined;
  let isPremium = userType ? ((userType === 'PREMIUM' || userType === 'PROFESSIONAL') ? true : false) : false;
  if (isLoginRedirect) {
    // if (checkFirebaseUserAccess(login)) {
    if (isPremium) {
      return false;
    } else {
      return true
    }
  } else {
    return true
  }
}


export const getRealDate = (date) => {
  if (date) {
    let str_date = date;
    let arr_date = str_date.split('-');
    let months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let regex = new RegExp("^0+(?!$)", 'g');

    return `${months[arr_date[2].replaceAll(regex, "")] + ' ' + arr_date[1] + ', ' + arr_date[0]}`
  }
  return false
}

export const fileObjToBlob = (file, onBlob) => {
  if (!file) return null;

  const reader = new FileReader();

  reader.addEventListener("load", function () {
    const blobObj = dataURItoBlob(reader.result);

    if (onBlob) onBlob(blobObj);
  });

  reader.readAsDataURL(file);
}

export const dataURItoBlob = dataURI => {
  if (!dataURI) return null;

  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const getNavFromUrl = (url, current) => {
  if (!url) return url;

  return url.replace(`${process.env.NEXT_PUBLIC_URL}${current}/`, '');
}

export const isLocal = () => typeof window !== "undefined" ? window.location.hostname === 'localhost' : null;

export const swapping = (a, b) => ({ a: b, b: a });

export const getCssBarXPos = (min, max, val = 0) => {
  return (val - Math.min(0, min)) / (max - Math.min(0, min));
}

export const getChartSeriesColor = (series, altColor) => {
  const defColor = altColor || '#000000';
  if (series && series.setting) {
    if (series.type === dataConstants.CHART_SERIES_TYPE.LINE) {
      return series.setting.line && series.setting.line.stroke ? series.setting.line.stroke : defColor;
    }
    else if (series.type === dataConstants.CHART_SERIES_TYPE.AREA) {
      return series.setting.area && series.setting.area.fill ? series.setting.area.fill : (series.setting.line && series.setting.line.stroke ? series.setting.line.stroke : defColor);
    }
    else if (series.type === dataConstants.CHART_SERIES_TYPE.BAR) {
      return series.setting.rect && series.setting.rect.fill ? series.setting.rect.fill : defColor
    }
  }

  return defColor;
}

export const getRandomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const embedPages = [dataConstants.NAVS.EMBED_CHART, dataConstants.NAVS.CHART_SHARE];

export const getHighLengthIndex = arr => {
  let index = 0;

  arr.reduce((len, item, indx) => {
    if (len < item.length) {
      index = indx;
      len = arr.length;
    }

    return len;
  }, 0);

  return index;
}

export const getObjHighLengthIndex = obj => {
  let index = 0;

  Object.keys(obj).reduce((len, c, indx) => {
    if (len < obj[c]?.length) {
      index = indx;
      len = obj[c].length;
    }

    return len;
  }, 0);

  return index;
}

export const NavMenus = [
  { name: "Stocks", url: `${dataConstants.NAVS.STOCK}`, code: dataConstants.NAVS.STOCK },
  { name: "Funds", url: `${dataConstants.NAVS.FUNDS}`, code: dataConstants.NAVS.FUNDS },
  { name: "Economy", url: `${dataConstants.NAVS.US_ECONOMY}`, code: dataConstants.NAVS.US_ECONOMY },
  { name: "Sectors", url: `${dataConstants.NAVS.SECTORS}`, code: dataConstants.NAVS.SECTORS },
  { name: "Screener", url: `${dataConstants.NAVS.SCREENER}`, code: dataConstants.NAVS.SCREENER },
  { name: "Blogs", url: `${dataConstants.NAVS.BLOGS}`, code: dataConstants.NAVS.BLOGS },
  // {name: "Covid19", url: `${dataConstants.NAVS.COVID19}/US-United-States`, code: dataConstants.NAVS.COVID19 },
  // made changes to this on 28 Jan 2022 and diverted blogs to blog.grufity
  // {
  //   name: "Research", url: '', code: 'Research', dropdown: dataConstants.HEADER_DROPDOWNS.RESEARCH, subItems: [
  //     { name: "Charts", url: dataConstants.NAVS.CHARTS },
  //     { name: "Blogs", url: dataConstants.NAVS.BLOGS },
  //     { name: "Dashboards", url: dataConstants.NAVS.DASHBOARDS },
  //   ]
  // },
  {
    name: "Tools", url: '', code: 'Tools', dropdown: dataConstants.HEADER_DROPDOWNS.TOOLS, subItems: [
      { name: "Data Explorer", url: dataConstants.NAVS.DATA_EXPLORER },
      { name: "Charts", url: dataConstants.NAVS.CHARTS },
      { name: "Create Chart", url: dataConstants.NAVS.CHART, newTab: true },
    ]
  },
  { name: "Pricing", url: `${dataConstants.NAVS.PRICING}`, code: dataConstants.NAVS.PRICING, nonSubscribed: true },
];

export const convertTblFromData = data => {
  const meta = data.find(d => d.rowN === 0 && d.colN === 0);
  const header = data.filter(d => d.rowN === 0 && d.colN > 0).map(d => ({ ...d, dv: d.dv && d.dv[0] === '{' ? JSON.parse(d.dv) : d.dv }));
  const rows = data.filter(d => d.rowN !== 0);
  return { header, rows: groupBy(rows, d => d.rowN), meta };
}

export const orderTblByDv = (rows, code, order = dataConstants.SORT_DIR.DESC) => {
  const keys = Object.keys(rows);
  let arr = keys.map(k => rows[k]);
  const colIndex = arr && arr.length > 0 ? arr[0].findIndex(d => d.dv.endsWith(code)) : -1;

  arr = colIndex >= 0 ? arr.sort((a, b) => order === dataConstants.SORT_DIR.DESC ? (b[colIndex].val - a[colIndex].val) : (a[colIndex].val - b[colIndex].val)) : [];

  return arr;
}

export const getColsFromDV = (header, colDVs, nameInclude = true) => {
  return header.filter(d => colDVs.includes(d.dv) || (nameInclude && d.val === 'Name'));
}

export const orderTblByCol = (rows, sortProps) => {
  const { colN, dir } = sortProps || {};
  const keys = Object.keys(rows);
  let arr = keys.map(k => rows[k]);

  if (sortProps) {
    const colIndex = arr && arr.length > 0 ? arr[0].findIndex(d => d.colN === colN) : -1;
    arr = colIndex >= 0 ? arr.sort((a, b) => compareFn(a[colIndex].val, b[colIndex].val, dir)) : [];
  }

  return arr;
}

export const compareFn = function (a, b, dir) {
  if (a === b) return 0;
  if (!isDefined(a) || isEmpty(a)) return 1;
  else if (!isDefined(b) || isEmpty(b)) return -1;

  if (isNaN(a) && isNaN(a.replace(/\,/g, '')) && isNaN(b) && isNaN(b.replace(/\,/g, ''))) {
    if (new Date(a).toString() !== "Invalid Date" && new Date(b).toString() !== "Invalid Date") {
      return dir === dataConstants.SORT_DIR.DESC ? +new Date(b) - +new Date(a) : +new Date(a) - +new Date(b);
    }

    a = (a || '').toLowerCase();
    b = (b || '').toLowerCase();

    if (a < b) return dir === dataConstants.SORT_DIR.DESC ? 1 : -1;
    if (a > b) return dir === dataConstants.SORT_DIR.DESC ? -1 : 1;
    return 0;
  }

  if (isNaN(a) && !isNaN(a.replace(/\,/g, ''))) a = a.replace(/\,/g, '');
  if (isNaN(b) && !isNaN(b.replace(/\,/g, ''))) b = b.replace(/\,/g, '');

  return dir === dataConstants.SORT_DIR.DESC ? +b - +a : +a - +b;
}

export const isEmpty = it => isDefined(it) && typeof it === 'string' ? it.trim() === '' : false;

export const isDefined = it => it !== null && it !== undefined;

export const isNumber = it => it ? (!isNaN(it) || !isNaN(it.replace(/\,/g, ''))) : false;

export const convertTickerTblFromData = data => {
  const obj = { ...data };
  let { tickers } = obj || {};
  obj.tickers = tickers || [];
  obj.cols = ["Ticker", "Price", "%Chg"];
  obj.tbl = {};

  obj.tickers.forEach(t => {
    obj.tbl[t.Ticker] = obj.price.filter(d => d.ticker === t.Ticker);
  });

  return obj;
}

export const getPercent = (a, b, rounds) => {
  const percent = roundNum((a - b) * 100 / b, rounds);

  return percent >= 0 ? `+${percent}` : percent;
}

export const convertWatchJSONfromStr = str => {
  let list;

  try {
    list = str.split("\n");
    list = list.reduce((l, d) => {
      if (!l[l.length - 1].label && !l[l.length - 1].tickers && d.trim(' ') === '')
        return l;

      if (d.indexOf('#') >= 0) {
        if (l[l.length - 1].label) {
          throw new Error();
        } else {
          if (d.match(/#/g) && d.match(/#/g).length > 1) {
            throw new Error();
          }
        }
      }

      if (l[l.length - 1].tickers && l[l.length - 1].tickers.length > 0 && d.trim() === '')
        l.push({ label: '' });
      else if (d.trim() === '' && isDefined(l[l.length - 1].label))
        return l;
      else if (d.trim() === '' && !isDefined(l[l.length - 1].label)) l.push({ label: '' });
      else if (!l[l.length - 1].label && d.indexOf('#') >= 0) {
        l[l.length - 1].label = d.substr(1);
      }
      else {
        if (!/[a-zA-Z]+/.test(d.trim()))
          throw new Error();

        if (!l[l.length - 1].tickers) l[l.length - 1].tickers = [];

        l[l.length - 1].tickers.push(d);
      }

      return l;
    }, [{ label: "" }]);
  }
  catch (err) {
    return { error: err };
  }

  return { data: list.filter(d => isDefined(d.tickers) && isDefined(d.label)) };
}

export const convertWatchStrfromJSON = arr => {
  let str = "";

  arr.forEach((d, i) => {
    str += `#${d.label}\n`;

    d.tickers.forEach(t => {
      str += `${t}\n`;
    });

    if (i !== arr.length - 1)
      str += '\n';
  });

  return str;
}

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    // body: mode === 'dark' ? darkScrollbar() : null,
    //   primary: {
    //     ...amber,
    //     ...(mode === 'dark' && {
    //       main: amber[300],
    //     }),
    //   },
    ...(mode === 'dark' && {
      background: {
        default: '#1d2025',
        paper: '#323f4b',
      },
    }),
    // text: {
    //   ...(mode === 'light'
    //     ? {
    //       primary: grey[900],
    //       secondary: grey[800],
    //     }
    //     : {
    //       primary: '#fff',
    //       secondary: grey[900],
    //     }),
    // },
    // ...(mode === 'dark' ? {
    //   scrollbarColor: `${thumb} ${track}`,
    //   '*::-webkit-scrollbar': {
    //     backgroundColor: track,
    //     width: '20px',
    //   },
    //   '*::-webkit-scrollbar': {
    //     backgroundColor: track,
    //   },
    //   '*::-webkit-scrollbar-thumb': {
    //     borderRadius: 8,
    //     backgroundColor: thumb,
    //     minHeight: 24,
    //     border: `3px solid ${track}`,
    //   },
    //   '*::-webkit-scrollbar-thumb:focus': {
    //     backgroundColor: active,
    //   },
    //   '*::-webkit-scrollbar-thumb:active': {
    //     backgroundColor: active,
    //   },
    //   '*::-webkit-scrollbar-corner': {
    //     backgroundColor: track,
    //   },
    // } : {}),
  },
});


export const getPreferredTheme = (systemPreference, userPreference) => {
  // const mode = systemPreference ? 'dark' : 'light';
  const mode = 'light';
  return {
    palette: {
      // mode: systemPreference ? 'dark' : 'light',
      mode,
      ...(mode === 'dark' && {
        background: {
          default: 'rgb(10, 25, 41)',
          paper: '#202e3c',
          primary: 'rgb(10, 25, 41)',
          primary_light: '#323f4b',
          selected: '',
          sorted: '#403f344d',
        },
        text: {
          default: 'rgba(255, 255, 255, 0.7)',
          link: '#2DA1EE',
          description: 'rgba(255, 255, 255, 0.7)',
          positive: '#0e9e19',
          negative: '#EB0000',
          regular: 'rgba(0, 0, 0, 0.87)',
          color: {
            white: 'rgb(255, 255, 255)',
            black: 'rgba(0, 0, 0, 0.87)',
          }
        },
        primary: {
          main: "rgb(10, 25, 41)",
        },
      }),
      ...(mode === 'light' && {
        background: {
          primary: '#174c86',
          // primary_light: 'rgb(23 76 134/.1)',
          primary_light: '#e3e8ee',
          selected: '',
          sorted: 'rgba(255, 255, 0, .1)',
        },
        text: {
          default: 'rgba(0, 0, 0, 0.6)',
          link: '#174c86',
          // description: '#666',
          description: '#545454',
          // very_light_desc: '#737391',
          very_light_desc: '#595959',
          // positive: '#0e9e19',
          positive: '#138615',
          // negative: '#EB0000',
          negative: '#D92B4C',
          regular: 'rgb(255, 255, 255)',
          color: {
            black: 'rgb(255, 255, 255)',
            white: 'rgba(0, 0, 0, 0.87)',
          }
        },
        primary: {
          main: '#161b57', /* 'hsl(235deg 59.62% 21.27%)' #020B4A*/
          light: "#42a5f5",
          dark: "#2a2d64",
          contrastText: "#fff",
        },
        secondary: {
          main: "#19857b",
          light: "#ba68c8",
          dark: "#7b1fa2",
          contrastText: "#fff",
        },
        error: {
          main: red.A400,
          light: "#ef5350",
          dark: "#c62828",
          contrastText: "#fff",
        },
        warning: {
          main: '#ed6c01',
          light: "#ff9800",
          dark: "#e65100",
          contrastText: "#fff",
        },
        info: {
          main: '#0288d1',
          light: "#03a9f4",
          dark: "#01579b",
          contrastText: "#fff",
        },
        success: {
          main: '#2e7d32',
          light: "#4caf50",
          dark: "#1b5e20",
          contrastText: "#fff",
        },
        grey: {
          50: '#fafafa',
          100: "#f5f5f5",
          200: "#eeeeee",
          300: "#e0e0e0",
          400: "#bdbdbd",
          500: "#9e9e9e",
          600: "#757575",
          700: "#616161",
          800: "#424242",
          900: "#212121",
          A100: "#f5f5f5",
          A200: "#eeeeee",
          A400: "#bdbdbd",
          A700: "#616161",
        },
        //   background: {
        //     default: '#1d2025',
        //     paper: '#323f4b',
        //   },
      }),
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
            subtitle1: 'p',
            subtitle2: 'p',
            body1: 'span',
            body2: 'span',
            caption: 'span'
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  }
}
