import App from "next/app";
import Head from "next/head";
import { Snackbar, CssBaseline, Button } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { getTheme, GrufityTheme } from "../src/utils/theme";
import GrufityProgress from "../src/components/_common/GrufityProgress";
import useAuth, { AuthProvider } from "../src/hook/auth";
import AuthStateChanged from "../src/AuthStateChanged";
import Layout from "../src/components/Layout";
import ErrorBoundary from "../src/components/ErrorBoundary";
import { initFirebase } from "../src/config/firebase.config";
// import { OnlineStatusProvider } from "../src/components/_common/useOnlineStatus";
import '../src/static/scrollbar.css';
import '../src/utils/CookieConsent/dist/dist.css';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Script from "next/script";
// import tagmanager from "../src/analytics/tagmanager";
import { Analytics } from '@vercel/analytics/react';
import CookieConsent from "../src/utils/CookieConsent";
import { useRouter, withRouter } from 'next/router'
import * as gtag from "../lib/google/gtag"
import _sendAnalytics from "../lib/analytics/_sendAnalytics";
import { getFingerprintFromCookie } from "../src/components/_common/fingerprint";

// import IdleTimer from "../src/components/_common/IdleTimer";

// import wrapper from "../src/store";
// import globalStore from "../src/store/globalStore";
// import { getMetaTags } from "../lib/metaTags/_getMetaTags"
// import theme from "../src/utils/theme";
// import '../src/static/d3.css';
// import '../src/static/component-widget.css';
// import '../src/static/firebase-auth-buttons.css'
// import '../src/static/component-stock-peers.css'
// import '../src/static/page-pricing.css';
// import '../src/static/stripecard.css';

// import "../styles/globals.css";
// import "../src/config/firebase.config";
// import initFirebase from "../firebase";
// import { AuthAction, withAuthUser } from "next-firebase-auth";
// import "../src/config/firebase.config";
// import { initAuth, initFirebase } from "../firebase";
// import initAuth from '../firebase'
// import initAuth from "../src/utils/initAuth";
// import "../src/config/firebase.config";
// import { getMetaTags } from "../lib/meta/_getMetaTags";
// import { getChartTheme } from "../lib/charts/_getThemes";

// // checks if one day has passed.
// const hasOneDayPassed = () => {
//   let date = new Date().toLocaleDateString();
//   // if there's a date in localstorage and it's equal to the above:
//   // inferring a day has yet to pass since both dates are equal.
//   if (localStorage.yourapp_date == date)
//     return false;

//   // this portion of logic occurs when a day has passed
//   localStorage.yourapp_date = date;
//   return true;
// }

class _App extends App {
  constructor(props) {
    super(props);
    // this.handleUserAnalytics = this.handleUserAnalytics.bind(this);
    this.abortController = new AbortController();
    this.state = {
      themeMode: 'light',
      pageLoading: true,
      minutes: 9,
      seconds: '',
      history: [],
      open: true,
      vertical: 'bottom',
      horizontal: 'left',
      hasPremiumAccess: true,
      cookie_consent: {},
    };
  }

  static async getInitialProps({ Component, ctx }) {
    return {
      pageProps: {
        ...(Component.getInitialProps
          ? await Component.getInitialProps(ctx)
          : {}),
        // pathname: ctx.pathname,
        pathname: ctx.asPath,
        // chart_theme: await getChartTheme({ type: "Default" })
      },
    };
  }

  componentWillMount = () => {
    // initAuth()
    // initFirebase()
    initFirebase()
    // this.runOncePerDay()
    if (typeof window !== 'undefined') {
      var hours = 24; // to clear the localStorage after 24 hour
      var now = new Date().getTime();
      var setupTime = localStorage.getItem('setupTime');
      if (setupTime == null) {
        localStorage.setItem('setupTime', now)
      } else {
        if (now - setupTime > hours * 60 * 60 * 1000) {
          localStorage.clear()
          localStorage.setItem('setupTime', now);
        }
      }
    }

    if (!Array.prototype.includes) {
      Object.defineProperty(Array.prototype, 'includes', {
        enumerable: false,
        value(obj) {
          const newArr = this.filter(el => el === obj); // eslint-disable-line eqeqeq
          return newArr.length > 0;
        },
      });
    }

    if (!String.prototype.includes) {
      String.prototype.includes = function includesPolyfill(...args) {
        return String.prototype.indexOf.apply(this, args) !== -1;
      };
    }

    if (!String.prototype.toCapitalize) {
      String.prototype.toCapitalize = function cap() {
        if (!this) return this;

        let strArr = this.split(' ');
        strArr = strArr.map(d => d.length > 0 ? d[0].toUpperCase() + d.substr(1) : d);

        return strArr.join(' ');
      }
    }

    // Warn if overriding existing method
    if (!Array.prototype.equals) {
      // console.warn(
      //   "Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.",
      // );
      // attach the .equals method to Array's prototype to call it on any array
      Array.prototype.equals = function eq(array) {
        // if the other array is a falsy value, return
        if (!array) return false;

        // compare lengths - can save a lot of time
        if (this.length !== array.length) return false;

        for (let i = 0, l = this.length; i < l; i += 1) {
          // Check if we have nested arrays
          if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i])) return false;
          } else if (this[i] !== array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
          }
        }
        return true;
      };

      // Hide method from for-in loops
      Object.defineProperty(Array.prototype, 'equals', { enumerable: false });
    }

    if (!Array.prototype.max) {
      Array.prototype.max = function m(prop) {
        return (
          this.reduce((item, d) => {
            if (prop)
              item = item[prop] && d[prop] <= item[prop] ? item : d;
            else
              item = item && d <= item ? item : d;

            return item;
          }, prop ? {} : undefined)
        );
      }
    }

    if (!Array.prototype.min) {
      Array.prototype.min = function m(prop) {
        return (
          this.reduce((item, d) => {

            if (prop)
              item = item[prop] && d[prop] >= item[prop] ? item : d;
            else
              item = item && d >= item ? item : d;

            return item;
          }, prop ? {} : undefined)
        );
      }
    }

    // if (!Array.prototype.mid) {
    //   Array.prototype.mid = function md(call) {
    //     const arr = [].concat(this);
    //     const sortArr = arr.sort((a, b) => call ? call(a) - call(b) : a - b);
    //     const midIndex = Math.round(sortArr.length / 2);
    //     return sortArr[midIndex - 1];
    //   }
    // }
    if (!Array.prototype.mid) {
      Array.prototype.mid = function md(call) {
        const arrWithoutZeros = this.filter(value => value !== 0);
        if (arrWithoutZeros.length === 0) {
          return undefined;
        }
        const sortedArr = arrWithoutZeros.sort((a, b) => (call ? call(a) - call(b) : a - b));
        const midIndex = Math.round(sortedArr.length / 2);
        return sortedArr[midIndex - 1];
      }
    }
  }

  componentDidMount() {
    const { asPath } = this.props.router;

    // handle Analytics according to cookie consent
    this.handleAnalytics();

    // lets add initial route to `history`
    this.setState(prevState => ({ history: [...prevState.history, asPath] }));

    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  componentDidUpdate(prevProps) {
    const { history } = this.state;
    const { asPath } = this.props.router;

    // if (this.props.pageProps.pathname != prevProps.pageProps.pathname) {
    //   tagmanager.init();
    // }

    // if current route (`asPath`) does not equal
    // the latest item in the history,
    // it is changed so lets save it
    if (history[history.length - 1] !== asPath) {
      this.setState(prevState => ({ history: [...prevState.history, asPath] }));
    }
  }

  toggleColorMode = () => {
    const { themeMode } = this.state;
    this.setState({ themeMode: themeMode === 'light' ? 'dark' : 'light' })
  };


  // some function which should run once a day
  // runOncePerDay = () => {
  //   if (typeof window !== 'undefined') {
  //     // if (!hasOneDayPassed()) {
  //     //   console.log('One day did not pass!', true);
  //     //   if (!localStorage.getItem('views')) {
  //     //     this.setState({ hasPremiumAccess: false })
  //     //   }
  //     // } else {
  //     //   let time = 499; // This is the time allowed
  //     //   let views = localStorage.getItem('views');

  //     //   // if (views == null) {
  //     //   if (!localStorage.getItem('views')) {
  //     //     let new_countdown = new Date().getTime() + (time + 2) * 1000;
  //     //     time = new_countdown;
  //     //     localStorage.setItem('views', new_countdown);
  //     //   } else {
  //     //     time = views;
  //     //   }
  //     //   let x = setInterval(() => {
  //     //     let now = new Date().getTime();
  //     //     let distance = time - now;
  //     //     let counter = Math.floor((distance % (1000 * time)) / 1000);
  //     //     if (counter <= 0) {
  //     //       clearInterval(x);
  //     //       this.setState({ hasPremiumAccess: false })
  //     //       // this.setState(prevState => ({
  //     //       //   ...prevState, hasPremiumAccess: false
  //     //       // }))
  //     //       localStorage.removeItem('views');
  //     //     }
  //     //   }, 1000);
  //     //   console.log('Good morning! One day passed ');
  //     // };
  //     if (!hasOneDayPassed()) {
  //       console.log('One day did not pass!', true);
  //     } else {
  //       const timer = new IdleTimer({
  //         timeout: 10, //expire after 10 seconds
  //         onTimeout: () => {
  //           this.setState({ isTimeout: true });
  //         },
  //         onExpired: () => {
  //           // do something if expired on load

  //           this.setState({ isTimeout: true });
  //         }
  //       });

  //       return () => {
  //         timer.cleanUp();
  //       }
  //     }
  //   }
  // }

  handleLoadingState = (status) => {
    this.setState({ pageLoading: false });
  };

  handleAnalytics = () => {
    const { router } = this.props;
    // const allow_analysis = this.state.cookie_consent?.categories?.length > 1 ? true : false;

    const handleRouteChange = (url) => {
      // gtag.pageview(url, this.state.cookie_consent?.categories?.length > 1 ? true : false);
      let consent = true;
      gtag.pageview(url, consent);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }

  extractAnchorData = (anchor) => {
    return {
      eventName: anchor.dataset.eventName,
      eventCategory: anchor.dataset.eventCategory,
      eventLabel: anchor.dataset.eventLabel,
      href: anchor.href,
    };
  }

  extractTableData = (row) => {
    return {
      eventName: 'TableRowClick',
      eventCategory: row.dataset.eventCategory,
      eventLabel: row.dataset.eventLabel,
      rowData: {
        id: row.dataset.rowId,
        // Extract other data properties based on your structure
      },
    };
  }

  extractButtonData = (button) => {
    return {
      eventName: button.dataset.eventName,
      eventCategory: button.dataset.eventCategory,
      eventLabel: button.dataset.eventLabel,
    };
  }

  // handleUserAnalytics = async (event, props) => {
  //   this.abortController.abort(); // Abort any previous requests
  //   this.abortController = new AbortController(); // Create a new controller for this click
  //   const signal = this.abortController.signal;
  //   // // const { eventName, eventCategory, eventLabel } = event.target.dataset;
  //   // const { tagName, dataset } = event?.target;
  //   // const clickedRow = event?.currentTarget;
  //   // console.log('clickedRow?.dataset', clickedRow?.dataset,dataset)
  //   // if (tagName === 'BUTTON' || tagName === 'A' || tagName === 'INPUT') {
  //   //   const { eventName, eventCategory, eventLabel } = dataset ?? clickedRow?.dataset;

  //   const { target } = event || {};
  //   const { eventName, eventCategory, eventLabel } = target?.dataset || {};
  //   const { href } = target || {};

  //   const { asPath } = this.props.router;

  //   let location;
  //   try {
  //     const response = await fetch('/api/ip');
  //     const data = await response.json();
  //     location = data.userIP ?? ""
  //   } catch (error) {
  //     console.error('Error fetching user IP:', error);
  //   }

  //   let cookie;
  //   try {
  //     cookie = getFingerprintFromCookie() ?? "";
  //   } catch (cookieError) {
  //     console.error("Error getting fingerprint from cookie:", cookieError);
  //   }
  //   // console.log(target.tagName)
  //   // let data = {};
  //   // if (props) {
  //   //   data = props ?? ""
  //   // } else {
  //   //   switch (target.tagName) {
  //   //     case 'A':
  //   //       data = this.extractAnchorData(target);
  //   //       break;
  //   //     case 'TR':
  //   //       data = this.extractTableData(target);
  //   //       break;
  //   //     case 'TD':
  //   //       data = this.extractTableData(target);
  //   //       break;
  //   //     case 'BUTTON':
  //   //       data = this.extractButtonData(target);
  //   //       break;
  //   //     case 'P':
  //   //       data = this.extractButtonData(target);
  //   //       break;
  //   //     default:
  //   //       console.warn('Unexpected element type for click event:', target.tagName);
  //   //       return;
  //   //   }
  //   // }

  //   if (!this.state.pageLoading) {
  //     // _sendAnalytics(cookie, asPath, null, null, null, null, null, null, `_geo=${location}; _uEvents={${eventName}, ${eventCategory}, ${eventLabel}, ${href}, ${props}}`, "")
  //     _sendAnalytics(cookie, asPath, null, null, null, null, null, null, `_geo=${location}; _uEvents={${eventName ? `${eventName}, ` : ''}${eventCategory ? `${eventCategory}, ` : ''}${eventLabel ? `${eventLabel}, ` : ''}${href ? `${href}, ` : ''}${props ? `${props}` : ''}}`, "")
  //       //   this.abortController.signal
  //       // )
  //       .catch((error) => {
  //         // Handle any errors, potentially checking for AbortError
  //         console.warn("Unexpected error at _sendAnalytics at app.js")
  //       });
  //     // console.log(`${eventName ? `${eventName}, ` : ''}${eventCategory ? `${eventCategory}, ` : ''}${eventLabel ? `${eventLabel}, ` : ''}${href ? `${href}, ` : ''}${props ? `${props}` : ''}`)
  //   } else {
  //     // Handle click event for non-logged-in users (e.g., prompt for login)
  //   }
  // }

  render() {
    const { Component, pageProps } = this.props;
    const { asPath } = this.props.router;
    const { themeMode, vertical, horizontal, open, pageLoading } = this.state;
    // const theme = createTheme(getTheme(globalStore.get('themeMode')));
    // const theme = createTheme(getTheme(themeMode));
    const theme = createTheme(getTheme(themeMode));
    const parse = require('html-react-parser');

    // Check if the current page needs to show the ad script
    const shouldShowAd = asPath?.includes("blogs")

    return (
      <>
        <GrufityTheme>
          <Head>
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            {/* <Script
              strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_MEASUREMENT_ID}`}
            />
            <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.GA_MEASUREMENT_ID}');
              `,
              }}
            /> */}
            {/* <link rel="shortcut icon" href={`/assets/svg/favicon.png`} /> */}
            <meta charSet="utf-8" />
            <meta name="msvalidate.01" content="909F6353FF854D82141A5DDC12212820" />
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
            />
            <meta name="theme-color" content={theme.palette.primary.main} />
            {/* {pageProps?.meta_tags && <meta dangerouslySetInnerHTML={{ __html: pageProps?.meta_tags, }} />} */}
            {pageProps?.meta_tags && parse(pageProps?.meta_tags)}
            {!pageProps?.meta_tags && <title>Grufity</title>}
          </Head>
          <CssBaseline />
          <AuthProvider>
            {/* <OnlineStatusProvider
            pageLoading={this?.state?.pageLoading}
          > */}
            <Layout
              toggleColorMode={this.toggleColorMode}
              themeMode={themeMode}
              pageLoading={pageLoading}
            // handleUserAnalytics={this.handleUserAnalytics}
            >
              <ErrorBoundary
                history={this.state.history}
                FallbackComponent={
                  <GrufityProgress
                    // color={themeMode === 'dark' ? '#E1E1E1' : '#ffffff'}
                    color='#ffffff'
                    startPosition={0.3}
                    stopDelayMs={200}
                    height={3}
                    showOnShallow={true}
                    options={{
                      easing: "ease",
                      speed: 500,
                      trickleSpeed: 200,
                      // showSpinner: false,
                    }}
                  />}
              >
                <AuthStateChanged setpageLoading={this.handleLoadingState} pageLoading={pageLoading}>
                  {/* <Snackbar
                    sx={{ '& .MuiSnackbarContent-root': { minWidth: '4rem' } }}
                    anchorOrigin={{ vertical, horizontal }}
                    open={true}
                    message={this?.state?.isTimeout ? "Timeout" : "Active"}
                    key={vertical + horizontal}
                  /> */}
                  {/* <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.CAPTCHA_KEY}
                    scriptProps={{
                      async: false,
                      defer: false,
                      appendTo: "head",
                      nonce: undefined,
                    }}
                  > */}
                  <Component
                    {...pageProps}
                    themeMode={themeMode}
                    pageLoading={this?.state?.pageLoading}
                    minutes={this?.state?.minutes}
                    // hasPremiumAccess={this?.state?.hasPremiumAccess}
                    history={this?.state?.history}
                  // handleUserAnalytics={this.handleUserAnalytics}
                  />
                  <Analytics
                    beforeSend={(event) => {
                      // Ignore all events that have a `dev.grufity` inside the URL
                      if (event.url.includes('dev')) {
                        return null;
                      }
                      return event;
                    }}
                  />
                  <CookieConsent setConsent={(consent) => this.setState({ cookie_consent: consent })} />
                  {/* </GoogleReCaptchaProvider> */}
                </AuthStateChanged>
              </ErrorBoundary>
              <GrufityProgress
                // color={themeMode === 'dark' ? '#E1E1E1' : '#ffffff'}
                color='#ffffff'
                startPosition={0.3}
                stopDelayMs={200}
                height={3}
                showOnShallow={true}
                options={{
                  easing: "ease",
                  speed: 500,
                  trickleSpeed: 200,
                  showSpinner: true,
                }}
              />
            </Layout>
            {/* </OnlineStatusProvider> */}
          </AuthProvider>
        </GrufityTheme>
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=G-N5ZXKR5D3T`}
        />
        {shouldShowAd && (
          <Script
            strategy="afterInteractive"
            crossorigin="anonymous"
            src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9822821274784800`}
          />)}
        <Script
          id="google-tag"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-N5ZXKR5D3T');
              `,
          }}
        />
      </>
    );
  }
}

// export default wrapper.withRedux(_App);
export default withRouter(_App);
