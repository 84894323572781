/* eslint-disable import/first */
// import { createCookie } from '../api/helpers';
import { dataConstants } from '../constants';
// import uniq from 'lodash.uniq';
import uniqBy from 'lodash.uniqby';
// import groupBy from 'lodash.groupby';

// import { convertUrlParamStr, escapeQuotes } from '../utils';
import {
  // convertDate,
  subMonthsFn, subYearsFn
} from './time';
// import FormulaUtil from './formula';

export const modalType = ['gruffiti', 'createChart', 'wall', 'pinned'];

// let structedElems = [];

// const { STOCK_TABS_CODES, NAVS, STOCK_TABS } = dataConstants;

export const metaData = {
  GRUFITY: {
    title: 'Grufity',
    tags: 'grufity',
    description: 'Warehouse of financial charts and data. Quick way to do stock analysis, discover macro-economic data, chart various historical data series and compare assets performances across stocks, commodities, ETFs etc',
    url: process.env.NEXT_PUBLIC_URL,
    robots: 'index,follow',
    canonicalUrl: process.env.NEXT_PUBLIC_URL,
  },
  // STOCK: {
  //   title: 'Grufity Stock',
  //   tags: 'grufity, stock',
  //   description: 'grufity stocks',
  //   url: `${process.env.NEXT_PUBLIC_URL}/stock`,
  //   robots: 'index,follow',
  //   canonicalUrl: `${process.env.NEXT_PUBLIC_URL}/stock`,
  // },
  // WALL: {
  //   title: 'Grufity Wall',
  //   tags: 'grufity, wall',
  //   description: 'grufity wall',
  //   url: `${process.env.NEXT_PUBLIC_URL}/wall`,
  //   robots: 'noindex,follow',
  //   canonicalUrl: process.env.NEXT_PUBLIC_URL,
  //   // url: process.env.NODE_ENV === 'production' ? 'https://www.grufity.com/wall' : 'https://dev.grufity.com/wall',
  // },
  // PINNED: {
  //   title: 'Grufity Pinned',
  //   tags: 'grufity, pinned, pin',
  //   description: 'grufity pinned',
  //   url: `${process.env.NEXT_PUBLIC_URL}/pinned`,
  //   robots: 'noindex,follow',
  //   canonicalUrl: process.env.NEXT_PUBLIC_URL,
  //   // url: process.env.NODE_ENV === 'production' ? 'https://www.grufity.com/pinned' : 'https://dev.grufity.com/pinned',
  // },
  // CHART: {
  //   title: 'Grufity Chart',
  //   tags: 'grufity, chart, create chart',
  //   description: 'grufity create chart',
  //   url: `${process.env.NEXT_PUBLIC_URL}/chart`,
  //   robots: 'index,follow',
  //   canonicalUrl: `${process.env.NEXT_PUBLIC_URL}/chart`,
  //   // url: process.env.NODE_ENV === 'production' ? 'https://www.grufity.com/chart' : 'https://dev.grufity.com/chart',
  // },
  // PRIVACY: {
  //   title: 'Privacy',
  //   tags: 'grufity',
  //   description: 'privacy',
  //   url: `${process.env.NEXT_PUBLIC_URL}/privacy`,
  //   robots: 'noindex,follow',
  //   canonicalUrl: `${process.env.NEXT_PUBLIC_URL}`,
  // },
  // TERMS: {
  //   title: 'Terms',
  //   tags: 'grufity',
  //   description: 'terms of use',
  //   url: `${process.env.NEXT_PUBLIC_URL}/terms`,
  //   robots: 'noindex,follow',
  //   canonicalUrl: `${process.env.NEXT_PUBLIC_URL}`,
  // }
};

// const Stock_tabs = {
//   [STOCK_TABS_CODES.SUM]: {
//     title: 'Stock',
//     description: 'Detailed #company (#ticker) Stock Summary including important Financial Metrics, Financial Ratios, Top Fund Holders, Income Statement, Balance Sheet, Cashflow, Insider Trading and more.',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName`,
//     keywords: '#company summary,#company stocks, #ticker stock, summary,#company financial details,#company financial charts,#company financial metrics,#company fund holders,#company funds,#company income statement,#company balance sheet,#company cashflow,#company insider trading, #company transactions,#company financial ratios,#company high price,#company low price,#company 52 week high,#company 52 week low,#company #ticker data',
//     articleBody: "#company stock price is #price, MarketCap is #mktCap. Detailed #company stock summary including important Financial Metrics, Financial Ratios, Top Fund Holders, Income Statement, Balance Sheet, Cashflow, Insider Trading and more on Grufity",
//   },
//   [STOCK_TABS_CODES.PEERS]: {
//     title: 'Peers',
//     description: 'Detailed #company (#ticker) Stock Summary including important Financial Metrics, Financial Ratios, Top Fund Holders, Income Statement, Balance Sheet, Cashflow, Insider Trading and more.',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName/${STOCK_TABS.PEERS}`,
//     keywords: '#company summary,#company stocks, #ticker stock, summary,#company financial details,#company financial charts,#company financial metrics,#company fund holders,#company funds,#company income statement,#company balance sheet,#company cashflow,#company insider trading, #company transactions,#company financial ratios,#company high price,#company low price,#company 52 week high,#company 52 week low,#company #ticker data',
//     articleBody: "#company stock price is #price, MarketCap is #mktCap. Detailed #company stock summary including important Financial Metrics, Financial Ratios, Top Fund Holders, Income Statement, Balance Sheet, Cashflow, Insider Trading and more on Grufity",
//   },
//   [STOCK_TABS_CODES.BS]: {
//     title: 'Balance Sheet',
//     description: '#company’s latest and complete Balance sheet. Charts for total Assets, Liabilities, Cash, Cash Equivalents and more.',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName/${STOCK_TABS.BS}`,
//     keywords: '#company balance sheet, #ticker balance sheet, #ticker assets,#company liabilities, #company cash, #company cash equivalents, #company financial statements',
//     articleBody: "#company’s Revenue for the latest quarter  #quatNYear is #quatRev. On a trailing 12 months basis, it’s revenue was #revTTM",
//   },
//   [STOCK_TABS_CODES.CF]: {
//     title: 'Cashflow',
//     description: '#company’s latest and complete Cashflow. Charts for Cash generated by Operating Expenses, Investing Activities, Financing Activities and more.',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName/${STOCK_TABS.CF}`,
//     keywords: '#company cashflow, #ticker operating expenses,#company investing,#company financing,#company financial statements',
//     articleBody: "#company’s Revenue for the latest quarter  #QuarterAndYear is #quatRev. On a trailing 12 months basis, it’s revenue was #revTTM",
//   },
//   [STOCK_TABS_CODES.IS]: {
//     title: 'Income Statement',
//     description: '#company’s latest and complete Income statement. Charts for Net Sales(TTM), Total Operating Expenses, R&D, Net income (TTM) and more.',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName/${STOCK_TABS.IS}`,
//     keywords: '#ticker operating expenses, #company Net Income, #company net sales, #company R & D, #company financial statements',
//     articleBody: "#company’s Revenue for the latest quarter  #quatNYear is #quartRev. On a trailing 12 months basis, it’s revenue was #RevTTM",
//   },
//   [STOCK_TABS_CODES.RET]: {
//     title: 'Historical Stock Returns and Drawdowns',
//     description: '#company’s Historical Stock Returns, Annualized Cumulative Returns, Year-by-Year Returns, Excess Returns over S&P, Drawdowns, Projections based on Historical Performance . Charts for Cumulative Returns, and more.',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName/${STOCK_TABS.RET}`,
//     keywords: 'historical returns, returns, stock returns, stocks, cumulative returns, year by year, excess returns, drawdowns, projections, #ticker data, #company returns, #company transactions, #company trading, #ticker investing',
//     articleBody: "#company’s five year cumulative stock return was #cumRet",
//   },
//   [STOCK_TABS_CODES.IT]: {
//     title: 'Insider Transactions',
//     description: 'Latest and historical insider transactions data and trends for #company #ticker. Recent transactions, Top 5 Insiders Holdings, Insider Trading Trends and Recent Insider Behavior.',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName/${STOCK_TABS.IT}`,
//     keywords: 'insider trading, insider, trading, transactions, #ticker transactions, #company transactions, #company trading, #ticker trading, recent transactions, holders, historical holdings, insider behavior, insider trends',
//     articleBody: 'In the month of #month #year, Insiders held #tSharesHeld shares and bought #tSharesBought shares. Net insiders sold #tSharesSold shares in March. Number of shares held by insiders are #tSharesHeld(#PerSharesHeld%). See charts for historical holdings and cumulative insider behavior.',
//   },
//   [STOCK_TABS_CODES.OWN]: {
//     title: '- Shares owned by Funds',
//     description: 'Top funds that hold #company stock. Funds that have made big bets on #company (#ticker). Latest and historical trends in fund ownership',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName/${STOCK_TABS.OWN}`,
//     keywords: '#company fund owners, #company, #ticker shareholders, conviction owners, mutual fund owners',
//     articleBody: 'Top Funds that own #company stock.  #nTopFundOwn owns #noOfSharesHeld shares of #ticker. There are #noOfHighConvFunds funds that are heavily invested in #compnay',
//   },
//   [STOCK_TABS_CODES.OTH]: {
//     title: 'Important Financial Details',
//     description: 'Additional and often missed out Important Financial details from #company’s Financial Reports',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName/${STOCK_TABS.OTH}`,
//     keywords: '#company financial statements, #company finance, #ticker details',
//     articleBody: '',
//   },
//   [STOCK_TABS_CODES.QA]: {
//     title: 'Questions and Answers',
//     description: 'Q & A Forum to discuss #company (#ticker) Stocks, Finances and Products. Ask any question and have it answered by the Grufity Community.”',
//     url: `${process.env.NEXT_PUBLIC_URL}${NAVS.STOCK}/#ticker-#companyName/${STOCK_TABS.QA}`,
//     keywords: '#company Q & A, stocks, #ticker investing, questions, answers, #company FAQ',
//     articleBody: 'Frequently Asked Questions on #company stocks, finances and products.. Answers provided by the Grufity community.',
//   },
// };

// export const getChartTags = data => {
//   let tags = [];
//   // const tags = data.tags && data.tags !== '' ? data.tags.split(' ') : [];

//   if (data.title) {
//     tags.push(data.title);
//   }

//   if (data.subTitle) {
//     tags.push(data.subTitle);
//   }

//   // if (data.description) {
//   //   tags.push(data.description.replace(/(<([^>]+)>)/gi, ''));
//   // }

//   tags.push(data.code);

//   const symbolCodes = data.seriesMeta.map(d => d.code);
//   tags = tags.concat(symbolCodes);

//   const tickers = data.tickers.map(d => d.ticker);
//   tags = tags.concat(tickers);

//   tags = tags.concat(tickers.map(d => `$${d}`));

//   const seriesDesc = data.seriesMeta.map(d => d.desc);
//   tags = tags.concat(seriesDesc);

//   //tags = data.tags && data.tags !== '' && data.tags !== '{}' ? tags.concat(data.tags.split(' ')) : tags;

//   const tagStr = tags.join(' ');

//   tags = tagStr.split(' ');

//   return uniq(tags).join(' ');
// };

// export const asciiToHexa = str => {
//   const arr1 = [];
//   for (let n = 0, l = str.length; n < l; n += 1) {
//     const hex = Number(str.charCodeAt(n)).toString(16);
//     arr1.push(hex);
//   }
//   return arr1.join('');
// };

// export const setUserLoginCookies = ({ accesstoken, uid, refreshToken }) => {
//   createCookie('actkn', accesstoken, 60 * 60);
//   createCookie('uid', uid, 60 * 60);
//   if (refreshToken) {
//     createCookie('refreshToken', refreshToken, 30 * 24 * 60 * 60);
//   }
// };

// export const updateTag = (propT = 'name', propVal = '', propType = 'content', val = '', tag = 'meta') => {
//   document.querySelector(`${tag}[${propT}='${propVal}']`).setAttribute(propType, val);
// }

// export const createTag = (propT = 'name', propVal = '', propType = 'content', val = '', tag = 'meta') => {
//   const link = document.createElement(tag);
//   link.setAttribute(propT, propVal);
//   link.setAttribute(propType, val);
//   document.head.appendChild(link);
// }

// export const setTag = (propT = 'name', propVal = '', propType = 'content', val = '', tag = 'meta') => {
//   if (!document.querySelector(`${tag}[${propT}='${propVal}']`)) {
//     createTag(propT, propVal, propType, val, tag);
//   } else {
//     updateTag(propT, propVal, propType, val, tag);
//   }
// }

// export const removeStructedData = () => {
//   if (structedElems.length > 0) {
//     structedElems.forEach(d => {
//       d.remove();
//     });
//     structedElems = [];
//   }
// }

// export const getStructDataStr = d => `
//   {
//     "@context": "https://schema.org",
//     ${d.type ? `"@type": "${d.type}",` : ''}
//     ${d.name ? `"name": "${d.name}",` : ''}
//     ${d.title ? `"headline": "${d.title}",` : ''}
//     ${d.imageUrl ? `"image": "${d.imageUrl}",` : ''}
//     ${d.author ? `"author": "${d.author}",` : ''}
//     ${d.genre ? `"genre": "${d.genre}",` : ''}
//     ${d.keywords ? `"keywords": "${d.keywords.replace(/(<([^>]+)>)/gi, '')}",` : ''}
//     ${d.url ? `"url": "${d.url}",` : ''}
//     ${d.description ? `"description": "${d.description.replace(/(<([^>]+)>)/gi, '')}",` : ''}
//     ${d.articleBody ? `"articleBody": "${d.articleBody.replace(/(<([^>]+)>)/gi, '')}",` : ''}
//     ${d.temporalCoverage ? `"temporalCoverage": "${convertDate(d.temporalCoverage)}/..",` : ''}
//     ${d.creator ? `"creator": ${d.creator},` : ''}
//     ${d.datePublished ? `"datePublished": "${convertDate(d.datePublished)}",` : ''}
//     ${d.dateModified ? `"dateModified": "${convertDate(d.dateModified)}",` : ''}
//     ${d.mainEntityOfPage ? `"mainEntityOfPage": {"@type": "WebPage", "@id": "${d.mainEntityOfPage}"},` : ''}
//     ${d.mainEntity ? `"mainEntity": {
//       "@type": "${d.mainEntity.type || 'Question'}",
//       "name": "${d.mainEntity.name}",
//       ${d.mainEntity.text ? `text": "${d.mainEntity.text}",` : ''}
//       "answerCount": ${d.mainEntity.answers.length},
//       ${d.mainEntity.votes ? `"upvoteCount" : "${d.mainEntity.votes}",` : ''}
//       "dateCreated": "${d.mainEntity.created_at || convertDate(new Date())}",
//       "author": {
//         "@type": "Person",
//         "name": "${d.mainEntity.author.display_name}"
//       },
//       "suggestedAnswer": [
//         ${d.mainEntity.answers.map(it => {
//   return `{
//             "@type": "Answer",
//             "text": "${escapeQuotes(it.answer)}",
//             "dateCreated": "${it.created_at || convertDate(new Date())}",
//             ${it.votes ? `"upvoteCount" : "${it.votes}",` : ''}
//             "url": "${it.url}",
//             "author": {
//               "@type": "Person",
//               "name": "${it.author.display_name}"
//             }
//           }`;
// })}
//       ]
//     },` : ''}
//     "publisher": {
//       "@type": "Organization",
//       "name": "Grufity",
//       "logo": {
//         "@type": "ImageObject",
//         "url": "https://charts.grufity.com/logo/logo.png"
//       }
//     }
//   }
// `;

// export const setStructedData = (datas = []) => {
//   removeStructedData();


//   datas.forEach(d => {
//     if (d) {
//       let elem = document.createElement('script');
//       elem['type'] = 'application/ld+json';

//       const htmlStr = getStructDataStr(d);

//       elem.innerHTML = htmlStr;

//       document.head.appendChild(elem);
//       structedElems.push(elem);
//     }
//   });
// }

// export const structDataStr = (data = []) => `
// ${data.map(d => (
//   ` <script type="application/ld+json">
//         ${getStructDataStr(d)}
//       </script>
//     `
// ))}
// `;

// export const getStructDataArr = (data, pageUrl, imageUrl) => {
//   return [
//     {
//       ...data,
//       imageUrl: imageUrl,
//       url: pageUrl,
//       type: 'Article',
//       //context: "https://schema.org",
//       keywords: getChartTags(data),
//       genre: '',
//       author: data.author && data.author.length > 0 ? `@${data.author[0].screen_name}` : 'grufity',
//       articleBody: viewChartBodyText(data),
//       datePublished: data.created_at || new Date().toISOString(),
//       dateModified: data.updated_at || new Date().toISOString(),
//     },
//     // data.seriesMeta.length === 1 && data.tickers.length > 0 && !(data.sequenceData && data.sequenceData.length > 0) ? {
//     //   ...data,
//     //   type: 'Dataset',
//     //   author: undefined,
//     //   creator: `
//     //     {
//     //       "@type":"Organization",
//     //       "url": "${pageUrl}",
//     //       "name":"Grufity"
//     //     }
//     //   `,
//     //   url: pageUrl,
//     //   description: descriptionStr,
//     //   name: nameStr,
//     //   keywords: data.seriesMeta.length === 1 ? uniq([data.seriesMeta[0].code, nameStr, descriptionStr].join(' ').split(' ')).join(' ') : undefined,
//     //   temporalCoverage: getMinDate(data),
//     //   title: undefined,
//     // } : null,
//   ];
// }

// export const viewChartBodyText = data => {
//   let bodyArr = [];

//   const seriesDesc = data.seriesMeta.map(d => d.richTextDesc);
//   bodyArr = bodyArr.concat(seriesDesc);

//   const tickerDesc = data.tickers.map(d => d.richTextDesc);
//   bodyArr = bodyArr.concat(tickerDesc);

//   return (getChartTags(data) + uniq(bodyArr).join(' '));
// }

export const getMinDate = data => {
  if (data.fromDate) return data.fromDate;

  let dateArr = data?.series_data?.map(d => d.recordDate);
  dateArr = dateArr.concat(data?.stock_data?.map(d => d.recordDate));

  dateArr = dateArr.sort((a, b) => +new Date(a) - (+new Date(b)));

  return dateArr.length > 0 ? dateArr[0] : undefined;
}

export const getMaxDate = data => {
  if (data.toDate) return data.toDate;

  let dateArr = data.series_data.map(d => d.recordDate);
  dateArr = dateArr.concat(data.stock_data.map(d => d.recordDate));

  dateArr = dateArr.sort((a, b) => +new Date(b) - (+new Date(a)));

  return dateArr.length > 0 ? dateArr[0] : undefined;
}

export const replacePlaceHolders = (str, placeholders = []) => {
  let s = str;
  placeholders.forEach(d => {
    s = s.replace(new RegExp(d.key, 'g'), d.val);
  });

  return s;
}

// export const getMetaTagHtmlStr = data => `
//   <meta name="robots" content="index,follow">
//   <link href="${data.url || '#'}" rel="canonical">
//   <title>${data.title || ''}</title>
//   <meta name="description" content="${data.desc || ''}">
//   <meta name="keywords" content="${data.keywords || ''}">

//   <meta name="twitter:card" content="${data.tCard || "summary_large_image"}">
//   <meta name="twitter:creator" content="${data.creator || "Grufity"}">
//   <meta name="twitter:site" content="${data.site || "@Grufity"}">
//   <meta name="twitter:title" content="${data.title || ''}">
//   <meta name="twitter:description" content="${data.desc || ''}">
//   <meta name="twitter:image" content="${data.imgUrl || '#'}">

//   <meta property="og:title" content="${data.title || ''}" />
//   <meta property="og:image" content="${data.imgUrl || '#'}" />
//   <meta property="og:image:alt" content="${data.title || ''}">
//   <meta property="og:url" content="${data.url || '#'}" />
//   <meta property="og:description" content="${data.desc || ''}" />
//   <meta property="og:type" content="${data.oType || "article"}" />
// `;

// export const updateChartMetaTags = (data, pageType = dataConstants.NAVS.VIEW_CHART) => {
//   //const index = data.url ? data.url.lastIndexOf('.html') : '';
//   const pageUrl = `${process.env.NEXT_PUBLIC_URL}${dataConstants.NAVS.VIEW_CHART}/${data.code}${data.title ? `/${getTitleUrlStr(data.title)}` : ''}`;

//   const imageUrl = data.url;
//   //? (index >= 0 ? `${data.url.substr(0, index)}.jpeg` : data.url) : '';

//   document.title = `${data.title || ''}(chart)`;
//   setTag(undefined, 'keywords', undefined, getChartTags(data));
//   setTag(undefined, 'description', undefined, `${data.subTitle ? `${data.subTitle} ` : ''}${data.description ? `${data.description.replace(/(<([^>]+)>)/gi, '')} ` : ''}${data.title || ''
//     }`);
//   // setTag(undefined, 'abstract', undefined, `chart showing information of ${data.title}`);
//   // setTag(undefined, 'url', undefined, pageUrl);
//   // setTag(undefined, 'identifier-URL', undefined, `${process.env.NEXT_PUBLIC_URL}${pageType}/${data.code}`);
//   // setTag(undefined, 'author', undefined, data.author && data.author.length > 0 ? `@${data.author[0].screen_name}` : 'grufity');
//   setTag('property', 'og:title', undefined, data.title || '');
//   setTag('property', 'og:image', undefined, imageUrl);
//   setTag('property', 'og:url', undefined, pageUrl);
//   setTag('property', 'og:description', undefined, `${data.subTitle ? `${data.subTitle} ` : ''}${data.description ? `${data.description.replace(/(<([^>]+)>)/gi, '')} ` : ''}${data.title || ''
//     }`);
//   setTag('property', 'og:image:alt', undefined, data.title);
//   setTag('property', 'og:type', undefined, "article");
//   // setTag(undefined, 'twitter:image', undefined,  data.url ? `${data.url.substr(0, index)}.jpeg` : '');
//   setTag('rel', 'canonical', 'href', pageUrl, 'link');

//   setTag(undefined, 'twitter:card', undefined, "summary_large_image");
//   setTag(undefined, 'twitter:creator', undefined, "Grufity");
//   setTag(undefined, 'twitter:site', undefined, "@Grufity");
//   setTag(undefined, 'twitter:creator', undefined, "@Grufity");
//   setTag(undefined, 'twitter:title', undefined, data.title);
//   setTag(undefined, 'twitter:description', undefined, `${data.subTitle ? `${data.subTitle} ` : ''}${data.description ? `${data.description.replace(/(<([^>]+)>)/gi, '')} ` : ''}${data.title || ''
//     }`);
//   setTag(undefined, 'twitter:image', undefined, imageUrl);

//   // const ancestory = data.seriesMeta.length === 1 && data.tickers.length > 0 ? `${data.tickers[0].desc} - ${data.tickers[0].ticker}` : '';
//   // const seriesDesc = data.seriesMeta.length === 1 ? data.seriesMeta[0].desc : '';
//   // const tickerRichDesc = data.seriesMeta.length === 1 && data.tickers.length > 0 ? data.tickers[0].desc : '';

//   //const nameStr = data.seriesMeta.length === 1 ? `${ancestory}: ${seriesDesc}` : undefined;
//   //const descriptionStr = data.seriesMeta.length === 1 ? `${ancestory}: ${tickerRichDesc}` : undefined;

//   setStructedData(getStructDataArr(data, pageUrl, imageUrl));

//   //   setStructedData([
//   //     {
//   //       ...data,
//   //       imageUrl: imageUrl,
//   //       url: pageUrl,
//   //       type: 'Article',
//   //       //context: "https://schema.org",
//   //       keywords: getChartTags(data),
//   //       genre: '',
//   //       author: data.author && data.author.length > 0 ? `@${data.author[0].screen_name}` : 'grufity',
//   //       articleBody: viewChartBodyText(data),
//   //       datePublished: data.created_at,
//   //       dateModified: data.updated_at,
//   //     },
//   //     data.seriesMeta.length === 1 && data.tickers.length > 0 && !(data.sequenceData && data.sequenceData.length > 0) ? {
//   //       ...data,
//   //       type: 'Dataset',
//   //       author: undefined,
//   //       creator: `
//   //         {
//   //           "@type":"Organization",
//   //           "url": "${pageUrl}",
//   //           "name":"Grufity"
//   //         }
//   //       `,
//   //       url: pageUrl,
//   //       description: descriptionStr,
//   //       name: nameStr,
//   //       keywords: data.seriesMeta.length === 1 ? uniq([data.seriesMeta[0].code, nameStr, descriptionStr].join(' ').split(' ')).join(' ') : undefined,
//   //       temporalCoverage: getMinDate(data),
//   //       title: undefined,
//   //     } : null,
//   // ]);
//   //"contactPoint":{
//   //   "@type":"ContactPoint",
//   //   "contactType": "customer service",
//   //   "email":"contact@grufity.com",
//   //   "telephone":"+1-828-271-4800",
//   // }
//   // document.querySelector("meta[name='keywords']").setAttribute('content', getChartTags(data));
//   // document
//   //   .querySelector("meta[name='description']")
//   //   .setAttribute(
//   //     'content',
//   //     `${data.subTitle ? `${data.subTitle} ` : ''}${data.description ? `${data.description.replace(/(<([^>]+)>)/gi, '')} ` : ''}${
//   //       data.title
//   //     }`,
//   //   );
//   // document.querySelector("meta[name='abstract']").setAttribute('content', `chart showing information of ${data.title}`);
//   // document.querySelector("meta[name='url']").setAttribute('content', `${process.env.NEXT_PUBLIC_URL}${pageType}/${data.code}`);
//   // document.querySelector("meta[name='identifier-URL']").setAttribute('content', `${process.env.NEXT_PUBLIC_URL}${pageType}/${data.code}`);
//   // document
//   //   .querySelector("meta[name='author']")
//   //   .setAttribute('content', data.author && data.author.length > 0 ? `@${data.author[0].screen_name}` : 'grufity');
//   // document.querySelector("meta[property='og:title']").setAttribute('content', data.title);
//   // document.querySelector("meta[property='og:image']").setAttribute('content', data.url ? `${data.url.substr(0, index)}.jpeg` : '');
//   // document.querySelector("meta[property='og:url']").setAttribute('content', `${process.env.NEXT_PUBLIC_URL}${pageType}/${data.code}`);
//   // document
//   //   .querySelector("meta[property='og:description']")
//   //   .setAttribute(
//   //     'content',
//   //     `${data.subTitle ? `${data.subTitle} ` : ''}${data.description ? `${data.description.replace(/(<([^>]+)>)/gi, '')} ` : ''}${
//   //       data.title
//   //     }`,
//   //   );
//   // document.querySelector("meta[name='twitter:image']").setAttribute('content', data.url ? `${data.url.substr(0, index)}.jpeg` : '');
//   // document.querySelector("meta[name='twitter:title']").setAttribute('content', data.title);
// };

// export const updatePageMetaTags = (pageType, data = {}) => {
//   removeStructedData();
//   document.title = metaData[pageType].title;
//   setTag(undefined, 'keywords', undefined, metaData[pageType].tags);
//   setTag(undefined, 'description', undefined, metaData[pageType].description ? metaData[pageType].description.replace(/(<([^>]+)>)/gi, '') : '');
//   // setTag(undefined, 'abstract', undefined, 'grufity');
//   // setTag(undefined, 'url', undefined, metaData[pageType].url);
//   // setTag(undefined, 'identifier-URL', undefined, metaData[pageType].url);
//   // setTag(undefined, 'author', undefined, 'grufity');
//   setTag('property', 'og:title', undefined, metaData[pageType].title);
//   setTag('property', 'og:image', undefined, metaData[pageType].url);
//   setTag('property', 'og:url', undefined, metaData[pageType].url);
//   setTag('property', 'og:description', undefined, metaData[pageType].description ? metaData[pageType].description.replace(/(<([^>]+)>)/gi, '') : '');
//   //setTag(undefined, 'twitter:image', undefined,  metaData[pageType].url);
//   //setTag(undefined, 'twitter:title', undefined, metaData[pageType].title);
//   setTag(undefined, 'robots', undefined, metaData[pageType].robots);
//   setTag('rel', 'canonical', 'href', data.canonicalUrl || metaData[pageType].canonicalUrl, 'link');

//   // document.querySelector("meta[name='keywords']").setAttribute('content', metaData[pageType].tags);
//   // document
//   //   .querySelector("meta[name='description']")
//   //   .setAttribute('content', metaData[pageType].description ? metaData[pageType].description.replace(/(<([^>]+)>)/gi, '') : '');
//   // document.querySelector("meta[name='abstract']").setAttribute('content', 'grufity');
//   // document.querySelector("meta[name='url']").setAttribute('content', metaData[pageType].url);
//   // document.querySelector("meta[name='identifier-URL']").setAttribute('content', metaData[pageType].url);
//   // document.querySelector("meta[name='author']").setAttribute('content', 'grufity');
//   // document.querySelector("meta[property='og:title']").setAttribute('content', metaData[pageType].title);
//   // document.querySelector("meta[property='og:image']").setAttribute('content', metaData[pageType].url);
//   // document.querySelector("meta[property='og:url']").setAttribute('content', metaData[pageType].url);
//   // document
//   //   .querySelector("meta[property='og:description']")
//   //   .setAttribute('content', metaData[pageType].description ? metaData[pageType].description.replace(/(<([^>]+)>)/gi, '') : '');
//   // document.querySelector("meta[name='twitter:image']").setAttribute('content', metaData[pageType].url);
//   // document.querySelector("meta[name='twitter:title']").setAttribute('content', metaData[pageType].title);

//   // document.querySelector("meta[name='robots']").setAttribute('content', metaData[pageType].robots);
// };

// export const updateCommodityPageMetaTags = data => {
//   removeStructedData();
//   const url = `${process.env.NEXT_PUBLIC_URL}${dataConstants.NAVS.RESEARCH}/${data.term}`;
//   document.title = data.title || '';
//   setTag(undefined, 'keywords', undefined, data.keywords || '');
//   setTag(undefined, 'description', undefined, data.description || '');
//   // setTag(undefined, 'abstract', undefined, 'grufity');
//   // setTag(undefined, 'url', undefined, url);
//   // setTag(undefined, 'identifier-URL', undefined, url);
//   // setTag(undefined, 'author', undefined, 'grufity');
//   setTag('property', 'og:title', undefined, data.title || '');
//   setTag('property', 'og:image', undefined, url);
//   setTag('property', 'og:url', undefined, url);
//   setTag('property', 'og:description', undefined, data.description || '');
//   // setTag(undefined, 'twitter:image', undefined,  url);
//   setTag(undefined, 'twitter:title', undefined, data.title || '');
//   setTag(undefined, 'robots', undefined, 'index,follow');
//   setTag('rel', 'canonical', 'href', url, 'link');
//   // document.querySelector("meta[name='keywords']").setAttribute('content', data.keywords || '');
//   // document.querySelector("meta[name='description']").setAttribute('content', data.description || '');
//   // document.querySelector("meta[property='og:title']").setAttribute('content', data.title || '');
//   // document.querySelector("meta[property='og:description']").setAttribute('content', data.description || '');
//   // document.querySelector("meta[name='twitter:title']").setAttribute('content', data.title || '');
//   // document.querySelector("meta[name='url']").setAttribute('content', url);
//   // document.querySelector("meta[name='identifier-URL']").setAttribute('content', url);
//   // document.querySelector("meta[property='og:url']").setAttribute('content', url);

//   // document.querySelector("meta[name='robots']").setAttribute('content', 'index,follow');
// };

// export const updateStockPageMetaTags = (data, tabCode) => {
//   //const metaData = data[tabCode] || {};
//   const staticData = Stock_tabs[tabCode];

//   if (staticData) {
//     const { ticker, description } = data.info || {};
//     const company = description ? description.split(' ')[0] : '';
//     const title = `${description} (${ticker}) ${staticData.title}`;

//     const placeholders = [
//       { key: '#company', val: company },
//       { key: '#ticker', val: ticker },
//       { key: '#companyName', val: convertUrlParamStr(company) }
//     ];

//     const keywords = replacePlaceHolders(staticData.keywords, placeholders);
//     const desc = replacePlaceHolders(staticData.description, placeholders);
//     const url = replacePlaceHolders(staticData.url, placeholders);
//     //const articleBody = replacePlaceHolders(staticData.articleBody, placeholders);

//     document.title = title;
//     setTag(undefined, 'keywords', undefined, keywords);
//     setTag(undefined, 'description', undefined, desc);
//     setTag('property', 'og:title', undefined, title);
//     setTag('property', 'og:image', undefined, url);
//     setTag('property', 'og:image:alt', undefined, title);
//     setTag('property', 'og:url', undefined, url);
//     setTag('property', 'og:description', undefined, desc);
//     setTag('property', 'og:type', undefined, 'article');
//     setTag('rel', 'canonical', 'href', url, 'link');
//     setTag(undefined, 'twitter:title', undefined, title);
//     setTag(undefined, 'twitter:card', undefined, 'summary_large_image');
//     setTag(undefined, 'twitter:site', undefined, '@Grufity');
//     setTag(undefined, 'twitter:creator', undefined, 'Grufity');
//     setTag(undefined, 'twitter:description', undefined, desc);
//     setTag(undefined, 'twitter:image', undefined, url);
//     setTag(undefined, 'robots', undefined, 'index,follow');

//     // setStructedData([
//     //   {
//     //     title,
//     //     description: desc,
//     //     imageUrl: url,
//     //     url,
//     //     type: 'Article',
//     //     keywords,
//     //     genre: '',
//     //     author: 'grufity',
//     //     articleBody,
//     //     datePublished: new Date().toDateString(),
//     //     dateModified: new Date().toDateString(),
//     //     mainEntityOfPage: url,
//     //   }
//     // ]);
//   }

// }

// export const updateStockQuestionTag = data => {
//   const url = `${process.env.NEXT_PUBLIC_URL}${dataConstants.NAVS.STOCK}/${dataConstants.STOCK_TABS.QA}/${data.id || '#qId'}/${convertUrlParamStr(data.question)}`;
//   document.title = data.question;

//   setTag(undefined, 'keywords', undefined, data.question);
//   setTag(undefined, 'description', undefined, data.question);
//   setTag('property', 'og:title', undefined, data.question);
//   setTag('property', 'og:image', undefined, url);
//   setTag('property', 'og:image:alt', undefined, data.question);
//   setTag('property', 'og:url', undefined, url);
//   setTag('property', 'og:description', undefined,  '');
//   setTag('property', 'og:type', undefined,  'article');
//   setTag('rel', 'canonical', 'href', url, 'link');
//   setTag(undefined, 'twitter:title', undefined, data.question);
//   setTag(undefined, 'twitter:card', undefined, 'summary_large_image');
//   setTag(undefined, 'twitter:site', undefined, '@Grufity');
//   setTag(undefined, 'twitter:creator', undefined, 'Grufity');
//   setTag(undefined, 'twitter:description', undefined, '');
//   setTag(undefined, 'twitter:image', undefined, url);
//   setTag(undefined, 'robots', undefined, 'index,follow');

//   if(data.answers && data.answers.length > 0) {
//     setStructedData([{

//     }]);
//   }
// }

// export const getStockQuestionTagStr = (data, info) => {
//   const url = `${process.env.NEXT_PUBLIC_URL}${dataConstants.NAVS.STOCK}/${info?.ticker}-${info?.companyName}/${STOCK_TABS.QA}/${data.id || '#qId'}/${getTitleUrlStr(data.question)}`;
//   let metaStr = getMetaTagHtmlStr({
//     title: data.question,
//     url,
//     description: data.question,
//   });

//   if (data.answers && data.answers.length > 0) {
//     metaStr += structDataStr([{
//       type: 'QAPage',
//       mainEntity: {
//         ...data,
//         name: data.question,
//         text: data.question,
//         created_at: data.date ? convertDate(data.date) : undefined,
//         answers: data.answers.map(d => ({
//           ...d,
//           url: `${url}#${d.id || '$id'}`,
//         }))
//       }
//     }]);
//   }

//   return metaStr;
// }

export const getRandomStr = len => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < len; i += 1) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const isExponent = num => {
  const n = num.toString();
  const matches = n.match(/[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/g);
  return matches && matches.length > 0;
};

export const roundNum = (num, round = 2) => {
  const n = num;

  if (!n || n && isNaN(n)) return n;

  if (isExponent(n)) {
    const nStr = n.toString().split('e');
    const roundNum = parseFloat(nStr[0]).toFixed(round);
    return parseFloat(`${roundNum}e${nStr[1]}`);
  }
  return (Math.round(n * Math.pow(10, round)) / Math.pow(10, round)).toFixed(round);
};

export const noChartData = data => {
  const { series_data = [], stock_data = [], sequenceData = [] } = data || {};
  return series_data.length === 0 && stock_data.length === 0 && sequenceData.length === 0;
};

const setPlus = y => {
  let sArr = y.split(' ');
  sArr = sArr.map(d => d.length > 0 && d[0].match(/^[\w\d._]+$/i) ? `+${d}` : d);
  return sArr.join(' ');
}

const setStar = y => {
  let sArr = y.split(' ');
  sArr = sArr.map(d => d.length > 0 && d[d.length - 1].match(/^[\w\d._]+$/i) ? `${d}*` : d);
  return sArr.join(' ');
}

export const constructKeywordSearchStr = (str = "") => {
  if (str === '') return str;

  let s = str.trim().replace(/["]/g, "'");
  s = s.replace(/[-]\s+/g, '-').replace(/[+]\s+/g, '+');
  let cottedArr = s.match(/['][^|]+?[']/g) || [];
  s = s.replace(/['][^|]+?[']/g, '');

  s = setPlus(s)
  s = setStar(s);
  s += cottedArr.join(' ');
  s = s.replace(/'/g, "+'").replace(/[-][+]/g, '-').replace(/[+][+]/g, '+');
  return s;
}

export const getNoOfDataPoints = (dataPoints = 1000, coverPercentage = 100) => {
  //const device = window.navigator.userAgent;
  const screenR = window.innerWidth / 1000;

  return Math.round(screenR > 1 ? (coverPercentage / 100) * screenR * dataPoints : screenR * dataPoints);
  // if(device.match(/iPhone|BlackBerry|Android|BlackBerry|IEMobile/i)) {
  //   return (screenW/dataPoints) * dataPoints;
  // }
  // else if (device.match(/iPod|iPad/i)) {
  //   return 0.7 * dataPoints;
  // }
  // else {
  //   return dataPoints;
  // }

  // switch(device) {
  //   case device.match(/iPhone/i):
  //   case device.match(/BlackBerry/i):
  //   case device.match(/Android/i):
  //   case device.match(/BlackBerry/i):
  //   case device.match(/IEMobile/i):
  //     return 0.4 * dataPoints;
  //   case device.match(/iPod|iPad/i):
  //     return 0.7 * dataPoints;
  //   default:
  //     return dataPoints;
  // }
}

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}${month}${day}`;
}

export const generateDateOptions = () => {
  try {
    const today = new Date();
    const formattedDate = formatDate(today);

    const last3Days = new Date();
    last3Days.setDate(last3Days.getDate() - 3);
    const formattedLast3Days = formatDate(last3Days);

    const last5Days = new Date();
    last5Days.setDate(last5Days.getDate() - 5);
    const formattedLast5Days = formatDate(last5Days);

    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    const formattedLastWeek = formatDate(lastWeek);

    const last2Weeks = new Date();
    last2Weeks.setDate(last2Weeks.getDate() - 14);
    const formattedLast2Weeks = formatDate(last2Weeks);

    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const formattedLastMonth = formatDate(lastMonth);

    const last2Months = new Date();
    last2Months.setMonth(last2Months.getMonth() - 2);
    const formattedLast2Months = formatDate(last2Months);

    const last3Months = new Date();
    last3Months.setMonth(last3Months.getMonth() - 3);
    const formattedLast3Months = formatDate(last3Months);

    const last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);
    const formattedLast6Months = formatDate(last6Months);

    const last12Months = new Date();
    last12Months.setMonth(last12Months.getMonth() - 12);
    const formattedLast12Months = formatDate(last12Months);

    const last24Months = new Date();
    last24Months.setMonth(last24Months.getMonth() - 24);
    const formattedLast24Months = formatDate(last24Months);

    const last36Months = new Date();
    last36Months.setMonth(last36Months.getMonth() - 36);
    const formattedLast36Months = formatDate(last36Months);

    const dates = [
      { label: 'Any', value: '0' },
      { label: 'Last 3 days', value: formattedLast3Days },
      { label: 'Last 5 days', value: formattedLast5Days },
      { label: 'Last week', value: formattedLastWeek },
      { label: 'Last 2 weeks', value: formattedLast2Weeks },
      { label: 'Last month', value: formattedLastMonth },
      { label: 'Last 2 months', value: formattedLast2Months },
      { label: 'Last 3 months', value: formattedLast3Months },
      { label: 'Last 6 months', value: formattedLast6Months },
      { label: 'Last 12 months', value: formattedLast12Months },
      { label: 'Last 24 months', value: formattedLast24Months },
      { label: 'Last 36 months', value: formattedLast36Months },
    ];

    return dates;
  } catch (error) {
    console.error('Error generating date options:', error);
    return [];
  }
}

export const getStartDate = (selectedView, maxDate) => {
  switch (selectedView) {
    case '1M':
      return subMonthsFn(maxDate, 1)?.toISOString();
    case '3M':
      return subMonthsFn(maxDate, 3)?.toISOString();
    case '6M':
      return subMonthsFn(maxDate, 6)?.toISOString();
    case '1Y':
      return subYearsFn(maxDate, 1)?.toISOString();
    case '2Y':
      return subYearsFn(maxDate, 2)?.toISOString();
    case '3Y':
      return subYearsFn(maxDate, 3)?.toISOString();
    case '5Y':
      return subYearsFn(maxDate, 5)?.toISOString();
    case '7Y':
      return subYearsFn(maxDate, 7)?.toISOString();
    case '10Y':
      return subYearsFn(maxDate, 10)?.toISOString();
    default:
      return maxDate;
  }
}

export const getPeriodGap = (selectedView, data) => {
  let d = data;
  d = d.length > 0 ? d.filter(it => it.code === d[0].code) : d;
  d = d.sort((a, b) => +new Date(a.recordDate) - +new Date(b.recordDate));
  d = uniqBy(d, 'rn');
  //const avglen = Math.min(d.length, 50);

  return d.length > 0 ? Math.abs(d[1].rn - d[0].rn) : 0;
}

export const getPlotDataPoints = (chart, selectedView = 'Max', dataPoints = 1000, coverPercentage = 100) => {
  if (chart.allData && selectedView === 'Max') return chart[chart.dataProp || 'stock_data'];

  let data = chart[chart.dataProp || 'stock_data'];

  if (noChartData(chart)) return data;
  //const requiredDataPoints = getNoOfDataPoints(dataPoints, coverPercentage);
  const minDate = getMinDate(chart);
  const maxDate = getMaxDate(chart);

  const startDate = selectedView !== 'Max' ? +new Date(getStartDate(selectedView, maxDate)) : +new Date(minDate);
  //const before1YrDate = +new Date(getStartDate('1Y', maxDate));

  data = data.filter(d => +new Date(d.recordDate) >= startDate);

  if (data && data.length < 2) {
    return data;
  }

  const gap = getPeriodGap(selectedView, data);
  data = data.reduce((arr, item, i) => {
    if ((item.rn % gap) === 0) {
      arr.push(item);
    }

    // switch(selectedView) {
    //   case '2Y':
    //   case '5Y':
    //   case 'Max':
    //     if((item.rn % gap) === 0) {
    //       arr.push(item);
    //     }
    //     break;
    //   default:
    //   if((item.rn % gap) === 0) {
    //     arr.push(item);
    //   }
    // }

    return arr;
  }, []);

  return data;
}

export const getChartData = (chart, formula, legend) => {
  if (!chart.id) return chart;

  const { stock_data, series_data, sequenceData } = chart || {};
  let code = '';
  if (!formula) {
    code = stock_data && stock_data.length > 0 ? stock_data[0].code : undefined;

    if (!code)
      code = series_data && series_data.length > 0 ? series_data[0].code : code;

    if (!code)
      code = sequenceData && sequenceData.length > 0 ? sequenceData[0].code : code;
  };

  return formula || code ?
    {
      ...chart,
      series: chart.series && chart.series.length > 0 ? [{
        ...chart.series[0],
        yformula: formula || code,
        legend: {
          ...(chart.series[0].legend || {}),
          label: (legend || chart.series[0].legend.label),
        }
      }] : [{
        yformula: formula || code,
        type: dataConstants.CHART_SERIES_TYPE.LINE,
        seq: 1,
      }],
      ySeries: formula || code,
    } : chart;
}

// export const getChartDataProp = type => {
//   const { CHART_TYPE } = dataConstants;
//   switch (type) {
//     case CHART_TYPE.CATEGORIES:
//       return 'sequenceData';
//     default:
//       return 'series_data';
//   }
// }

// export const isChartDataLimitCorrect = (chart, limit = 5) => {
//   let data = chart[getChartDataProp(chart.chart_type)];

//   if (!chart.code) {
//     return false;
//   }

//   if (chart.chart_type !== dataConstants.CHART_TYPE.CATEGORIES && data) {
//     data = data.concat(chart.stock_data);

//     //const codes = FormulaUtil.getSymbolCodes(chart.ySeries);

//     const codeWiseData = groupBy(data, d => d.code);
//     let enoughData = true;

//     Object.keys(codeWiseData).forEach(k => {
//       if (!codeWiseData[k] || (codeWiseData[k] && codeWiseData[k].length < limit)) {
//         enoughData = false;
//         return;
//       }
//     });

//     return enoughData;
//   }

//   return data && data.length >= limit;
// }

// export const ColorLuminance = (hex, lum) => {

//   // validate hex string
//   hex = String(hex).replace(/[^0-9a-f]/gi, '');
//   if (hex.length < 6) {
//     hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
//   }
//   lum = lum || 0;

//   // convert to decimal and change luminosity
//   let rgb = "#", c, i;
//   for (i = 0; i < 3; i++) {
//     c = parseInt(hex.substr(i * 2, 2), 16);
//     c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
//     rgb += ("00" + c).substr(c.length);
//   }

//   return rgb;
// }


// export const setCookies = (obj = []) => {
//   obj.forEach(d => {
//     createCookie(d.key, d.value, d.time || 60 * 60);
//   });
// }

// export const getCookieItem = key => {
//   const { cookie } = document || {};

//   let cookies = cookie.split('; ').map(d => d.split('='));

//   const findItem = cookies.find(d => d[0] === key);

//   return findItem ? findItem[1] : '';
// }

// export const getPlanFromUrl = url => {
//   //let plan = null;

//   if (url && url.includes(dataConstants.NAVS.PRICING)) {
//     let u = url;
//     //for local
//     u = u.replace(`${process.env.NEXT_PUBLIC_PAY_URL}${dataConstants.NAVS.PRICING}`, '');
//     u = u.replace(`${process.env.NEXT_PUBLIC_URL}${dataConstants.NAVS.PRICING}`, '');
//     u = u.split('/').filter(d => d);

//     return u.length > 0 ? u[0] : null;
//   }

//   return null;
// }

export const getTitleUrlStr = title => title.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '-');

// export const isValidURL = (string) => {
//   let res = string && string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
//   return (res !== null)
// };

// Array.prototype.remove = function (key, value) {
//   const index = this.findIndex(obj => obj[key] === value);
//   return index >= 0 ? [
//     ...this.slice(0, index),
//     ...this.slice(index + 1)
//   ] : this;
// };

export const getTabData = (data, tab, mainDataKey) => {
  if (tab && tab.settings && tab.settings.dataTab) {
    let retArr = [];
    tab.settings.dataTab.forEach(d => {
      retArr = retArr.concat(data[d]);
    });

    return retArr;
  }

  return data[mainDataKey];
}

// export const extractscripts = code => {
//   const re = /<script\b[^>]*>([\s\S]*?)<\/script>/gi;
//   let match;
//   const scripts = [];

//   while (match = re.exec(code)) {
//     scripts.push(match[1]);
//   }

//   return scripts;
// }

// export const setChartTheme = (chart, themes) => {
//   const theme = themes.find(d => d.type === chart.chartSetting.themeName);
//   return { ...chart, currTheme: theme };
// }

// export const getChartSeriesByCodes = (chart, codes) => {
//   const arr = [];

//   codes.forEach((c, i) => {
//     const series = chart.series.find(d => d.yformula === c);
//     arr.push({ ...series, seq: i + 1 });
//   });

//   return arr;
// }
