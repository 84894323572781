// import Link from "./Link";
import { useRouter } from "next/router";
// import Social from "./Social";
import { Box, Container, Divider, Typography, Stack, IconButton } from '@mui/material';
// import Grid from '@mui/material/Grid';
import { routes, terms, pricing } from "../../routes";
import { makeStyles } from "@mui/styles";
import { Link as MUILink } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { socialMedia } from "../../routes/social";
import { getRandomStr } from '../helpers'
import Logo from "./_common/Logo";
import * as send from '../../lib/analytics/send';

const useStyles = makeStyles((theme) => ({
  footer: {
    // backgroundColor: theme.palette.primary.main,
    width: `100%`,
    position: "relative",
    overflow: "hidden",
    marginTop: "1em",
    // padding: "2em 0 ",
    paddingTop: "2em",
  },
  link: {
    fontSize: ".87rem",
    color: "text.link",
    "&:hover": {
      color: 'text.link',
    },
  },
  copylight: {
    color: "text.description",
    // whiteSpace: 'nowrap',
    fontSize: ".87rem",
    // "&:hover": {
    //   color: theme.palette.info.main,
    // },
  },
}));

const preventDefault = (event) => event.preventDefault();

const Footer = () => {
  const classes = useStyles();
  const router = useRouter();
  const { twitter, facebook, github } = socialMedia;
  const groups = routes?.reduce((groups, route) => {
    const groupName = route?.group || 'Other';
    if (!groups[groupName]) {
      groups[groupName] = [];
    }
    groups[groupName].push(route);
    return groups;
  }, {});

  const currentYear = new Date().getFullYear();

  return (
    (router.pathname === "/pricing" || router.pathname === "/auth" || router.pathname?.includes("/search/doc") || router.pathname?.includes("/docs/api")) ? null :
      <footer className={classes.footer}>
        <Divider varient="middle" sx={{ mt: 4 }} />
        <Container maxWidth="xl" >
          <Box component="div" sx={{ my: 5 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems='flex-start'
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Box component="div" sx={{}}>
                <Logo color={"dark"} />
                <Typography variant="p" component={"div"} sx={{ fontSize: ".87rem", mt: 3, fontWeight: 600, background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}>
                  Discover the joy of investing.
                </Typography>
                <Typography variant="p" color="text.secondary" component={"div"} sx={{ fontSize: ".87rem", mt: .1, }}>
                  All the financial data and tools you need, at one place.
                </Typography>
              </Box>
              <Box component="div" sx={{}}>
                <Stack
                  direction="row" useFlexGap flexWrap="wrap"
                  spacing={{ xs: 4, sm: 7, md: 9 }}
                  sx={{ '& ul': { listStyle: 'none', p: 0, mt: 3 }, mt: { xs: 3, md: 0, sm: 0 }, '& li': { mt: 1 } }}
                >
                  {Object.keys(groups).map((groupName) => (
                    <div key={groupName}>
                      <Typography variant="p" component={"div"} sx={{ fontSize: ".87rem", fontWeight: 600 }}>
                        {groupName}
                      </Typography>
                      <ul>
                        {groups[groupName].map((route) => (
                          <li key={getRandomStr(3)}>
                            <MUILink
                              className=''
                              underline="hover"
                              sx={{
                                color: "text.link",
                                fontSize: '.875rem',
                                py: { md: 0, sm: 1, xs: 2 },
                                px: { md: 0, sm: 1, xs: .5 },
                              }}
                              href={route.link}
                              onClick={(e) => send.uevent(e)}
                              data-event-name="href"
                              data-event-category="homepage-footer"
                              data-event-label={route.link}
                            >
                              {route.name}
                            </MUILink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Divider varient="middle" />
          <Box
            maxWidth="lg"
            sx={{
              typography: 'body1',
              '& > :not(style) + :not(style)': {
                ml: 2,
              },
              // mt: 3,
              // mb: 3,
              mx: 'auto',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: { md: 'space-between', xs: 'center' },
              flexWrap: 'wrap',
              textAlign: 'center',
              py: 2.5,
              width: '100%'
            }}
          // onClick={preventDefault}
          >
            <Box sx={{ my: 2 }}>
              <Typography variant="p" className={classes.copylight} color="text.secondary" >
                Copyright © {currentYear} Knowtilus Technologies Pvt. Ltd.
              </Typography>
            </Box>
            {/* <Divider orientation="vertical" flexItem /> */}
            {/* <Stack
            direction={'row'}
            spacing={{ xs: 2, sm: 3, md: 4 }}
            sx={{
              my: 1, mx: '0 !important',
              justifyContent: { md: 'space-evenly', xs: 'center' },
              flexWrap: 'wrap',
            }}
          >
            {routes.map(({ name, link }) => (
              <MUILink
                className=''
                key={getRandomStr(3)}
                href={link}
                underline="hover"
                sx={{
                  color: "text.link",
                  fontSize: '.875rem',
                  py: { md: 0, sm: 1, xs: 2 },
                  px: { md: 0, sm: 1, xs: .5 },
                }}
              >
                {name}
              </MUILink>
            ))}
          </Stack> */}
            {/* <Divider orientation="vertical" flexItem /> */}
            <Stack
              direction={'row'}
              spacing={4}
            >
              {/* <IconButton aria-label="delete" size="small" onClick={preventDefault} sx={{ my: 2 }}>
              <LinkedInIcon
                sx={{ color: 'text.link' }}
                fontSize="small"
              />
            </IconButton>
            <IconButton aria-label="delete" size="small" onClick={preventDefault} sx={{ my: 2 }}>
              <FacebookIcon
                sx={{ color: 'text.link' }}
                fontSize="small"
              />
            </IconButton> */}
              <IconButton
                aria-label="delete"
                size="small"
                // onClick={preventDefault}
                href="https://twitter.com/grufity"
                target="_blank" rel="noopener" sx={{ my: 2 }}
              >
                <TwitterIcon
                  sx={{ color: 'text.link' }}
                  fontSize="small"
                />
              </IconButton>
            </Stack>
          </Box>
        </Container >
      </footer >
  );
};

export default Footer;
